import { useState } from "react";
import { IContratantesDTO, IGetResponseContratante } from "../pages/contratante/types";
import { IAutocompleteValue } from "../components/autocompleteMultiple/types";
import { IContratanteContext } from "./types/contratanteTypes";
import { set } from "lodash";


export const ContratanteContext = () => {

    const [contratanteSelected, setContratanteSelected] = useState({} as IGetResponseContratante);
    const [pessoa, setPessoa] = useState('')
    const [tipoContratante, setTipoContratante] = useState<IAutocompleteValue>([])
    const [documento, setDocumento] = useState('')
    const [email, setEmail] = useState('')
    const [telefone, setTelefone] = useState('')
    const [tipoEndereco, setTipoEndereco] = useState<string[]>([])
    const [endereco, setEndereco] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [bairro, setBairro] = useState('')
    const [CEP, setCEP] = useState('')
    const [pais, setPais] = useState('')
    const [inscricaoEstadual, setInscricaoEstadual] = useState('')
    const [estadoListaContratante, setEstadoListaContratante] = useState<{ name: string, id: string, uf: string }[]>([])
    const [estadoContratante, setEstadoContratante] = useState<{ name: string, id: string, uf: string }[]>([])
    const [cidadeListaContratante, setCidadeListaContratante] = useState<IAutocompleteValue>([])
    const [cidadeContratante, setCidadeContratante] = useState<IAutocompleteValue>([])
    const [nomeCidade, setNomeCidade] = useState('')
    const [tempoGarantia, setTempoGarantia] = useState('')
    const [codigo, setCodigo] = useState('')
    const [slaTratativa, setSlaTratativa] = useState('')

    //ESTADOS USADOS NA ABA CONFIGURACOES
    const [integracaoSAP, setIntegracaoSAP] = useState(false)
    const [wsLogin, setWsLogin] = useState('')
    const [wsPass, setWsPass] = useState('')
    const [fornecedorSAP, setFornecedorSAP] = useState('')

    const [integraAbono, setIntegraAbono] = useState(false);
    const [sincronizacaoFoto, setSincronizacaoFoto] = useState(false);
    const [validaTrocaNumeroSerie, setValidaTrocaNumeroSerie] = useState(false);
    const [mobileControleDeChip, setMobileControleDeChip] = useState(false);
    const [mobileBaixaAutomaticaTodosServicos, setMobileBaixaAutomaticaTodosServicos] = useState(false);
    const [mobileBaixaAutomaticaServicosIndividuais, setMobileBaixaAutomaticaServicosIndividuais] = useState(false);
    const [habilitaChatBotInsucesso, setHabilitaChatBotInsucesso] = useState(false);
    const [habilitaChatBotTracking, setHabilitaChatBotTracking] = useState(false);

    return{
        contratanteSelected,
        setContratanteSelected,
        tipoContratante,
        setTipoContratante,
        pessoa,
        setPessoa,
        documento,
        setDocumento,
        email,
        setEmail,
        telefone,
        setTelefone,
        tipoEndereco,
        setTipoEndereco,
        endereco,
        setEndereco,
        inscricaoEstadual,
        setInscricaoEstadual,
        numero,
        setNumero,
        complemento,
        setComplemento,
        bairro,
        setBairro,
        CEP,
        setCEP,
        pais,
        setPais,
        estadoListaContratante,
        setEstadoListaContratante,
        estadoContratante,
        setEstadoContratante,
        cidadeListaContratante,
        setCidadeListaContratante,
        cidadeContratante,
        setCidadeContratante,
        nomeCidade,
        setNomeCidade,
        tempoGarantia,
        setTempoGarantia,
        codigo,
        setCodigo,
        slaTratativa,
        setSlaTratativa,
        integracaoSAP,
        setIntegracaoSAP,
        wsLogin,
        setWsLogin,
        wsPass,
        setWsPass,
        fornecedorSAP,
        setFornecedorSAP,
        integraAbono,
        setIntegraAbono,
        sincronizacaoFoto,
        setSincronizacaoFoto,
        validaTrocaNumeroSerie,
        setValidaTrocaNumeroSerie,
        mobileControleDeChip,
        setMobileControleDeChip,
        mobileBaixaAutomaticaTodosServicos,
        setMobileBaixaAutomaticaTodosServicos,
        mobileBaixaAutomaticaServicosIndividuais,
        setMobileBaixaAutomaticaServicosIndividuais,
        habilitaChatBotInsucesso,
        setHabilitaChatBotInsucesso,
        habilitaChatBotTracking,
        setHabilitaChatBotTracking,
    } as unknown as IContratanteContext
}