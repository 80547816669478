import React, { useRef, useMemo, useState, useEffect } from 'react';
import JoditEditor from 'jodit-react';

interface EditorTextoProps {
  content: string;
  setContent: (content: string) => void;
}

const EditorTexto: React.FC<EditorTextoProps> = ({ content, setContent }) => {
  const editor = useRef(null);
  const [localContent, setLocalContent] = useState(content);
  useEffect(() => {
    setLocalContent(content);
  }, [content]);
  const config = useMemo(
    () => ({
      readonly: false,
      height: 300,
      language: 'pt_br',
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      i18n: {
        pt_br: {
            'Type something': 'Digite algo...',
            'About Jodit': 'Sobre o Jodit',
            'Jodit Editor': 'Editor Jodit',
            'Anchor': 'Âncora',
            'Open in new tab': 'Abrir em nova aba',
            'Open editor in fullsize': 'Abrir editor em tela cheia',
            'Clear Formatting': 'Limpar formatação',
            'Fill color or set the text color': 'Preencher cor ou definir a cor do texto',
            'Redo': 'Refazer',
            'Undo': 'Desfazer',
            'Bold': 'Negrito',
            'Italic': 'Itálico',
            'Insert Unordered List': 'Inserir lista não ordenada',
            'Insert Ordered List': 'Inserir lista ordenada',
            'Align Center': 'Alinhar ao centro',
            'Align Justify': 'Justificar',
            'Align Left': 'Alinhar à esquerda',
            'Align Right': 'Alinhar à direita',
            'Insert Horizontal Line': 'Inserir linha horizontal',
            'Insert Image': 'Inserir imagem',
            'Insert file': 'Inserir arquivo',
            'Insert youtube/vimeo video': 'Inserir vídeo do YouTube/Vimeo',
            'Insert link': 'Inserir link',
            'Font size': 'Tamanho da fonte',
            'Font family': 'Família da fonte',
            'Insert format block': 'Inserir bloco de formatação',
            'Normal': 'Normal',
            'Heading 1': 'Título 1',
            'Heading 2': 'Título 2',
            'Heading 3': 'Título 3',
            'Heading 4': 'Título 4',
            'Quote': 'Citação',
            'Code': 'Código',
            'Insert': 'Inserir',
            'Insert table': 'Inserir tabela',
            'Decrease Indent': 'Diminuir recuo',
            'Increase Indent': 'Aumentar recuo',
            'Select Special Character': 'Selecionar caractere especial',
            'Insert Special Character': 'Inserir caractere especial',
            'Paint format': 'Pintar formato',
            'Change mode': 'Alterar modo',
            'Margins': 'Margens',
            'top': 'superior',
            'right': 'direita',
            'bottom': 'inferior',
            'left': 'esquerda',
            'Styles': 'Estilos',
            'Classes': 'Classes',
            'Align': 'Alinhar',
            'Right': 'Direita',
            'Center': 'Centro',
            'Left': 'Esquerda',
            '--Not Set--': '--Não Definido--',
            'Src': 'Src',
            'Title': 'Título',
            'Alternative': 'Alternativo',
            'Link': 'Link',
            'Open link in new tab': 'Abrir link em nova aba',
            'File': 'Arquivo',
            'Advanced': 'Avançado',
            'Image properties': 'Propriedades da imagem',
            'Cancel': 'Cancelar',
            'Ok': 'Ok',
            'File Browser': 'Navegador de arquivos',
            'Error on load list': 'Erro ao carregar lista',
            'Error on load folders': 'Erro ao carregar pastas',
            'Are you sure?': 'Tem certeza?',
            'Enter Directory name': 'Digite o nome do diretório',
            'Create directory': 'Criar diretório',
            'type name': 'digite o nome',
            'Drop image': 'Soltar imagem',
            'Drop file': 'Soltar arquivo',
            'or click': 'ou clique',
            'Alternative text': 'Texto alternativo',
            'Browse': 'Navegar',
            'Upload': 'Enviar',
            'Background': 'Fundo',
            'Text': 'Texto',
            'Top': 'Topo',
            'Middle': 'Meio',
            'Bottom': 'Base',
            'Insert column before': 'Inserir coluna antes',
            'Insert column after': 'Inserir coluna depois',
            'Insert row above': 'Inserir linha acima',
            'Insert row below': 'Inserir linha abaixo',
            'Delete table': 'Excluir tabela',
            'Delete row': 'Excluir linha',
            'Delete column': 'Excluir coluna',
            'Empty cell': 'Célula vazia',
            'Source': 'Fonte',
            'Delete': 'Excluir',
            'Edit': 'Editar',
            'Vertical align': 'Alinhamento vertical',
            'Horizontal align': 'Alinhamento horizontal',
            'Paste': 'Colar',
            'Cut': 'Recortar',
            'Copy': 'Copiar',
            'Select all': 'Selecionar tudo',
            'Break': 'Quebra',
            'Search for': 'Procurar por',
            'Replace with': 'Substituir por',
            'Replace': 'Substituir',
            'Replace all': 'Substituir tudo',
            'Paste your svg code': 'Cole seu código SVG',
            'Paste your html code': 'Cole seu código HTML',
            'Show all': 'Mostrar tudo',
            'Text mode': 'Modo texto',
            'Preview': 'Visualizar',
            'Fullsize': 'Tamanho completo',
        }
      },
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'undo',
        'redo', "",
      ],
    }),
    []
  );

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      onBlur={(newContent) => setContent(newContent)} 
      onChange={(newContent) => setLocalContent(newContent)}
    />
  );
};

export default EditorTexto;
