import SectionCustom from "../../../components/section/section";
import React, { useEffect, useState } from "react";
import {
  Box,
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
  Grid,
  styled,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getContratantesNovo } from "../../farol/services";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import { IContratantesDTO } from "../../contratante/types";
import { OpcaoSelect } from "../../consultaOS/types";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import { obterProcedimentoServico } from "../services";
import { AxiosResponse } from "axios";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import { HeaderTabela, ServicoContratante } from "../types";
import StickyTable from "../../../components/stickyTable/stickyTable";

export const NewButton = styled(Button)`
  background: #afb1b1 !important;
  margin-top: 16px !important;
  color: #fff !important;
`;

const ConsultarProcedimento = () => {
  const [contratante, setContratante] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAlertError, setOpenAlertError] = React.useState(false);
  const [msgAlertError, setMsgAlertError] = React.useState("Falha na operação");
  const [dadosConsulta, setDadosConsulta] = useState<ServicoContratante[]>(
    []
  );
  const [alertType, setAlertType] = React.useState<
    "error" | "success" | "info" | "warning" | undefined
  >("error");
  const [listaContratante, setListaContratante] = useState<
    { name: string; id: string }[]
  >([]);
  const [disableActions, setDisableActions] = useState(false);
  const navigate = useNavigate();

    const objTraducaoHeader = {
      id: "ID",
      status: "Status",
      contratanteNome: "Contratante",
      grupoServicoNome: "Grupo de Serviço",
      servicoNome: "Serviço",
      procedimento: "Procedimento",
    } as HeaderTabela;

    const removerTagsHtml = (texto: string) => {
      return texto
      .replace(/<\/?[^>]+(>|$)/g, "") 
      .replace(/&nbsp;/g, " ")        
      .trim();                        
    };
    const obterDadosParaTabela = () => {
      if(dadosConsulta.length === 0) {
        return [{id: 0, status: "", contratanteNome: "", grupoServicoNome: "", servicoNome: "", procedimento: ""}];

      }
      return dadosConsulta?.map((proc) => ({
        id: proc.id,
        status: proc.ativo ? "Ativo" : "Inativo",
        contratanteNome: proc.contratante.nome,
        grupoServicoNome:
        proc?.grupoServico?.nome,
        servicoNome: proc?.servicos?.length > 0 ?  proc.servicos.map((item:any)=> item.nome).join(', ') : "",
        procedimento: removerTagsHtml(proc.procedimento),
      })) as any[];
    };

  const handleGetContratantes = async () => {
    await getContratantesNovo().then(
      (res: any) => {
        const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;
        if (respSuccess) {
          const listaContratante =
            respSuccess.data?.map((item: any) => {
              return { name: item.nome, id: item.id.toString() };
            }) || [];
          setListaContratante([...[], ...listaContratante]);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
        if (respErr) {
          console.error(respErr.errors[0].Message);
        }
      }
    );
  };

  useEffect(() => {
    handleGetContratantes();
  }, []);

  useEffect(() => {
    if (contratante.length) {
      handleSearch();
    }
  }, [contratante]);

  const handleSearch = async () => {
    setOpenAlertError(false);
    setDisableActions(true);
    const codigo = contratante[0];
    setLoading(true);
    if (codigo === undefined || codigo === null || codigo.trim() === "") {
      setLoading(false);
      setOpenAlertError(true);
      setMsgAlertError("Informe um contratante para realizar a pesquisa");
      setDisableActions(false);
      return;
    }
    try {
      const idContratante = contratante[0];
      const { data } = (await obterProcedimentoServico(
        parseInt(idContratante)
      )) as AxiosResponse;
      setLoading(false);
      setDadosConsulta(data.data);
      setDisableActions(false);
      if(data.data.length === 0) {
        setOpenAlertError(true);
        setMsgAlertError("Nenhum procedimento encontrado");
        setAlertType("info");

      }
    } catch (error: any) {
      setLoading(false);
    }
  };
  const obterTermoPorId = (ID: number) => {
    let resultadoDados = [...(dadosConsulta || [])];
    return resultadoDados.find((item) => item.id === ID)!;
  };
  const handleClickItemEdit = (data: any) => {
    navigate("/AlterarProcedimento", {
      state: {
        dadosProcedimento: obterTermoPorId(data.id),
        rota: {
          url: window.location.pathname,
          name: "Alterar Procedimento",
        },
      },
    });
  };

  const handleInclude = () => {
    navigate("/IncluirProcedimento", {
      state: {
        rota: {
          url: window.location.pathname,
          name: "Novo Procedimento",
        },
      },
    });
  };
  return (
    <SectionCustom
      id={"ConsultarProcedimento"}
      className="ConsultarProcedimento"
      titleHeader="Procedimentos"
      titleBreadcrumbItem="Consultar Procedimentos"
    >
      <>
        {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
        <AlertMessage
          isOpenAlert={openAlertError}
          setOpenAlert={setOpenAlertError}
          alertType={alertType ?? "error"}
          msgAlert={msgAlertError}
        />
        <Box sx={{ p: 1 }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={8}>
              <MultipleSelectCheckmarksid
                dimension="sm"
                label={"Contratante"}
                nodata={"Nenhum contratante encontrado"}
                options={[{ name: "--", id: "" }, ...listaContratante]}
                value={contratante}
                onChange={(value) => setContratante(value)}
                placeholder={""}
                id="multiple-checkbox-Contratante"
                idOption="multiple-checkbox-option-Contratante-"
                labelId="multiple-checkbox-label-Contratante"
                classes={{
                  select: "ConsultaUsuario-Seletor error",
                  primary: "Seletor-Option",
                  group: "Seletor-OptionGroup",
                }}
                multiple={false}
                disabled={disableActions}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                style={{ marginTop: "16px" }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSearch}
              >
                Pesquisar
              </Button>
            </Grid>
            <Grid item xs={6} md={2}>
              <NewButton
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleInclude}
              >
                Incluir
              </NewButton>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 1 }} style={{ marginTop: "46px" }}>
          <Grid container spacing={1} alignItems="center">
          <StickyTable
              id={"TabelaResultadoConsultaTermos"}
              data={obterDadosParaTabela()}
              objTraducaoHeader={objTraducaoHeader}
              allowEdit={true}
              edit={dadosConsulta.length > 0 ? true : false}
              handleClickEdit={handleClickItemEdit}
            />
          </Grid>
        </Box>
      </>
    </SectionCustom>
  );
};

export default ConsultarProcedimento;
