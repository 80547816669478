// consultaParametro_styles.ts
export const ContainerStyle = {
    display: 'flex',
    gap: '24px',
    width: '100%'
  };
  
  export const TreeContainerStyle = {
    width: '350px',
    minWidth: '350px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: '16px',
    backgroundColor: '#fff',
    height: 'fit-content'
  };
  
  export const FormContainerStyle = {
    flex: 1,
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    padding: '16px',
    backgroundColor: '#fff'
  };
  
  export const BreadcrumbStyle = {
    marginBottom: '24px',
    padding: '8px 0',
    borderBottom: '1px solid #e0e0e0'
  };