import { apiDelete, apiGet, apiPost, apiPut } from "../../../services/api";
import { ListTecnico ,FormularioData} from "../types";

const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO;

export const obterTecnicos = async (): Promise<ListTecnico[]> => {
  try {
    const response = (await apiGet(`${baseUrl}/Tecnicos?Ativo=true`))as any;
    return mapTecnico(response.data);
  } catch (error) {
    console.error("Error fetching tecnicos:", error);
    return [];
  }
};

function mapTecnico(data: any): ListTecnico[] {
  if (!Array.isArray(data.tecnicos)) {
    return [];
  }
  return data?.tecnicos?.map((item: any) => ({
    idTecnico: item.idTecnico,
    nome: item.nome
  }));
}

export const salvarParametroGeral = async (intervaloDias:number) =>
  await apiPost(`${baseUrl}/ConfiguracaoTecnico/atualiza-configuracao-geral?intervaloDias=${intervaloDias}`);


export const salvarParametroTecnico = async (form:FormularioData) =>
  await apiPost(`${baseUrl}/ConfiguracaoTecnico`, form);

export const atualizarTecnico = async (form:FormularioData) => 
  await apiPut(`${baseUrl}/ConfiguracaoTecnico`, form);

export const excluirTecnico = async (id:number) =>
  await apiDelete(`${baseUrl}/ConfiguracaoTecnico/${id}`);

export const obterListaTecnicoPaginado = async (pageNumber:number,pageSize :number) =>
    await apiGet(`${baseUrl}/ConfiguracaoTecnico?PageNumber=${pageNumber}&PageSize=${pageSize}`);


export const obterIntervaloParametroGeral = async () =>
  await apiGet(`${baseUrl}/ConfiguracaoTecnico/intervalo-configuracao-geral`);
