import styled from "styled-components";
import Button from "../Button/styles";

const TreeViewButton = styled(Button)`
  position: relative; /* Define o botão como um contêiner relativo */
  width: 100%; /* O botão ocupa toda a largura do TreeItem */
  height: 36px; /* Altura menor */
  font-size: 0.875rem; /* Reduzido o tamanho da fonte */
  padding: 0; /* Remove padding interno para evitar desalinhamento */
  margin: 0 -10px; /* Remove margens internas */
  text-align: center; /* Centraliza o texto horizontalmente */
  line-height: normal; /* Garante que o texto não fique desalinhado verticalmente */
  white-space: nowrap; /* Evita quebra de linha no texto */
  overflow: hidden; /* Esconde o texto que ultrapassar o limite */
  text-overflow: ellipsis; /* Adiciona reticências ao texto que ultrapassar o limite */

  /* Centraliza o texto usando position: absolute */
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centraliza o texto horizontal e verticalmente */
  }
`;

export default TreeViewButton;