import React, { useState, useRef, useCallback, useEffect } from "react";
import SectionCustom from "../../../components/section/section";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Grid,
  Typography,
  CircularProgress,
  Select,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";

import { ResponseApi } from "../../../contexts/types/responsesHttp";
import { IContratantesDTO } from "../../contratante/types";
import { getContratantesNovo, getGrouped_Services } from "../../farol/services";
import { OpcaoSelect } from "../../consultaOS/types";
import { getListaServicosComFiltro } from "../../servicos/services";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import EditorTexto from "../../../components/editor/editor-texto";
import { FormularioData, ServicoContratante } from "../types";
import { putProcedimentoServico } from "../services";
import { AxiosResponse } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { TextAreaTermo } from "../../termoDeOs/view/textAreaTermoOs";

const AlterarProcedimento = () => {
  const location = useLocation();
  const dadosProcedimento = (location.state?.dadosProcedimento ||
    {}) as ServicoContratante;
  const [formData, setFormData] = useState<FormularioData>({
    id: dadosProcedimento?.id || 0,
    ativo: dadosProcedimento?.ativo ? 1 : 0,
    contratanteId: dadosProcedimento?.contratante?.id || 0,
    grupoServicos: dadosProcedimento?.grupoServico?.id || 0,
    servicoIds: dadosProcedimento?.servicos?.map((s) => s.id) || [],
  });
  const [procedimento, setProcedimento] = useState(
    dadosProcedimento?.procedimento || ""
  );
  const [todosSelected, setTodosSelected] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [listaContratante, setListaContratante] = useState<OpcaoSelect[]>([]);
  const [gruposServico, setGruposServico] = useState<OpcaoSelect[]>([]);
  const [loading, setLoading] = useState(false);
  const [openAlertError, setOpenAlertError] = React.useState(false);
  const [msgAlertError, setMsgAlertError] = React.useState("Falha na operação");
  const [servicos, setServicos] = useState<OpcaoSelect[]>([]);
  const navigate = useNavigate();
  const [alertType, setAlertType] = React.useState<
    "error" | "success" | "info" | "warning" | undefined
  >("error");
  const hasData = Object.keys(dadosProcedimento).length > 0;

  const handleGetContratantes = async () => {
    await getContratantesNovo().then(
      (res: any) => {
        const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;
        if (respSuccess) {
          const listaContratante =
            respSuccess.data?.map((item: any) => {
              return { nome: item.nome, id: item.id };
            }) || [];
          setListaContratante([...[], ...listaContratante]);
        }
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
        if (respErr) {
          console.error(respErr.errors[0].Message);
        }
      }
    );
  };

  const handleTextFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

      if (name === "grupoServicos" && value) {
        handleGetServicosPorGrupo(Number(value));
        setFormData((prev) => ({ ...prev, servicoIds: [] }));
      }
    },
    []
  );

  const handleSelectChange = useCallback(
    (event: SelectChangeEvent<number[]>) => {
      const selectedValues = event.target.value as number[];

      if (selectedValues.includes(0)) {
        const newTodosSelected = !todosSelected;
        setTodosSelected(newTodosSelected);

        if (newTodosSelected) {
          setFormData((prev) => ({
            ...prev,
            servicoIds: servicos.map((s) => s.id),
          }));
        } else {
          setFormData((prev) => ({ ...prev, servicoIds: [] }));
        }
      } else {
        if (todosSelected) {
          setFormData((prev) => ({ ...prev, servicoIds: [] }));
        } else {
          setFormData((prev) => ({ ...prev, servicoIds: selectedValues }));
        }
        setTodosSelected(false);
      }

      setErrors((prevErrors) => ({ ...prevErrors, servicos: "" }));
    },
    [servicos, todosSelected]
  );

  const handleEditorChange = useCallback((content: string) => {
    setProcedimento(content);
    setErrors((prevErrors) => ({ ...prevErrors, procedimento: "" }));
  }, []);

  useEffect(() => {
    handleGetContratantes();
    getGrupoDeServicos();
    if (hasData && dadosProcedimento.grupoServico?.id) {
      handleGetServicosPorGrupo(dadosProcedimento.grupoServico.id);
    }
  }, []);
  const removerTagsHtml = (texto: string) => {
    return texto.replace(/<\/?[^>]+(>|$)/g, "").trim();
  };
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    const procedimentoSemTags = removerTagsHtml(procedimento);
    if (!formData.contratanteId) newErrors.contratante = "Campo obrigatório";
    if (!formData.grupoServicos) newErrors.grupoServicos = "Campo obrigatório";
    if (formData.servicoIds.length === 0)
      newErrors.servicos = "Campo obrigatório";
    if (!procedimentoSemTags) newErrors.procedimento = "Campo obrigatório";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sanitizedHtml = (htmlContent: string): string => {
    return DOMPurify.sanitize(htmlContent, {
      ALLOWED_TAGS: ["h2", "p", "strong", "span", "i", "u", "s"],
      ALLOWED_ATTR: [],
    });
  };

  const handleSave = useCallback(async () => {
    try {
      if (validateForm()) {
        const escapedProcedimento = sanitizedHtml(procedimento);
        let completeFormData = {
          ...formData,
          procedimento: escapedProcedimento,
        };
        completeFormData = {
          ...completeFormData,
          ativo: completeFormData.ativo == 1 ? true : false,
        };
        var { data } = (await putProcedimentoServico(
          completeFormData!
        )) as AxiosResponse;

        if (data.success) {
          setOpenAlertError(true);
          setAlertType("success");
          setMsgAlertError("Procedimento salvo com sucesso!");
          navigate("/ConsultarProcedimento");
        } else {
          setOpenAlertError(true);
          setAlertType("error");
          if (data.messages.length > 0) {
            setMsgAlertError(data.messages.join(","));
          } else {
            setMsgAlertError("Falha ao salvar procedimento");
          }
        }
      } else {
        setOpenAlertError(true);
        setAlertType("error");
        setMsgAlertError("Por favor, preencha todos os campos obrigatórios.");
      }
    } catch (error: any) {
      const erros = error.response.data as any;
      setOpenAlertError(true);
      setAlertType("error");
      setMsgAlertError(erros.messages.join(","));
    }
  }, [formData, procedimento]);

  const getGrupoDeServicos = async () => {
    setLoading(true);
    await getGrouped_Services().then(
      (resp: any) => {
        const respSuccess = resp?.data as ResponseApi<OpcaoSelect[]>;
        const grupos = (respSuccess?.data as any[]) ?? [];

        setGruposServico(grupos);
      },
      (err: any) => {
        console.error(err);
        setOpenAlertError(true);
        setAlertType("error");
        setMsgAlertError("Erro ao carregar Grupos de serviço");
      }
    );
    setLoading(false);
  };
  const handleGetServicosPorGrupo = async (idGrupoServico: number) => {
    setLoading(true);
    await getListaServicosComFiltro({ IdGrupoServico: idGrupoServico }).then(
      (resp: any) => {
        const respSuccess = resp?.data as ResponseApi<OpcaoSelect[]>;
        const servicos = (respSuccess?.data as OpcaoSelect[]) ?? [];
        setServicos(servicos);
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<any>;
        console.error(respErr.errors[0].Message);

        setOpenAlertError(true);
        setAlertType("error");
        setMsgAlertError("Erro ao carregar serviços");
      }
    );
    setLoading(false);
  };

  return (
    <SectionCustom
      id={"EditarProcedimento"}
      className="EditarProcedimento"
      titleHeader="Procedimentos"
      rotaVoltar="/ConsultarProcedimento"
      titleBreadcrumbItemBack="Consultar Procedimentos"
      titleBreadcrumbItem="Alterar Procedimentos"
    >
      <>
        <AlertMessage
          isOpenAlert={openAlertError}
          setOpenAlert={setOpenAlertError}
          alertType={alertType ?? "error"}
          msgAlert={msgAlertError}
        />

        {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
        <Box component="form" sx={{ width: "100%", p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                fullWidth
                label="Status"
                name="ativo"
                value={formData.ativo}
                onChange={handleTextFieldChange}
                error={!!errors.ativo}
                helperText={errors.ativo}
                disabled={!hasData}
              >
                <MenuItem value={1}>Ativo</MenuItem>
                <MenuItem value={0}>Inativo</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                fullWidth
                label="Contratante"
                name="contratanteId"
                value={formData.contratanteId}
                onChange={handleTextFieldChange}
                error={!!errors.contratante}
                helperText={errors.contratante}
                disabled={!hasData}
              >
                {listaContratante.map((c: any) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select
                fullWidth
                label="Grupo Serviços"
                name="grupoServicos"
                value={formData.grupoServicos}
                onChange={handleTextFieldChange}
                error={!!errors.grupoServicos}
                helperText={errors.grupoServicos}
                disabled={!hasData}
              >
                <MenuItem key={-1} value={-1}>
                  Todos
                </MenuItem>
                {gruposServico.map((c: any) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.nome}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth error={!!errors.servicos}>
                <InputLabel id="servicos-label">Serviços</InputLabel>
                <Select
                  labelId="servicos-label"
                  multiple
                  label="Serviços"
                  name="servicos"
                  value={
                    todosSelected
                      ? [0, ...formData.servicoIds]
                      : formData.servicoIds
                  }
                  onChange={handleSelectChange}
                  renderValue={(selected) => {
                    if (todosSelected) return "Todos";
                    return (selected as number[])
                      .map((id) => servicos.find((s) => s.id === id)?.nome)
                      .join(", ");
                  }}
                  disabled={!hasData || !formData.grupoServicos}
                >
                  <MenuItem value={0}>
                    <Checkbox checked={todosSelected} />
                    <ListItemText primary="Todos" />
                  </MenuItem>
                  {servicos.map((s: OpcaoSelect) => (
                    <MenuItem key={s.id} value={s.id}>
                      <Checkbox
                        checked={
                          todosSelected || formData.servicoIds.includes(s.id)
                        }
                      />
                      <ListItemText primary={s.nome} />
                    </MenuItem>
                  ))}
                </Select>
                {errors.servicos && (
                  <FormHelperText>{errors.servicos}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ mt: 2, mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Procedimento
                </Typography>
                {!hasData ? (
                  <TextAreaTermo
                    dimension="sm"
                    label=""
                    maxLength={2000}
                    name="procedimento"
                    id="procedimento"
                    value={undefined}
                    disabled={true}
                  />
                ) : (
                  <EditorTexto
                    content={procedimento}
                    setContent={handleEditorChange}
                  />
                )}
                {errors.procedimento && (
                  <Typography color="error" variant="caption">
                    {errors.procedimento}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={!hasData}
            >
              Salvar
            </Button>
          </Box>
        </Box>
      </>
    </SectionCustom>
  );
};

export default AlterarProcedimento;
