import { BreadcrumbStyle } from "../../pages/parametro/view/treeViewStyle";

const FormBreadcrumb = ({ path }: { path: (string | null)[] }) => {
  console.log(path)
  const defaultLabels = ['Parâmetro', 'Sem Contratante', 'Sem Prestador', 'Sem Grupo de Serviço', 'Sem Serviço'];
    return (
      <div style={BreadcrumbStyle}>
        {path.map((item, index) => (
          <span 
            key={index}
            style={index === path.length - 1 ? { fontWeight: "bold" } : undefined}
          >
            {index > 0 && " > "}
            {item !== null && item !== undefined ? item : (defaultLabels[index] || '')}
          </span>
        ))}
      </div>
    );
};

export default FormBreadcrumb;