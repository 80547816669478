import { useState, useCallback } from "react";
import {
  ItensListagem,
  ListTecnico,
  FormularioData,
} from "../pages/checklistCompleto/types";
import {
  excluirTecnico,
  obterTecnicos,
  salvarParametroGeral,
  atualizarTecnico,
  obterListaTecnicoPaginado,
  obterIntervaloParametroGeral,
  salvarParametroTecnico,
} from "../pages/checklistCompleto/services";
import { AxiosResponse } from "axios";
import React from "react";

export const ConsultarChecklistCompletoContext = () => {
  const [loading, setLoading] = useState(false);
  const [mudouDiasGeral, setMudouDiasGeral] = useState(false);
  const [dias, setDias] = useState(0);
  const [itemsListagem, setItemsListagem] = useState<ItensListagem[]>([]);
  const [tecnicos, setTecnicos] = useState<ListTecnico[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAlertError, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = React.useState<
    "error" | "success" | "info" | "warning" | undefined
  >("error");
  const [msgAlertError, setMsgAlert] = useState<string>(
    "Ocorreu um erro, tente novamente."
  );
  const [tecnicoToDelete, setTecnicoToDelete] = useState<ItensListagem | null>(
    null
  );
  const [formData, setFormData] = useState<FormularioData>({
    id: 0,
    tecnicoId: 0,
    intervaloDias: 0,
  });
  const [errors, setErrors] = useState({ tecnicId: "", intervaloDias: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const handleDiasChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(e.target.value);
      setMudouDiasGeral(true);
      setDias(newValue);
    },
    []
  );

  const getConfiguracaoGeral = async () => {
    try {
      setLoading(true);
      const consulta = (await obterIntervaloParametroGeral()) as AxiosResponse;
      if (consulta.status === 200) {
        setDias(consulta.data);
        setLoading(false);
      } else {
        setDias(0);
        setMsgAlert("Ocorreu um erro, tente novamente.");
        setAlertType("error");
        setOpenAlert(true);
        setLoading(false);
      }
    } catch (error) {
      setDias(0);
      setLoading(false);
    }
  };

  const handleSave = useCallback(async () => {
    setLoading(true);
    const request = (await salvarParametroGeral(dias)) as AxiosResponse;
    if (request.status === 200) {
      setMsgAlert("Registro salvo com sucesso!");
      setOpenAlert(true);
      setMudouDiasGeral(false);
      setAlertType("success");
    } else {
      setMsgAlert("Ocorreu um erro, tente novamente.");
      setAlertType("error");
      setOpenAlert(true);
    }
    setLoading(false);
  }, [dias]);
  const getTecnicos = async () => {
    const response = await obterTecnicos();
    setTecnicos(response);
  };
  const getTecnicoConfiguracoes = async () => {
    try {
      setLoading(true);
      var consulta = (await obterListaTecnicoPaginado(
        currentPage,
        pageSize
      )) as AxiosResponse;
      if (consulta.status == 200) {
        setItemsListagem(consulta.data.items);
        setTotalCount(consulta.data.totalCount);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const handleOpenModal = useCallback(() => {
    setIsEditing(false);
    setFormData({ id: 0, tecnicoId: 0, intervaloDias: 0 });
    setErrors({ tecnicId: "", intervaloDias: "" });
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setIsEditing(false);
    setFormData({ id: 0, tecnicoId: 0, intervaloDias: 0 });
    setErrors({ tecnicId: "", intervaloDias: "" });
  }, []);

  const handleEdit = useCallback((tecnico: ItensListagem) => {
    setIsEditing(true);
    setFormData({
      id: tecnico.id,
      tecnicoId: tecnico.tecnicoId,
      intervaloDias: tecnico.intervaloDias,
    });
    setErrors({ tecnicId: "", intervaloDias: "" });
    setOpenModal(true);
  }, []);

  const handleDeleteClick = useCallback((tecnico: ItensListagem) => {
    setTecnicoToDelete(tecnico);
    setOpenDeleteDialog(true);
  }, []);

  const handleCloseDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(false);
    setTecnicoToDelete(null);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    try {
      setLoading(false);
      if (tecnicoToDelete) {
        var response = (await excluirTecnico(
          tecnicoToDelete.id
        )) as AxiosResponse;
        if (response.status === 200) {
          setMsgAlert("Registro excluído com sucesso!");
          setOpenAlert(true);
          setAlertType("success");
          getTecnicos();
          getTecnicoConfiguracoes();
        } else {
          handleCloseDeleteDialog();
          setMsgAlert("Ocorreu um erro, tente novamente.");
          setAlertType("error");
          setOpenAlert(true);
        }
        setLoading(false);
      }
      handleCloseDeleteDialog();
    } catch (e) {
      handleCloseDeleteDialog();
      setMsgAlert("Ocorreu um erro, tente novamente.");
      setAlertType("error");
      setOpenAlert(true);
    }
  }, [tecnicoToDelete]);

  const handleSubmit = useCallback(async () => {
    try {
      if (validateForm()) {
        if (isEditing) {
          var response = (await atualizarTecnico(formData)) as AxiosResponse;
          if (response.status === 200) {
            setMsgAlert("Configuração atualizado com sucesso!");
            setOpenAlert(true);
            setAlertType("success");
            getTecnicos();
            getTecnicoConfiguracoes();
          } else {
            handleCloseDeleteDialog();
            setMsgAlert("Ocorreu um erro, tente novamente.");
            setAlertType("error");
            setOpenAlert(true);
          }
          setLoading(false);
        } else {
          var response = (await salvarParametroTecnico(formData))as AxiosResponse;
          if (response.status === 200) {
            setMsgAlert("Configuração salva com sucesso!");
            setOpenAlert(true);
            setAlertType("success");
            getTecnicos();
            getTecnicoConfiguracoes();
          }
          else {
            handleCloseDeleteDialog();
            setMsgAlert("Ocorreu um erro, tente novamente.");
            setAlertType("error");
            setOpenAlert(true);
          }
          setLoading(false);
        }
        handleCloseModal();
        getTecnicos();
      }
    } catch (erreor) {
      handleCloseDeleteDialog();
      setMsgAlert("Ocorreu um erro, tente novamente.");
      setAlertType("error");
      setOpenAlert(true);
    }
  }, [formData, isEditing]);

  const validateForm = (): boolean => {
    let tempErrors = {
      tecnicId: "",
      intervaloDias: "",
    };
    let isValid = true;
    if (!formData.tecnicoId) {
      tempErrors.tecnicId = "Esse campo é obrigatório";
      isValid = false;
    }
    if (formData.intervaloDias === "" || formData.intervaloDias === null) {
      tempErrors.intervaloDias = "Esse campo é obrigatório";
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  const handleFormChange = useCallback((field: string, value: any) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: "" }));
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize: string) => {
    setPageSize(Number(newPageSize));
    setCurrentPage(1);
  }, []);

  return {
    loading,
    dias,
    itemsListagem,
    tecnicos,
    openModal,
    isEditing,
    openDeleteDialog,
    tecnicoToDelete,
    formData,
    errors,
    currentPage,
    pageSize,
    totalCount,
    openAlertError,
    msgAlertError,
    alertType,
    setAlertType,
    setMsgAlert,
    setOpenAlert,
    handleDiasChange,
    handleSave,
    handleOpenModal,
    handleCloseModal,
    handleEdit,
    handleDeleteClick,
    handleCloseDeleteDialog,
    handleConfirmDelete,
    handleSubmit,
    validateForm,
    handleFormChange,
    handlePageChange,
    handlePageSizeChange,
    getTecnicos,
    getConfiguracaoGeral,
    getTecnicoConfiguracoes,
    mudouDiasGeral,
  };
};
