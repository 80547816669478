import Login from "../pages/login/view/login";
import Verify2FA from "../pages/login/view/verify2FA";
import Dashboard from "../pages/dashboard/view/dashboard";
import FarolOS from "../pages/farol/view/farol";
import EncaminharOS from "../pages/encaminharOS/view/encaminharOS";
import OsPendente from '../pages/osPendente/view/osPendente'
import EnviarTecnico from "../pages/enviarTecnico/view/EnviarTecnico";
import EstoqueTecnico from "../pages/estoqueTecnico/view/estoqueTecnico";
import ResumoEquipamentos from "../pages/resumoEquipamentos/view/resumoEquipamentos";
import TipoEquipamento from "../pages/tipoEquipamento/view/tipoEquipamento";
import ConsultarPerfil from "../pages/perfil/view/consultarPerfil";
import IncluirPerfil from "../pages/perfil/view/incluirEditarPerfil";
import IncluirEditarTipoEquipamento from "../pages/tipoEquipamento/view/incluirEditarTipoEquipamento";
import ConsultaFamilia from "../pages/familia/view/consultaFamilia";
import IncluirOuAlterarFamilia from "../pages/familia/view/incluirOuAlterarFamilia";
import ConsultaUsuario from "../pages/usuario/view/consultaUsuario";
import IncluirOuAlterarUsuario from "../pages/usuario/view/incluirOuAlterarUsuario";
import ConsultaModelo from "../pages/modelo/view/consultaModelo";
import IncluirEditarModelo from "../pages/modelo/view/incluirEditarModelo";
import ConsultaPrestador from "../pages/prestador/view/consultaPrestador";
import IncluirEditarPrestador from "../pages/prestador/view/incluirEditarPrestador";
import ResumoApp from "../pages/resumoApp/view/resumoApp";
import ConsultaTecnico from "../pages/tecnico/view/consultaTecnico";
import IncluirEditarTecnico from "../pages/tecnico/view/incluirEditarTecnico";
import PrestadorResponsavel from "../pages/prestadorResponsavel/view/prestadorResponsavel";
import IncluirEditarPrestadorResponsavel from "../pages/prestadorResponsavel/view/incluirEditarPrestadorResponsavel";
import ConsultaGrupoServico from "../pages/grupoServico/view/consultaGrupoServico";
import ConsultaOS from "../pages/consultaOS/view/consultarOS";
import DetalhesOS from "../pages/detalhesOS/view/detalhesOS";
import AcessoNegado from "../pages/acessoNegado/view/acessoNegado";
import IncluirEditarGrupoServico from "../pages/grupoServico/view/incluirEditarGrupoServico";
import ParametroInsucesso from "../pages/parametroInsucesso/view/parametroInsucesso";
import ConsultaServico from "../pages/servicos/view/consultaServico";
import IncluirEditarServico from "../pages/servicos/view/incluirEditarServico";
import ChangePassword from "../pages/changePassword/view/changePassword";
import ConsultaEstados from '../pages/estados/view/consultaEstados'
import ConsultaCidades from "../pages/cidades/view/consultaCidades";
import IncluirEditarMetasProdutividade from "../pages/metasProdutividade/view/incluirEditarMetasProdutividade";
import { ConsultServiceOrderStatusPage } from "../pages/controleStatus/view/check-status";
import IncluirEditarStatusOrdemServico from "../pages/controleStatus/view/incluirEditarStatusOrdemServico";
import ConsultaRegiao from "../pages/regioes/view/consultaRegiao";
import IncluirEditarRegiao from "../pages/regioes/view/incluirEditarRegiao";
import IncluirEditarCidade from "../pages/cidades/view/incluirEditarCidade";
import IncluirEditarEstado from "../pages/estados/view/incluirEditarEstados";
import RedefinirSenha from "../pages/redefinirSenha/view/redefinirSenha";
import ResetSenha from "../pages/resetSenha/view/resetSenha";
import ValidarEmail from "../pages/validarEmail/view/validarEmail";
import ValidarResetSenha from "../pages/validarResetSenha/view/validarResetSenha";
import IncluirEditarParametro from "../pages/parametro/view/incluirEditarParametro";
import ConsultaParametro from "../pages/parametro/view/consultaParametro";
import ConsultarTermoOs from "../pages/termoDeOs/view/consultarTermoOs";
import IncluirTermoOs from "../pages/termoDeOs/view/incluirTermoOs";
import ConsultarChecklist from "../pages/checklist/view/consultarChecklist";
import ConsultaAgrupamentoModelo from "../pages/agrupamentoModelo/view/consultaAgrupamentoModelo";
import IncluirEditarAgrupamento from "../pages/agrupamentoModelo/view/incluirEditarAgrupamento";
import ConsultarParametroItem from "../pages/parametroItemChecklist/view/consultarParametroItem";
import ConsultarHistoricoParametroItem from "../pages/parametroItemChecklist/view/consultarHistoricoParametroItem";
import ParametrosRoterizador from "../pages/roteirizador/view/parametrosRoteirizador";
import CriarPrestadorVeiculo from "../pages/prestadorVeiculo/view/criarPrestadorVeiculo";
import ConsultarPrestadorVeiculo from "../pages/prestadorVeiculo/view/consultarPrestadorVeiculo";
import EditarPrestadorVeiculo from "../pages/prestadorVeiculo/view/editarPrestadorVeiculo";
import RotasOtimizadas from "../pages/roteitizadorRotasOtimizadas/view/rotasOtimizadas";
// import { ConsultAllowanceJustificationPage } from "../pages/justificativaAbono/view/consult-allowance";
// import { RegisterAllowanceJustificationPage } from "../pages/justificativaAbono/view/register-allowance";
import IncluirEditarTermoOs from "../pages/termoDeOs/view/incluirEditarTermoOs";
import ConfirmarDevolucao from "../pages/enviarTecnico/view/confirmarDevolucao";
import ConsultaAjusteMassivoOS from "../pages/ajusteMassivoOS/view/consultaAjusteMassivoOS";
import AjusteMassivoOS from "../pages/ajusteMassivoOS/view/ajusteMassivoOS";
import MensagensInsucesso from "../pages/mensagensInsucesso/view/mensagensInsucesso";
import IncluirEditarMensagemInsucesso from "../pages/mensagensInsucesso/view/incluirEditarMensagemInsucesso";
import ConsultarChecklistCompleto from "../pages/checklistCompleto/view/consultarChecklistCompleto";
import { ConsultAllowanceJustificationPage } from "../pages/justificativaAbono/view/consult-allowance";
import { RegisterAllowanceJustificationPage } from "../pages/justificativaAbono/view/register-allowance";
import ConsultaContratante from "../pages/contratante/view/consultaContratante";
import IncluirEditarContratante from "../pages/contratante/view/incluirEditarContratante";

import AlterarProcedimento from "../pages/procedimentoServico/view/alterarProcedimento";
import IncluirProcedimento from "../pages/procedimentoServico/view/incluirProcedimento";
import ConsultarProcedimento from "../pages/procedimentoServico/view/consultarProcedimento";

export const pagesComponentList: any = {
    Login: <Login />,
    Verify2FA: <Verify2FA />,
    ChangePassword: <ChangePassword />,
    Dashboard: <Dashboard />,
    FarolOS: <FarolOS />,
    EncaminharOS: <EncaminharOS />,
    OsPendente: <OsPendente />,
    EstoqueTecnico: <EstoqueTecnico />,
    EnviarEquipamento: <EnviarTecnico />,
    ResumoEquipamentos: <ResumoEquipamentos />,
    // CadastrarOM: <CadastrarOM />,
    // ConsultaOM: <ConsultaOM />,
    // EditarOM: <EditarOM />,
    ConsultaFamilia: <ConsultaFamilia />,
    IncluirFamilia: <IncluirOuAlterarFamilia />,
    ConsultaUsuario: <ConsultaUsuario />,
    IncluirUsuario: <IncluirOuAlterarUsuario />,
    TipoEquipamento: <TipoEquipamento />,
    ConsultarPerfil: <ConsultarPerfil />,
    IncluirPerfil: <IncluirPerfil />,
    EditarPerfil: <IncluirPerfil />,
    IncluirTipoEquipamento: <IncluirEditarTipoEquipamento />,
    EditarTipoEquipamento: <IncluirEditarTipoEquipamento />,
    ConsultaModelo: <ConsultaModelo />,
    IncluirModelo: <IncluirEditarModelo />,
    EditarModelo: <IncluirEditarModelo />,
    ConsultaPrestador: <ConsultaPrestador />,
    IncluiPrestador: <IncluirEditarPrestador />,
    EditaPrestador: <IncluirEditarPrestador />,
    ResumoApp: <ResumoApp />,
    ConsultaTecnico: <ConsultaTecnico />,
    IncluiTecnico: <IncluirEditarTecnico />,
    EditaTecnico: <IncluirEditarTecnico />,
    ConsultaGrupoServico: <ConsultaGrupoServico />,
    EditaGrupoServico: <IncluirEditarGrupoServico />,
    IncluiGrupoServico: <IncluirEditarGrupoServico />,
    NotFound: <AcessoNegado />,
    AcessoNegado: <AcessoNegado />,
    ParametroInsucesso: <ParametroInsucesso />,
    ConsultaOS: <ConsultaOS />,
    DetalhesOS: <DetalhesOS />,
    PrestadorResponsavel: <PrestadorResponsavel />,
    IncluiPrestadorResponsavel: <IncluirEditarPrestadorResponsavel />,
    EditarPrestadorResponsavel: <IncluirEditarPrestadorResponsavel />,
    // MetasProdutividade: <MetasProdutividade />,
    EditarMetasProdutividade: <IncluirEditarMetasProdutividade />,
    IncluirMetasProdutividade: <IncluirEditarMetasProdutividade />,
    Servicos: <ConsultaServico />,
    EditarServico: <IncluirEditarServico />,
    IncluirServico: <IncluirEditarServico />,
    ConsultarStatusOS: <ConsultServiceOrderStatusPage />,
    IncluirStatusOS: <IncluirEditarStatusOrdemServico />,
    EditarStatusOS: <IncluirEditarStatusOrdemServico />,
    ConsultarRegioes: <ConsultaRegiao />,
    IncluirRegiao: <IncluirEditarRegiao />,
    EditarRegiao: <IncluirEditarRegiao />,
    ConsultarCidades: <ConsultaCidades />,
    IncluirCidade: <IncluirEditarCidade />,
    EditarCidade: <IncluirEditarCidade />,
    ConsultarEstados: <ConsultaEstados />,
    IncluirEstado: <IncluirEditarEstado />,
    EditarEstado: <IncluirEditarEstado />,
    RedefinirSenha: <RedefinirSenha />,
    ResetSenha: <ResetSenha />,
    ValidarEmail: <ValidarEmail />,
    ValidarResetSenha: <ValidarResetSenha />,
    ConsultarJustificativaAbono: <ConsultAllowanceJustificationPage />,
    CadastrarJustificativaAbono: <RegisterAllowanceJustificationPage />,
    EditarJustificativaAbono: <RegisterAllowanceJustificationPage />,
    ConsultarParametros: <ConsultaParametro />,
    IncluirParametro: <IncluirEditarParametro />,
    EditarParametro: <IncluirEditarParametro />,
    ConsultarTermoOs: <ConsultarTermoOs />,
    IncluirTermoOs: <IncluirEditarTermoOs />,
    EditarTermoOs: <IncluirEditarTermoOs />,
    ConsultarAgrupamentoModelo: <ConsultaAgrupamentoModelo />,
    IncluirAgrupamentoModelos: <IncluirEditarAgrupamento />,
    EditarAgrupamentoModelos: <IncluirEditarAgrupamento />,
    ConsultarChecklist: <ConsultarChecklist />,
    ConsultarParametroItem: <ConsultarParametroItem />,
    ConsultarHistoricoParametroItem: <ConsultarHistoricoParametroItem/>,
    ConfirmarDevolucao: <ConfirmarDevolucao />,
    ConsultaAjusteMassivoOS: <ConsultaAjusteMassivoOS />,
    AjusteMassivoOS: <AjusteMassivoOS />,
    ParametrosRoterizador: <ParametrosRoterizador />,
    CriarPrestadorVeiculo: <CriarPrestadorVeiculo/>,
    ConsultarPrestadorVeiculo: <ConsultarPrestadorVeiculo/>,
    EditarPrestadorVeiculo: <EditarPrestadorVeiculo/>,
    RotasOtimizadas: <RotasOtimizadas />,
    MensagensInsucesso: <MensagensInsucesso />,
    IncluirMensagemInsucesso: <IncluirEditarMensagemInsucesso />,
    ConsultarChecklistCompleto:<ConsultarChecklistCompleto/>,
    ConsultarContratantes: <ConsultaContratante />,
    IncluirContratante: <IncluirEditarContratante />,
    EditarContratante: <IncluirEditarContratante />,
    IncluirProcedimento: <IncluirProcedimento />,
    AlterarProcedimento: <AlterarProcedimento />,
    ConsultarProcedimento:<ConsultarProcedimento/>
};

export const privateURLsList = [
    { name: "ChangePassword", url: "/ChangePassword" },
    { name: "Dashboard", url: "/Dashboard" },
    { name: "FarolOS", url: "/FarolOS" },
    { name: "EncaminharOS", url: "/EncaminharOS" },
    { name: "OsPendente", url: "/OsPendente" },
    { name: "EstoqueTecnico", url: "/EstoqueTecnico" },
    { name: "EnviarEquipamento", url: "/EnviarEquipamento" },
    { name: "ResumoEquipamentos", url: "/ResumoEquipamentos" },
    { name: "CadastrarOM", url: "/CadastrarOM" },
    { name: "ConsultaOM", url: "/ConsultaOM" },
    { name: "EditarOM", url: "/EditarOM" },
    { name: "ConsultaFamilia", url: "/ConsultaFamilia" },
    { name: "IncluirFamilia", url: "/IncluirFamilia" },
    { name: "ConsultaUsuario", url: "/ConsultaUsuario" },
    { name: "IncluirUsuario", url: "/IncluirUsuario" },
    { name: "TipoEquipamento", url: "/Equipamento" },
    { name: "ConsultarPerfil", url: "/ConsultarPerfil" },
    { name: "IncluirPerfil", url: "/IncluirPerfil" },
    { name: "EditarPerfil", url: "/EditarPerfil" },
    { name: "IncluirTipoEquipamento", url: "/IncluirTipoEquipamento" },
    { name: "EditarTipoEquipamento", url: "/EditarTipoEquipamento" },
    { name: "ConsultaModelo", url: "/Modelo" },
    { name: "EditarModelo", url: "/EditarModelo" },
    { name: "IncluirModelo", url: "/IncluirModelo" },
    { name: "IncluiPrestador", url: "/IncluiPrestador" },
    { name: "EditaPrestador", url: "/EditaPrestador" },
    { name: "ConsultaPrestador", url: "/ConsultaPrestador" },
    { name: "ResumoApp", url: "/ResumoApp" },
    { name: "ConsultaTecnico", url: "/ConsultaTecnico" },
    { name: "EditaTecnico", url: "/EditarTecnico" },
    { name: "IncluiTecnico", url: "/IncluirTecnico" },
    { name: "ConsultaGrupoServico", url: "/ConsultaGrupoServico" },
    { name: "IncluiGrupoServico", url: "/IncluiGrupoServico" },
    { name: "EditaGrupoServico", url: "/EditaGrupoServico" },
    { name: "NotFound", url: "/NotFound" },
    { name: "AcessoNegado", url: "/AcessoNegado" },
    { name: "ParametroInsucesso", url: "/ParametroInsucesso" },
    { name: "ConsultaOS", url: "/ConsultaOS" },
    { name: "DetalhesOS", url: "/DetalhesOS" },
    { name: "PrestadorResponsavel", url: "/ConsultaPrestadorResponsavel" },
    { name: "IncluiPrestadorResponsavel", url: "/IncluiPrestadorResponsavel" },
    // { name: "MetasProdutividade", url: "/MetasProdutividade" },
    { name: "IncluirMetasProdutividade", url: "/IncluirMetasProdutividade" },
    { name: "EditarMetasProdutividade", url: "/EditarMetasProdutividade" },
    { name: "EditarPrestadorResponsavel", url: "/EditaPrestadorResponsavel" },
    { name: "Servicos", url: "/Servicos" },
    { name: "EditarServico", url: "/EditarServico" },
    { name: "IncluirServico", url: "/IncluirServico" },
    { name: "ConsultarStatusOS", url: "/ConsultarStatusOS" },
    { name: "IncluirStatusOS", url: "/IncluirStatusOS" },
    { name: "EditarStatusOS", url: "/EditarStatusOS" },
    { name: "ConsultarRegioes", url: "/ConsultarRegioes" },
    { name: "IncluirRegiao", url: "/IncluirRegiao" },
    { name: "EditarRegiao", url: "/EditarRegiao" },
    { name: "ConsultarCidades", url: "/ConsultarCidades" },
    { name: "IncluirCidade", url: "/IncluirCidade" },
    { name: "EditarCidade", url: "/EditarCidade" },
    { name: "ConsultarEstados", url: "/ConsultarEstados" },
    { name: "IncluirEstado", url: "/IncluirEstado" },
    { name: "EditarEstado", url: "/EditarEstado" },
    { name: "ConsultarJustificativaAbono", url: "/ConsultarJustificativaAbono" },
    { name: "CadastrarJustificativaAbono", url: "/CadastrarJustificativaAbono" },
    { name: "EditarJustificativaAbono", url: "/EditarJustificativaAbono" },
    { name: "ConsultarParametros", url: "/ConsultarParametros" },
    { name: "IncluirParametro", url: "/IncluirParametro" },
    { name: "EditarParametro", url: "/EditarParametro" },
    { name: "ConsultarFluxoDeTrabalho", url: "/ConsultarFluxoDeTrabalho" },
    { name: "IncluirFluxoDeTrabalho", url: "/IncluirFluxoDeTrabalho" },
    { name: "EditarFluxoDeTrabalho", url: "/EditarFluxoDeTrabalho" },
    { name: "ConfiguracaoOrquestrador", url: "/ConfiguracaoOrquestrador" },
    { name: "ConsultarTermoOs", url: "/ConsultarTermoOs" },
    { name: "IncluirTermoOs", url: "IncluirTermoOs" },
    { name: "EditarTermoOs", url: "EditarTermoOs" },
    { name: "ConsultarAgrupamentoModelo", url: "/ConsultarAgrupamentoModelo" },
    { name: "IncluirAgrupamentoModelos", url: "/IncluirAgrupamentoModelos" },
    { name: "EditarAgrupamentoModelos", url: "/EditarAgrupamentoModelos" },
    { name: "ConsultarChecklist", url: "/ConsultarChecklist" },
    { name: "ConsultarParametroItem", url: "/ConsultarParametroItem" },
    { name: "ConsultarHistoricoParametroItem", url: "/ConsultarHistoricoParametroItem" },
    { name: "ConfirmarDevolucao", url: "/ConfirmarDevolucao" },
    { name: "ConsultaAjusteMassivoOS", url: "/ConsultaAjusteMassivoOS" },
    { name: "AjusteMassivoOS", url: "/AjusteMassivoOS" },
    { name: "ParametrosRoterizador", url: "/ParametrosRoteirizador" },
    { name: "IncluirAgrupamentoModelos", url: "/IncluirAgrupamentoModelos" },
    { name: "EditarAgrupamentoModelos", url: "/EditarAgrupamentoModelos" },
    { name: "ConsultarParametroItem", url: "/ConsultarParametroItem" },
    { name: "ConsultarHistoricoParametroItem", url: "/ConsultarHistoricoParametroItem" },
    { name: "ConsultarPrestadorVeiculo", url: "/ConsultarPrestadorVeiculo" },
    { name: "CriarPrestadorVeiculo", url: "/CriarPrestadorVeiculo" },
    { name: "EditarPrestadorVeiculo", url: "/EditarPrestadorVeiculo" },
    { name: "ParametrosRoterizador", url: "/ParametrosRoterizador" },
    { name: "RotasOtimizadas", url: "/RotasOtimizadas" },
    { name: "MensagensInsucesso", url: "/MensagensInsucesso" },
    { name: "IncluirMensagemInsucesso", url: "/IncluirMensagemInsucesso" },
    { name: "ConsultarChecklistCompleto", url: "/ConsultarChecklistCompleto" },
    { name: "IncluirProcedimento", url: "/IncluirProcedimento" },
    { name: "AlterarProcedimento", url: "/AlterarProcedimento" },
    { name: "ConsultarProcedimento", url: "/ConsultarProcedimento" },
    { name: "ConsultarContratantes", url: "/ConsultarContratantes" },
    { name: "IncluirContratante", url: "/IncluirContratante" },
    { name: "EditarContratante", url: "/EditarContratante" },
];

export const publicURLsList = [
    { name: "Login", url: "/Login" },
    { name: "Verify2FA", url: "/Verify2FA" },
    { name: "RedefinirSenha", url: "/RedefinirSenha" },
    { name: "ResetSenha", url: "/ResetSenha" },
    { name: "ValidarResetSenha", url: "/ValidarResetSenha" },
    { name: "ValidarEmail", url: "/ValidarEmail" },
];
