import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IContratantesDTO, IGetContratanteFiltrado } from "../types";
import _ from 'lodash';

const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

const verificaTipoDocumento = (documento: string) => {
  // Remove caracteres não numéricos
  const apenasNumeros = documento.replace(/\D/g, '');
  
  if (apenasNumeros.length === 11) {
    return { tipo: 'CPF', valor: apenasNumeros };
  } else if (apenasNumeros.length === 14) {
    return { tipo: 'CNPJ', valor: apenasNumeros };
  }
  return null;
};

export const getContratanteServicoParametrosComFiltro = async (params: IContratantesDTO) =>
  await apiGet(`${baseUrlOrdemServico}/ContratanteServicoParametros`, params)

export const getContratantePorId = (id: number | string) =>
  apiGet(`${baseUrlOrdemServico}/Contratante/${id}`)

export const putContratante = async (params: any) =>
  await apiPut(`${baseUrlOrdemServico}/contratante_/`, params)


export const postContratante = (params: any) =>
  apiPost(`${baseUrlOrdemServico}/contratante_`, params)

export const getContratantes = async () =>
  await apiGet(`${baseUrlOrdemServico}/contratante_/consulta-com-filtros`)

export const getListaContratantesComFiltro = async ({
  nomeFantasia,
  razaoSocial,
  codigoContratante,
  cpfCnpj,
  ativo,
  pageNumber,
  pageSize,
}: IGetContratanteFiltrado) => {
  let Filtro: string = '';
  if (nomeFantasia) Filtro = `?Nome=${nomeFantasia}`;
  if (razaoSocial) Filtro = _.isEmpty(Filtro) ? `?RazaoSocial=${razaoSocial}` : `${Filtro}&RazaoSocial=${razaoSocial}`;
  if (codigoContratante) Filtro = _.isEmpty(Filtro) ? `?Codigo=${codigoContratante}` : `${Filtro}&Codigo=${codigoContratante}`;

  if (cpfCnpj) {
    const documento = verificaTipoDocumento(cpfCnpj);
    if (documento) {
      const paramName = documento.tipo === 'CPF' ? 'Cpf' : 'Cnpj';
      Filtro = _.isEmpty(Filtro) 
        ? `?${paramName}=${documento.valor}` 
        : `${Filtro}&${paramName}=${documento.valor}`;
    }
  }

  if (!_.isNull(ativo) && !_.isUndefined(ativo)) Filtro = _.isEmpty(Filtro) ? `?Ativo=${ativo}` : `${Filtro}&Ativo=${ativo}`;
  if (_.isEmpty(Filtro)) {
    Filtro = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
  } else Filtro = `${Filtro}&PageNumber=${pageNumber}&PageSize=${pageSize}`;
  
  return await apiGet(`${baseUrlOrdemServico}/contratante_/consulta-com-filtros${Filtro}`)
}


