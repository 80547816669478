import React from 'react';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  FormControl,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import { FormularioData, ListTecnico } from '../types';

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '400px',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
  },
}));

interface TecnicoModalProps {
  open: boolean;
  isEditing: boolean;
  formData: FormularioData;
  errors: { tecnicId: string; intervaloDias: string };
  tecnicos: ListTecnico[];
  onClose: () => void;
  onSubmit: () => void;
  validateForm: () => boolean;
  onChange: (field: string, value: any) => void; // Adicionada esta linha
}

const TecnicoModal: React.FC<TecnicoModalProps> = ({
  open,
  isEditing,
  formData,
  errors,
  tecnicos,
  onClose,
  onSubmit,
  validateForm,
  onChange, // Adicionada esta linha
}) => {
  const handleIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      onChange('intervaloDias', value); // Usando a função onChange
    }
  };

  return (
    <StyledDrawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {/* Header */}
        <Box sx={{
          borderBottom: '1px solid #e0e0e0',
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <IconButton onClick={onClose} sx={{ mb: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">
            {isEditing ? 'Editar Técnico' : 'Adicione um novo Técnico'}
          </Typography>
        </Box>

        {/* Content */}
        <Box sx={{
          flexGrow: 1,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}>
          <FormControl fullWidth error={!!errors.tecnicId}>
            <Autocomplete
              id="tecnico-select"
              options={tecnicos}
              getOptionLabel={(option) => option.nome}
              value={tecnicos.find((tech) => tech.idTecnico === formData.tecnicoId) || null}
              onChange={(event, newValue) => {
                onChange('tecnicoId', newValue ? newValue.idTecnico : 0);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Técnico"
                  error={!!errors.tecnicId}
                  helperText={errors.tecnicId}
                />
              )}
              disabled={isEditing}
            />
          </FormControl>

          <TextField
            label="Intervalo de tempo (em dias)"
            type="text"
            fullWidth
            value={formData.intervaloDias}
            onChange={handleIntervalChange}
            error={!!errors.intervaloDias}
            helperText={errors.intervaloDias || 'Esse campo apenas aceita números'}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
            }}
          />
        </Box>

        {/* Footer */}
        <Box sx={{
          borderTop: '1px solid #e0e0e0',
          p: 2,
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}>
          <Button
            variant="contained"
            onClick={() => {
              if (validateForm()) {
                onSubmit();
              }
            }}
            color="primary"
            sx={{
              width: '90%',
              maxWidth: '400px',
            }}
          >
            {isEditing ? 'Salvar' : 'Adicionar'}
          </Button>
        </Box>
      </Box>
    </StyledDrawer>
  );
};

export default TecnicoModal;
