import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IGetJustificativaAbono, IPostJustificativaAbono, IPutJustificativaAbono } from "../types";

const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

export const getListaJustificativaAbonoComFiltro = async (params: IGetJustificativaAbono) =>
    await apiGet(`${baseUrlOrdemServico}/JustificativaAbono`, params);

export const postJustificativaAbono = async (params: IPostJustificativaAbono) =>
    await apiPost(`${baseUrlOrdemServico}/JustificativaAbono`, params)
  
export const putJustificativaAbono = async (params: IPutJustificativaAbono, id: number | string) =>
    await apiPut(`${baseUrlOrdemServico}/JustificativaAbono/${id}`, params)
