import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth";
import { HomeContext } from "../../../contexts/homeContext";
import * as Yup from "yup";
import { Form, Formik } from 'formik';
import { AxiosResponse } from "axios";
import { postContratante, putContratante } from "../services/services";
import Header from "../../../components/header/header";
import _, { set } from "lodash";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import { CircularProgress, Button, Tab, Tabs } from "@mui/material";
import Input from '../../../components/Input/Input'
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import ConfiguracaoTab from './configuracaoTab';
import GeralTab from './geralTab';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId'
import { createSuccessMsg, createErrorMsg } from "../../../services/api";
import { IIncluirEditarContratanteInitialValues, IPUTContratante } from "../types";
import './incluirEditarContratante.scss';
import { removeSpecialCharSimple } from '../../../helpers/removeSpecialCharSimple';
import { error } from "console";


const IncluirEditarContratante = () => {

    const {
        setMenuLateral,
        contratanteSelected,
        setContratanteSelected,
        documento,
        cidadeContratante,
        estadoContratante,
        CEP,
        contato,
        tipoEndereco,
        estadoListaContratante,
        pessoa,
        setTipoEndereco,
        setPais,
        setTipoContratante,
        setCEP,
        setCidadeContratante,
        cidadeListaContratante,
        setEstadoContratante,
        setEmailError,
    } = useContext(HomeContext);

    const isIncluding = window.location.pathname.includes('IncluirContratante');

    const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
        isIncluding ? "Incluir Contratante" : "Editar Contratante",
        funcionalidade);

    const localStatus = !_.isEmpty(contratanteSelected)
        ? [Number(contratanteSelected?.ativo)?.toString()]
        : ['1'];

    const navigate = useNavigate();

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
    const [msgAlert, setMsgAlert] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [disableActions, setDisableActions] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [tabValue, setTabValue] = useState(0);
    const [status, setStatus] = useState<string[]>(localStatus);
    const [estadoLocal, setEstadoLocal] = useState<{ name: string; id: string; uf: string; }[]>([]);
    const [cidadeLocal, setCidadeLocal] = useState<{ name: string; id: string; }[]>([]);


    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const initialValues: IIncluirEditarContratanteInitialValues = {
        contato: '',
        cidade: [],
        email: '',
        telefone: '',
        estado: [],
        cep: '',
        numero: '',
        complemento: '',
        ativo: true,
        nomeFantasia: '',
        razaoSocial: '',
        pessoa: '',
        documento: '',
        inscricaoEstadual: '',
        endereco: '',
        tipoEndereco: [],
        tempoGarantia: '',
        codigo: '',
        slaTratativa: '',
        bairro: '',
        pais: 'Brasil',

        //Aba Configurações
        wsLogin: '',
        wsPass: '',
        fornecedorSAP: '',
        integracaoSAP: false,
        integraAbono: false,
        sincronizacaoFoto: false,
        validaTrocaNumeroSerie: false,
        mobileControleChip: false,
        mobileBaixaAutomaticaTodosServicos: false,
        mobileBaixaAutomaticaServicosIndividuais: false,
        habilitaChatbotInsucesso: false,
        habilitaChatbotTracking: false,
    };
    
    const initialValuesEditar: IIncluirEditarContratanteInitialValues = useMemo(() => ({
        contato: contratanteSelected?.contato || '',
        cidade: cidadeLocal || [],
        email: contratanteSelected?.email || '',
        telefone: contratanteSelected?.telefone || '',
        estado: estadoLocal || [],
        cep: contratanteSelected?.cep || '',
        numero: contratanteSelected?.numero || '',
        complemento: contratanteSelected?.complemento || '',
        ativo: contratanteSelected?.ativo,
        nomeFantasia: contratanteSelected?.nomeFantasia || '',
        razaoSocial: contratanteSelected?.razaoSocial || '',
        pessoa: contratanteSelected?.tipo || pessoa || '',
        documento: contratanteSelected?.cpfCnpj || '',
        
        inscricaoEstadual: contratanteSelected?.inscricaoEstadual,
        endereco: contratanteSelected?.endereco,
        tipoEndereco: contratanteSelected?.tipoEndereco ? [contratanteSelected.tipoEndereco] : [],
        tempoGarantia: contratanteSelected?.tempoGarantia,
        codigo: contratanteSelected?.codigo,
        slaTratativa: contratanteSelected?.slaTratativa,
        bairro: contratanteSelected?.bairro,
        pais: contratanteSelected?.pais || 'Brasil',

        //Aba Configurações
        wsLogin: contratanteSelected?.wsLogin || '',
        wsPass: contratanteSelected?.wsPass || '',
        fornecedorSAP: contratanteSelected?.fornecedorSAP || '',

        integracaoSAP: contratanteSelected?.integracaoSAP || false,
        integraAbono: contratanteSelected?.integraAbono || false,
        sincronizacaoFoto: contratanteSelected?.sincronizacaoFoto || false,
        
        validaTrocaNumeroSerie: contratanteSelected?.validaTrocaNumeroSerie || false,
        mobileControleChip: contratanteSelected?.mobileControleDeChip,
        mobileBaixaAutomaticaTodosServicos: contratanteSelected?.mobileBaixaAutomaticaTodosServicos || false,

        mobileBaixaAutomaticaServicosIndividuais: contratanteSelected?.mobileBaixaAutomaticaServicosIndividuais || false,
        habilitaChatbotInsucesso: contratanteSelected?.habilitaChatbotInsucesso || false,
        habilitaChatbotTracking: contratanteSelected?.habilitaChatbotTracking || false,
    }), [
        contratanteSelected, estadoLocal, cidadeLocal, pessoa
    ]);

    const validationSchema = Yup.object({
        razaoSocial: Yup.string().required('Razão Social é obrigatória'),
        nomeFantasia: Yup.string().required('Nome Fantasia é obrigatório'),
        pessoa: Yup.string().required('Pessoa Física ou Jurídica é obrigatório'),
        documento: Yup.string().when('pessoa', {
            is: 'pf',
            then: Yup.string()
                .required('CPF é obrigatório')
                .test('cpf', 'CPF inválido', (value) => {
                    const numerosSemMascara = value?.replace(/\D/g, '');
                    return numerosSemMascara?.length === 11;
                }),
            otherwise: Yup.string()
                .required('CNPJ é obrigatório')
                .test('cnpj', 'CNPJ inválido', (value) => {
                    const numerosSemMascara = value?.replace(/\D/g, '');
                    return numerosSemMascara?.length === 14;
                })
        }),
        contato: Yup.string().required('Contato é obrigatório'),
        email: Yup.string().email('Email inválido').required('Email é obrigatório'),
        telefone: Yup.string().required('Telefone é obrigatório'),
        tipoEndereco: Yup.array().of(Yup.string()).min(1, 'Tipo de Endereço é obrigatório'),
        endereco: Yup.string().required('Endereço é obrigatório'),
        numero: Yup.string().required('Número é obrigatório'),
        bairro: Yup.string().required('Bairro é obrigatório'),
        cep: Yup.string().required('CEP é obrigatório'),
        estado: Yup.array().of(Yup.object().shape({
            name: Yup.string().required(),
            id: Yup.string().required(),
            uf: Yup.string().required(),
        })).min(1, 'Estado é obrigatório'),
        cidade: Yup.array().of(Yup.object().shape({
            name: Yup.string().required(),
            id: Yup.string().required(),
        })).min(1, 'Cidade é obrigatória'),
        tempoGarantia: Yup.string().required('Tempo de Garantia é obrigatório'),

    });

    const requestSalvarAPI = async (values: IIncluirEditarContratanteInitialValues) => {
        setLoading(true);

        try {
            /**Atualiza contratante selecionado */
            const changedFields = getChangedFields(values);

            // Se não houver campos alterados além do ID, não faz a requisição
            if (Object.keys(changedFields).length < 1) {
                setMsgAlert("Nenhum campo foi alterado");
                setTipoMsgAlert("info");
                setOpenAlert(true);
                setLoading(false);
                return;
            }

            const { data } = await (putContratante(changedFields)) as AxiosResponse;

            setLoading(false);
            setMsgAlert(createSuccessMsg(data, "Dados do contratante atualizados com sucesso"));
            setTipoMsgAlert("success");
            setOpenAlert(true);

            setContratanteSelected({
                ...contratanteSelected,
                ...changedFields
            });
            /**END Atualiza contratante selecionado*/

        } catch (e: any) {
            setLoading(false);
            setMsgAlert(createErrorMsg(e, "Houve algum erro ao salvar os dados."));
            setTipoMsgAlert("error");
            setOpenAlert(true);
        }
    };

    const requestCadastrarAPI = async (values: IIncluirEditarContratanteInitialValues) => {
        setLoading(true);

        try {
            let documentoParams = {};
            if (values.documento) {
                documentoParams = {
                    cpfCnpj: values.documento.replace(/\D/g, '')
                };
            }

            const params = {
                ativo: status[0] === '1' ? true : false,
                razaoSocial: values.razaoSocial,
                nomeFantasia: values.nomeFantasia,
                nome: values.nomeFantasia,
                email: values.email,
                codigo: values.codigo,
                telefone: values.telefone,
                endereco: values.endereco,
                bairro: values.bairro,
                numero: values.numero,
                complemento: values.complemento,
                pais: values.pais,
                cidade: cidadeContratante[0]?.name,
                estado: estadoContratante[0]?.uf,
                cep: CEP,
                contato: values.contato,
                tempoGarantia: values.tempoGarantia,
                inscricaoEstadual: values.inscricaoEstadual,
                tipoEndereco: Array.isArray(tipoEndereco) ? tipoEndereco[0] : tipoEndereco,
                integracaoSAP: values.integracaoSAP,
                integraAbono: values.integraAbono,
                validaTrocaNumeroSerie: values.validaTrocaNumeroSerie,
                sincronizacaoFoto: values.sincronizacaoFoto,
                mobileControleDeChip: values.mobileControleChip,
                mobileBaixaAutomaticaTodosServicos: values.mobileBaixaAutomaticaTodosServicos,
                mobileBaixaAutomaticaServicosIndividuais: values.mobileBaixaAutomaticaServicosIndividuais,
                habilitaChatbotInsucesso: values.habilitaChatbotInsucesso,
                habilitaChatbotTracking: values.habilitaChatbotTracking,
                slaTratativa: values.slaTratativa,
                tipo: values.pessoa,
                ...documentoParams,
                fornecedorSAP: values.fornecedorSAP,
                wsLogin: values.wsLogin,
                wsSenha: values.wsPass,
            };

            const { data } = (await postContratante(params)) as AxiosResponse;

            setLoading(false);
            
            setMsgAlert(createSuccessMsg(data, "Contratante cadastrado com sucesso"));
            setTipoMsgAlert("success");
            setOpenAlert(true);

        } catch (e: any) {
            setLoading(false);
            setMsgAlert(createErrorMsg(e, "Houve algum erro ao cadastrar o contratante."));
            setTipoMsgAlert("error");
            setOpenAlert(true);
        }
    };

    const getChangedFields = (values: IIncluirEditarContratanteInitialValues) => {
        const changedFields: Partial<IPUTContratante> = {
            id: contratanteSelected.id // ID é sempre necessário para atualização
        };

        // Verifica cada campo e adiciona apenas os que foram alterados
        if (status[0] === '1' !== contratanteSelected.ativo) {
            changedFields.ativo = status[0] === '1';
        }

        if (values.razaoSocial !== contratanteSelected.razaoSocial) {
            changedFields.razaoSocial = values.razaoSocial;
        }

        if (values.nomeFantasia !== contratanteSelected.nomeFantasia) {
            changedFields.nomeFantasia = values.nomeFantasia;
        }

        if (documento && documento.replace(/\D/g, '') !== contratanteSelected.cpfCnpj?.replace(/\D/g, '')) {
            changedFields.cpfCnpj = documento.replace(/\D/g, '');
        }

        if (values.inscricaoEstadual !== contratanteSelected.inscricaoEstadual) {
            changedFields.inscricaoEstadual = values.inscricaoEstadual;
        }

        if (values.email !== contratanteSelected.email)
            changedFields.email = values.email;

        if (values.telefone !== contratanteSelected.telefone) {
            changedFields.telefone = values.telefone;
        }

        if (values.endereco !== contratanteSelected.endereco) {
            changedFields.endereco = values.endereco;
        }

        if (values.bairro !== contratanteSelected.bairro) {
            changedFields.bairro = values.bairro;
        }

        if (values.numero !== contratanteSelected.numero) {
            changedFields.numero = values.numero;
        }

        if (values.complemento !== contratanteSelected.complemento) {
            changedFields.complemento = values.complemento;
        }

        if (values.cidade && values.cidade[0]?.name !== contratanteSelected.cidade) {
            changedFields.cidade = values.cidade[0]?.name;
        }

        if (values.estado && values.estado[0]?.uf !== contratanteSelected.estado) {
            changedFields.estado = values.estado[0]?.uf;
        }

        if (values.cep !== contratanteSelected.cep) {
            changedFields.cep = values.cep;
        }

        if (values.contato !== contratanteSelected.contato) {
            changedFields.contato = values.contato;
        }

        if (values.tempoGarantia !== contratanteSelected.tempoGarantia) {
            changedFields.tempoGarantia = values.tempoGarantia;
        }

        if (Array.isArray(tipoEndereco) ? tipoEndereco[0] !== contratanteSelected.tipoEndereco : tipoEndereco !== contratanteSelected.tipoEndereco) {
            changedFields.tipoEndereco = Array.isArray(tipoEndereco) ? tipoEndereco[0] : tipoEndereco;
        }

        if (values.integracaoSAP !== contratanteSelected.integracaoSAP) {
            changedFields.integracaoSAP = values.integracaoSAP;
        }

        if (values.integraAbono !== contratanteSelected.integraAbono) {
            changedFields.integraAbono = values.integraAbono;
        }

        if (values.validaTrocaNumeroSerie !== contratanteSelected.validaTrocaNumeroSerie) {
            changedFields.validaTrocaNumeroSerie = values.validaTrocaNumeroSerie;
        }

        if (values.sincronizacaoFoto !== contratanteSelected.sincronizacaoFoto) {
            changedFields.sincronizacaoFoto = values.sincronizacaoFoto;
        }

        if (values.mobileControleChip !== contratanteSelected.mobileControleDeChip) {
            changedFields.mobileControleDeChip = values.mobileControleChip;
        }

        if (values.mobileBaixaAutomaticaTodosServicos !== contratanteSelected.mobileBaixaAutomaticaTodosServicos) {
            changedFields.mobileBaixaAutomaticaTodosServicos = values.mobileBaixaAutomaticaTodosServicos;
        }

        if (values.mobileBaixaAutomaticaServicosIndividuais !== contratanteSelected.mobileBaixaAutomaticaServicosIndividuais) {
            changedFields.mobileBaixaAutomaticaServicosIndividuais = values.mobileBaixaAutomaticaServicosIndividuais;
        }

        if (values.habilitaChatbotInsucesso !== contratanteSelected.habilitaChatbotInsucesso) {
            changedFields.habilitaChatbotInsucesso = values.habilitaChatbotInsucesso;
        }

        if (values.habilitaChatbotTracking !== contratanteSelected.habilitaChatbotTracking) {
            changedFields.habilitaChatbotTracking = values.habilitaChatbotTracking;
        }

        if (values.slaTratativa !== contratanteSelected.slaTratativa) {
            changedFields.slaTratativa = values.slaTratativa;
        }

        if (values.pessoa !== contratanteSelected.tipo) {
            changedFields.tipo = values.pessoa;
        }

        if (values.codigo !== contratanteSelected.codigo) {
            changedFields.codigo = values.codigo;
        }

        if (values.fornecedorSAP !== contratanteSelected.fornecedorSAP) {
            changedFields.fornecedorSAP = values.fornecedorSAP;
        }

        if (values.wsLogin !== contratanteSelected.wsLogin) {
            changedFields.wsLogin = values.wsLogin;
        }

        if (values.wsPass !== contratanteSelected.wsPass) {
            changedFields.wsSenha = values.wsPass;
        }

        return changedFields;
    };

    const statusLista = [
        { name: 'Ativo', id: '1' },
        { name: 'Inativo', id: '0' }
    ];

    useEffect(() => {
        if (!!contratanteSelected?.id) {
            const estadoPesquisado = estadoListaContratante?.filter(estado => removeSpecialCharSimple(estado.uf) === removeSpecialCharSimple(contratanteSelected?.estado));
            if (!!estadoPesquisado) setEstadoLocal(estadoPesquisado.map(estado => ({ ...estado, uf: estado.uf })));
            const cidadePesquisada = cidadeListaContratante?.filter((cidade: any) => removeSpecialCharSimple(cidade.name) === removeSpecialCharSimple(contratanteSelected?.cidade ?? ""));
            if (!!cidadePesquisada) setCidadeLocal(cidadePesquisada);
        }
    }, [contratanteSelected, estadoListaContratante, cidadeListaContratante]);

    useEffect(() => {
        if (isIncluding) {
            setEmailError(false);
            
            setTipoEndereco([]);
            setPais('Brasil');
            setTipoContratante([]);
            setCEP('');
            setCidadeContratante([]);
            setEstadoContratante([]);
            setContratanteSelected({});

            // Reset do status para ativo por padrão
            setStatus(['1']);
        };
    }, [isIncluding]);

    return (
        <section>
            <Header setMenuLateral={() => setMenuLateral(true)} title={isIncluding ? 'Cadastro de Contratante' : 'Edição de Contratante'} />

            <Breadcrumb>
                <BreadcrumbItem onClick={() => navigate("/ConsultarContratantes")}>Contratante</BreadcrumbItem>
                <BreadcrumbItem>{isIncluding ? 'Cadastro de Contratante' : 'Edição de Contratante'}</BreadcrumbItem>
            </Breadcrumb>

            <Formik
                key={isIncluding ? 'incluir' : contratanteSelected.id}
                initialValues={isIncluding ? initialValues : initialValuesEditar}
                validationSchema={validationSchema}
                enableReinitialize={true}
                validateOnMount={false}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, actions) => {
                    actions.validateForm().then((errors) => {
                        if (Object.keys(errors).length === 0) {
                            if (!!isIncluding) {
                                if (!!profileHasPermission('Novo Registro')) requestCadastrarAPI(values);
                            } else {
                                if (!!profileHasPermission('Editar Registro')) requestSalvarAPI(values);
                            }
                        } else {
                            actions.setTouched(
                                Object.keys(initialValues).reduce((acc, key) => ({
                                    ...acc,
                                    [key]: true
                                }), {})
                            )
                        }
                    })
                }}
            >
                {({ values, resetForm, setFieldValue, handleChange, isValid, errors, touched, handleBlur }) => {
                    return (
                        <Form>
                            <div className='Container'>
                                <div className='Status'>
                                    <MultipleSelectCheckmarksId
                                        nodata={"Nenhum status encontrado"}
                                        options={statusLista}
                                        value={status}
                                        onChange={(e: string[]) => setStatus(e)}
                                        id="multiple-checkbox-status"
                                        idOption="multiple-checkbox-option-status-"
                                        labelId="multiple-checkbox-label-status"
                                        multiple={false}
                                        className="ConsultarEquipamentoSelectStatus"
                                        dimension='sm'
                                        label="Status"
                                    />
                                </div>
                                <div className='InputContratante'>
                                    <Input
                                        value={values.razaoSocial}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        name="razaoSocial"
                                        label='Razão Social'
                                        dimension='sm'
                                        error={touched.razaoSocial && Boolean(errors.razaoSocial)}
                                        helperText={touched.razaoSocial && errors.razaoSocial}
                                    />
                                    <Input
                                        value={values.nomeFantasia}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        label='Nome Fantasia'
                                        dimension='sm'
                                        name="nomeFantasia"
                                        error={touched.nomeFantasia && Boolean(errors.nomeFantasia)}
                                        helperText={touched.nomeFantasia && errors.nomeFantasia}
                                    />
                                </div>
                                <Tabs variant="fullWidth" className='TabsIncluirEditarContratante' value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                                    <Tab key="tab1" label="Geral" value={0} />
                                    <Tab key="tab2" label="Configurações" value={1} />

                                    {window.innerWidth > 1000 &&
                                        [
                                            <Tab key="tab1" className='FillingButton' disabled />,
                                            <Tab key="tab2" className='FillingButton' disabled />
                                        ]
                                    }
                                </Tabs>
                                <div style={{ display: tabValue === 0 ? 'block' : 'none' }}>
                                    <GeralTab
                                        isIncluding={isIncluding}
                                        formik={{ values, resetForm, setFieldValue, handleChange, isValid, errors, touched, handleBlur }}
                                    />
                                </div>
                                <div style={{ display: tabValue === 1 ? 'block' : 'none' }}>
                                    <ConfiguracaoTab 
                                        isIncluding={isIncluding}
                                        formik={{ values, resetForm, setFieldValue, handleChange, isValid, errors, touched, handleBlur }}
                                    />
                                </div>
                                <div className='ButtonContainer'>
                                    <Button
                                        id={"botao-salvar"}
                                        variant="contained"
                                        type="submit"
                                    >
                                        Salvar
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>

            {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}

            <AlertMessage
                isOpenAlert={openAlert}
                setOpenAlert={setOpenAlert}
                alertType={tipomsgAlert}
                msgAlert={msgAlert}
            />
        </section>
    );

}

export default IncluirEditarContratante;
