import { apiGet, apiPost, apiPut } from "../../../services/api";

const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO;


export const postProcedimentoServico = async (params: any) => 
    await apiPost(`${baseUrl}/ProcedimentoServico`, params)

export const putProcedimentoServico = async (params: any) => 
    await apiPut(`${baseUrl}/ProcedimentoServico`, params)

export const obterProcedimentoServico = async (idContratante: number) => 
    await apiGet(`${baseUrl}/ProcedimentoServico?idContratante=${idContratante}`)