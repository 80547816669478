import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
  backgroundColor: "#f5f5f5",
  width: "100%",
  minHeight: "calc(20vh - 60px)",
  display: "flex",
  flexDirection: "column",
}));

interface ConfiguracaoGeralProps {
  dias: number;
  onDiasChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: () => void;
}

const ConfiguracaoGeral: React.FC<ConfiguracaoGeralProps> = ({ dias, onDiasChange, onSave }) => {

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isNumber = /^[0-9]$/.test(e.key);
    const isBackspace = e.key === 'Backspace';
    const isTab = e.key === 'Tab';
    const isArrow = ['ArrowLeft', 'ArrowRight'].includes(e.key);
    
    if (!isNumber && !isBackspace && !isTab && !isArrow) {
      e.preventDefault();
    }
  };

  return (
    <StyledPaper>
      <Typography variant="h5" component="h1" gutterBottom>
        Configuração Geral
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        Escolha a configuração geral, em dias, que o checklist completo
        deve ser realizado pelos técnicos no MobyApp
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        • Digite números inteiros positivos.
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        • Ao configurar zero dias, o Checklist Completo é inativado.
      </Typography>

      <Box display="flex" gap={2} mb={4} alignItems="center">
        <TextField
          type="number"
          id="diasConfiguracaoGeral"
          value={dias}
          onChange={onDiasChange}
          onKeyDown={handleKeyPress}
          inputProps={{ min: 0 }}
        />
        <Button variant="contained" color="primary" onClick={onSave}>
          Salvar
        </Button>
      </Box>
    </StyledPaper>
  );
};

export default ConfiguracaoGeral;
