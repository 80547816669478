import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface NavigationGuardProps {
  message?: string;
  when?: boolean;
  children?: React.ReactNode;
  onSave: () => void;
}

interface PendingNavigation {
  path: string;
  type: "push" | "replace" | "pop" | "link";
}

const NavigationGuard: React.FC<NavigationGuardProps> = ({
  message = "Deseja realmente sair desta página?",
  when = true,
  children,
  onSave,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] =
    useState<PendingNavigation | null>(null);

  const handleModalConfirm = () => {
    setShowModal(false);

    if (pendingNavigation) {
      switch (pendingNavigation.type) {
        case "push":
          window.history.pushState(null, "", pendingNavigation.path);
          break;
        case "replace":
          window.history.replaceState(null, "", pendingNavigation.path);
          break;
        case "link":
          window.location.href = pendingNavigation.path;
          break;
        case "pop":
          window.history.pushState(null, "", pendingNavigation.path);
          break;
      }
    }
    setPendingNavigation(null);
  };

  const handleModalCancel = () => {
    onSave();
    setShowModal(false);

    if (pendingNavigation?.type === "pop") {
      window.history.pushState(null, "", location.pathname);
    }
    setPendingNavigation(null);
  };

  const handleNavigation = useCallback(
    (nextPath: string, type: PendingNavigation["type"]) => {
      if (!when) return true;

      setPendingNavigation({ path: nextPath, type });
      setShowModal(true);
      return false;
    },
    [when]
  );

  useEffect(() => {
    if (!when) return;

    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function () {
      if (handleNavigation(arguments[2] as string, "push")) {
        // @ts-ignore
        return originalPushState.apply(this, arguments);
      }
      return;
    };

    window.history.replaceState = function () {
      if (handleNavigation(arguments[2] as string, "replace")) {
        // @ts-ignore
        return originalReplaceState.apply(this, arguments);
      }
      return;
    };

    return () => {
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, [handleNavigation, when]);

  useEffect(() => {
    if (!when) return;

    const handlePopState = (event: PopStateEvent) => {
      handleNavigation(window.location.pathname, "pop");
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [handleNavigation, location, when]);

  useEffect(() => {
    if (!when) return;

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = message;
      return e.returnValue;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [message, when]);

  useEffect(() => {
    if (!when) return;

    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const link = target.closest("a");

      if (link && !link.getAttribute("href")?.startsWith("#")) {
        e.preventDefault();
        handleNavigation(link.getAttribute("href") || "", "link");
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleNavigation, when]);

  return (
    <>
      {children}

      <BootstrapDialog
        open={showModal}
        onClose={handleModalCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={()=>{setShowModal(false);}}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography gutterBottom>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalConfirm} color="primary" autoFocus>
            Sair
          </Button>
          <Button
            onClick={handleModalCancel}
            variant="contained"
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default NavigationGuard;
