import * as React from 'react';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ArticleIcon from '@mui/icons-material/Article';
import Box from '@mui/material/Box';
import { useContext, useState } from 'react';
import Button from '../Button/styles';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import TreeViewButton from '../TreeViewButton/styles';
interface TreeViewProps {
    dados: ParametroData[]; // Adicionando a propriedade dados
    onSelectNode?: (nodeId: string, nodeType: 'contratante' | 'prestador' | 'grupoServico' | 'servico' | 'parametro') => void;
    expanded?: string[];
    onNodeToggle?: (nodeIds: string[]) => void;
}

interface ParametroData {
    id: number;
    idPrestador: number | null;
    prestador: string | null;
    idGrupoServico: number | null;
    grupoServico: string | null;
    idServico: number | null;
    servico: string | null;
    idContratante: number;
    contratante: string;
    parametro: string;
    idParametro: number;
    tipo: string;
    valor: string;
    observacao: string;
    mensagemErro: string;
    ativo: boolean;
}

interface TreeNode {
    id: string;
    name: React.ReactNode;
    children?: TreeNode[];
}

const renderTreeItems = (
    nodes: TreeNode[],
    onSelectNode: (id: string, nodeType: 'contratante' | 'prestador' | 'grupoServico' | 'servico' | 'parametro') => void,
    handleNavigateToCadastro: () => void,
    selectedNodeId: string | null,
    setSelectedNodeId: React.Dispatch<React.SetStateAction<string | null>>
) => {
    return nodes.map((node) => {
        const isButtonNode = node.id.startsWith('botao_novo_parametro');
        const isParametroNode = node.id.startsWith('p_');

        return (
            <TreeItem
                key={node.id}
                itemId={node.id}
                label={
                    isButtonNode ? (
                        <TreeViewButton
                            variant="contained"
                            dimension="sm"
                            color="primary"
                            onClick={handleNavigateToCadastro}
                        >
                            <span>{node.name}</span>
                        </TreeViewButton>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isParametroNode && (
                                <ArticleIcon
                                    fontSize="small"
                                    style={{
                                        marginRight: '8px',
                                        color: selectedNodeId === node.id ? '#4FD1A3' : 'inherit',
                                        transform: 'scale(0.8)',
                                    }}
                                />
                            )}
                            <span
                                style={{
                                    fontWeight: selectedNodeId === node.id ? 'bold' : 'normal',
                                    color: selectedNodeId === node.id ? '#4FD1A3' : 'inherit',
                                }}
                            >
                                {node.name}
                            </span>
                        </div>
                    )
                }
                onClick={() => {
                    if (!isButtonNode) {
                        setSelectedNodeId(node.id);
                        const [type, id] = node.id.split('_');
                        let nodeType: 'contratante' | 'prestador' | 'grupoServico' | 'servico' | 'parametro';
                        switch (type) {
                            case 'c':
                                nodeType = 'contratante';
                                break;
                            case 'pr':
                                nodeType = 'prestador';
                                break;
                            case 'gs':
                                nodeType = 'grupoServico';
                                break;
                            case 's':
                                nodeType = 'servico';
                                break;
                            case 'p':
                                nodeType = 'parametro';
                                break;
                            default:
                                return;
                        }
                        onSelectNode?.(id, nodeType);
                    }
                }}
                sx={{
                    border: node.id.startsWith('c_') ? '1px solid #e0e0e0' : undefined,
                    borderRadius: node.id.startsWith('c_') ? '8px' : undefined,
                    margin: node.id.startsWith('c_') ? '8px 0' : undefined,
                    padding: node.id.startsWith('c_') ? '8px' : undefined,
                    '& .MuiTreeItem-content': {
                        borderRadius: '4px',
                        '&:hover': {
                            backgroundColor: '#f5f5f5',
                        },
                    },
                }}
            >
                {node.children && renderTreeItems(node.children, onSelectNode, handleNavigateToCadastro, selectedNodeId, setSelectedNodeId)}
            </TreeItem>
        )
    });
};

const organizarDadosParaTreeView = (
    dados: ParametroData[],
    profileHasPermissionIncluir: (funcionalidade: string) => boolean,
    handleNavigateToCadastro: () => void
): TreeNode[] => {

    const botaoCadastro = {
        id: `botao_novo_parametro`, // Identificador único
        name: (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {!!profileHasPermissionIncluir("Novo Registro") ? (
                    <Button
                        variant="contained"
                        dimension="sm"
                        color="primary"
                        onClick={handleNavigateToCadastro}
                        style={{
                            width: '100%', // O botão ocupa toda a largura do TreeItem
                            textAlign: 'left', // Alinha o texto à esquerda, como os outros itens
                            padding: '8px 16px', // Ajusta o padding para ficar consistente
                        }}
                    >
                        Novo Parâmetro
                    </Button>
                ) : (
                    <Button
                        type="button"
                        variant="primary"
                        dimension="sm"
                        style={{
                            cursor: 'not-allowed',
                            opacity: 0.5,
                            width: '100%', // O botão ocupa toda a largura do TreeItem
                            textAlign: 'left',
                            padding: '8px 16px',
                        }}
                        disabled={true}
                    >
                        Novo Parâmetro
                    </Button>
                )}
            </div>
        ),
        children: [],
    };

    const porContratante = dados.reduce<{ [key: string]: TreeNode }>((acc, item) => {
        const contratanteId = `c_${item.idContratante}`;

        if (!acc[contratanteId]) {
            acc[contratanteId] = {
                id: contratanteId,
                name: item.contratante || 'Sem Contratante',
                children: []
            };
        }

        const contratanteNode = acc[contratanteId];

        if (!contratanteNode.children) {
            contratanteNode.children = [];
        }

        // Se existir grupo de serviço, organize por grupo
        if (item.idGrupoServico && item.grupoServico) {
            const grupoNodeId = `gs_${item.idContratante}_${item.idGrupoServico}`;
            let grupoNode = contratanteNode.children?.find(node => node.id === grupoNodeId);
            if (!grupoNode) {
                grupoNode = {
                    id: grupoNodeId,
                    name: item.grupoServico || 'Sem Grupo de Serviço',
                    children: []
                };
                contratanteNode.children!.push(grupoNode);
            }

            if (!grupoNode.children) {
                grupoNode.children = [];
            }

            // Serviço dentro do grupo de serviço
            if (item.idServico && item.servico) {
                const servicoNodeId = `s_${item.idContratante}_${item.idGrupoServico}_${item.idServico}`;
                let servicoNode = grupoNode.children?.find(node => node.id === servicoNodeId);
                if (!servicoNode) {
                    servicoNode = {
                        id: servicoNodeId,
                        name: item.servico || 'Sem Serviço',
                        children: []
                    };
                    grupoNode.children?.push(servicoNode);
                }

                if (!servicoNode.children) {
                    servicoNode.children = [];
                }

                servicoNode.children?.push({
                    id: `p_${item.id}`,
                    name: `${item.parametro} (${item.valor})`
                });
            } else {
                // Caso não tenha serviço, parâmetro direto no grupo
                grupoNode.children?.push({
                    id: `p_${item.id}`,
                    name: `${item.parametro} (${item.valor})`
                });
            }
        } else if (item.idPrestador && item.prestador) {
            // Se não houver grupo de serviço mas houver prestador
            const prestadorNodeId = `pr_${item.idContratante}_${item.idPrestador}`;
            let prestadorNode = contratanteNode.children?.find(node => node.id === prestadorNodeId);
            if (!prestadorNode) {
                prestadorNode = {
                    id: prestadorNodeId,
                    name: item.prestador || 'Sem Prestador',
                    children: []
                };
                contratanteNode.children?.push(prestadorNode);
            }

            if (!prestadorNode.children) {
                prestadorNode.children = [];
            }

            prestadorNode.children?.push({
                id: `p_${item.id}`,
                name: `${item.parametro} (${item.valor})`
            });
        } else {
            // Caso apenas o parâmetro sem grupo de serviço ou prestador
            contratanteNode.children?.push({
                id: `p_${item.id}`,
                name: `${item.parametro} (${item.valor})`
            });
        }

        return acc;
    }, {});

    return [botaoCadastro, ...Object.values(porContratante)];
}

const BasicRichTreeView: React.FC<TreeViewProps> = ({ dados, onSelectNode, expanded = [], onNodeToggle }) => {
    const navigate = useNavigate();
    const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);

    const profileHasPermissionIncluir = (funcionalidade: string) =>
        funcionalidadeDaTelaTemPermissao("Incluir Parâmetro", funcionalidade);

    const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
    const handleNavigateToCadastro = () => navigate("/IncluirParametro");
    const dadosOrganizados = organizarDadosParaTreeView(dados, profileHasPermissionIncluir, handleNavigateToCadastro);

    // Verificação de segurança
    if (!dadosOrganizados || dadosOrganizados.length === 0) {
        return <div>Sem dados para exibir</div>;
    }

    return (
        <Box sx={{
            minHeight: 352,
            minWidth: 250,
            maxHeight: '80vh',
            overflow: 'auto',
            backgroundColor: '#fff',
            padding: '16px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
            <TreeView
                expandedItems={expanded}
                defaultExpandedItems={[]}
                onExpandedItemsChange={(event, nodeIds) => {
                    onNodeToggle?.(nodeIds);
                }}
            >
                {renderTreeItems(dadosOrganizados, onSelectNode!, handleNavigateToCadastro, selectedNodeId, setSelectedNodeId)}
            </TreeView>
        </Box>
    );
};

export default BasicRichTreeView;