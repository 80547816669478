import Switch, { SwitchProps } from '@mui/material/Switch';
import styled from "styled-components";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  row-gap: 2rem;

  & .MuiTextField-root {
    background-color: #FFF
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 1rem;
`;

export const GoBack = styled.button`
  background: transparent;
  height: 27px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #797D8C;
  border-radius: 50%;
  color: #797D8C;
`;

export const AllowanceSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  '&.MuiSwitch-root': {
    height: 'auto',
    minWidth: '4.5rem',
  },
  '& .MuiSwitch-switchBase': {
    padding: "13px 12px 12px 13px",
    transitionDuration: '150ms',
    '&.Mui-checked': {
      transform: 'translateX(calc(100% - 1.475rem))',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: '1.375rem',
    height: '1.375rem',
  },
  '& .MuiSwitch-track': {
    height: '1.5rem',
    width: '100%',
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#EF0000' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
