import React, { useEffect } from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import SectionCustom from "../../../components/section/section";
import ConfiguracaoGeral from "./configuracaoGeral";
import TecnicosTable from "./tecnicosTable";
import TecnicoModal from "./tecnicoModal";
import DeleteConfirmationDialog from "./deleteConfirmationDialog";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import { ConsultarChecklistCompletoContext } from "../../../contexts/consultarChecklistCompletoContext";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import NavigationGuard from "./navigationGuard";


const ConsultarChecklistCompleto: React.FC = () => {
  const {
    loading,
    dias,
    itemsListagem,
    tecnicos,
    openModal,
    isEditing,
    openDeleteDialog,
    tecnicoToDelete,
    formData,
    errors,
    currentPage,
    pageSize,
    totalCount,
    openAlertError,
    msgAlertError,
    alertType,
    setAlertType,
    setMsgAlert,
    setOpenAlert,
    handleDiasChange,
    handleSave,
    handleOpenModal,
    handleCloseModal,
    handleEdit,
    handleDeleteClick,
    handleCloseDeleteDialog,
    handleConfirmDelete,
    handleSubmit,
    validateForm,
    handleFormChange,
    handlePageChange,
    handlePageSizeChange,
    getTecnicos,
    getConfiguracaoGeral,
    getTecnicoConfiguracoes,
    mudouDiasGeral,
  } = ConsultarChecklistCompletoContext();

  useEffect(() => {
    getTecnicos();
    getConfiguracaoGeral();
  }, []);

  useEffect(() => {
    getTecnicoConfiguracoes();
  }, [currentPage, pageSize]);

  return (
    <>
    <NavigationGuard 
      when={mudouDiasGeral} 
      onSave={handleSave}
      message="Suas alterações não foram salvas. Clique no botão salvar para confirmá-las ou clique em Sair para sair da tela"
    >
      {loading && <FullscreenLoading />}
      <SectionCustom
        id="ConsultarCheckListCompleto"
        className="ConsultarCheckListCompleto"
        titleHeader="CheckList Completo"
        titleBreadcrumbItem="CheckList Completo"
      >
        <Container
          maxWidth={false}
          sx={{ padding: 0, height: "100%", width: "100%" }}
        >
          <ConfiguracaoGeral
            dias={dias}
            onDiasChange={handleDiasChange}
            onSave={handleSave}
          />

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={3}
            px={3}
          >
            <Typography variant="h5" component="h2">
              Configuração por Técnico
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModal}
            >
              Incluir técnico
            </Button>
          </Box>

          <AlertMessage
            isOpenAlert={openAlertError}
            setOpenAlert={setOpenAlert}
            alertType={alertType ?? "error"}
            msgAlert={msgAlertError}
          />
          <TecnicosTable
            itemsListagem={itemsListagem}
            onEdit={handleEdit}
            onDelete={handleDeleteClick}
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </Container>
      </SectionCustom>

      <TecnicoModal
        open={openModal}
        onClose={handleCloseModal}
        isEditing={isEditing}
        tecnicos={tecnicos}
        formData={formData}
        errors={errors}
        onSubmit={handleSubmit}
        validateForm={validateForm}
        onChange={handleFormChange}
      />

      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
      />
      </NavigationGuard>
    </>
  );
};

export default ConsultarChecklistCompleto;
