import { AxiosResponse } from "axios";
import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IEstado, IPutOSMassivo } from "../types";

const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

export const putOSMassivo = async (params: IPutOSMassivo) =>
  await apiPut(`${baseUrlOrdemServico}/OrdemServico_/sincronizar-ordem-servico-oppay`, params);

export const obterEstados = async () =>  
  await apiGet(`${baseUrlOrdemServico}/estados?Ativo=true`);

export const obterCidades = async () =>  
  await apiGet(`${baseUrlOrdemServico}/cidades/lista-cidade-menu`);

