import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IGetMensagensInsucesso, IGetMotivoContratante, IPutMotivoContratante } from "../types/types";


const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO

export const getMensagemInsucessoPorId = (id: number | string) =>
    apiGet(`${baseUrl}/MensagemInsucesso/${id}`)

export const putMensagemInsucesso = (idMensagemInsucesso: number | string, params: any) =>
    apiPut(`${baseUrl}/MensagemInsucesso/${idMensagemInsucesso}`, params)

export const postMensagemInsucesso = (params: any) =>
    apiPost(`${baseUrl}/MensagemInsucesso`, params)

export const getListaMensagensInsucessoComFiltro = async (params: IGetMensagensInsucesso) =>
    await apiGet(`${baseUrl}/mensagensInsucesso`, params)

export const getListaMotivoContratante = async (params: IGetMotivoContratante) =>
    await apiGet(`${baseUrl}/MotivosCancelamento/MotivosInsucessoVinculadosAoContratante`, params);

export const putMotivoContratante = (idVinculoMotivo: number, params: IPutMotivoContratante) =>
    apiPut(`${baseUrl}/MotivosCancelamento/AtualizaVinculoContratanteMotivoCancelamento/${idVinculoMotivo}`, params);
