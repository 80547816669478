import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Section,
  SearchButton,
  Body,
  FiltrosLabel,
  InputsRowDatePickers,
  InputLabel,
  ORLabel,
  ResultLabel,
  ExportContainer,
  PresetsContainer,
  ContainerButtons,
  NewUserButton,
} from "./consultarOS_styles";
import "./consultarOS.scss";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import Input from "../../../components/Input/Input";
import { AxiosResponse } from "axios";
import StickyTable from "../../../components/stickyTable/stickyTable";
import { Alert, CircularProgress, Collapse, Button, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import Pagination from '../../../components/pagination/paginacao';
import { consultarOSs } from "../services";
import moment from "moment";
import { getPrestadores } from "../../enviarTecnico/services/services";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import _ from "lodash";
import { IPostConsultarOSs, IPostConsultarOSsResponse } from "../types";
import { AuthContext } from "../../../contexts/auth";
import DatePicker from "../../../components/datePicker/datePicker";
import { GrupoServico, Grupo_Servico, IServico } from "../../../contexts/types/farolTypes";
import { getContratantesNovo, getGrouped_Services } from "../../farol/services";
import { maskCPFCNPJ } from "../../../helpers/maskCPFCNPJ";
import AccordionCategoriaConsultarOS from "./accordionCategoriaConsultarOS";
import AddIcon from '@mui/icons-material/Add';
import { getListaTecnicosByPrestador } from "../../encaminharOS/services/services";
import { ReactComponent as ExportIcon } from "../../../assets/icons/ExportIcon.svg";
import StarIcon from '@mui/icons-material/Star';
import ModalSalvarPreset from "./modalSalvarPreset";
import { useFormik } from "formik";
import * as yup from "yup";
import { IInitialValues } from "../types";
import { isJson } from "../../../helpers/isJSON";
import { getEquipamentoTipo } from '../../tipoEquipamento/services/services';
import { IConsultarTipoEquipamentoTabela } from '../../tipoEquipamento/types';
import { getEquipamentoModelo } from "../../modelo/services/services";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import { getEstados, getCidades } from "../../prestador/services/services";
import { IGetResponseCidades, IGetResponseEstados } from "../../prestador/types";
import { IContratantesDTO } from "../../contratante/types";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import ButtonOs from "../../../components/Button/styles";

const ConsultaOS = () => {
  const { setMenuLateral, listaStatus } = useContext(HomeContext);

  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consultar Ordem de Serviço", funcionalidade);

  const navigate = useNavigate();

  const isInitialMount = useRef(true);
  const [isModalSavePresetOpen, setIsModalSavePresetOpen] = useState(false);

  const [listaPresets, setListaPresets] = useState<{ name: string, id: string, preset: any | null }[]>([
    { name: 'Novo preset', id: '', preset: null },
    {
      name: 'Pesquisa Simples', id: '1',
      preset: '{"filtroNChamado":"","filtroNReferencia":"","filtroStatus":[],"filtroContratante":["29"],"filtroGrupoServico":["15"],"filtroServico":[{"name":"INSTALACAO","id":3}],"filtroTipoAtendimento":[],"filtroNVisitas":"","filtroCategoriaProdutosEServicos":[""],"filtroTipodeEquipamento":[],"filtroModelo":[],"filtroNSerie":"","filtroIDEquipamento":"","filtroEquipamentoCliente":["nodata"],"filtroModeloCliente":["nodata"],"filtroNSerieCliente":"","filtroEquipamentoRetirada":["nodata"],"filtroModeloRetirada":["nodata"],"filtroNSerieRetirada":"","filtroMetodologia":["nodata"],"filtroColetaDeDados":["nodata"],"filtroPublicoAlvoPesquisas":"","filtroTipoDeEntrega":["nodata"],"filtroProduto":["nodata"],"filtroPublicoAlvoAlimentos":"","filtroCategoriaPeriodo":[""],"filtroDataAberturaInicio":null,"filtroDataAberturaFim":null,"filtroRangeDataAbertura":[""],"filtroRangeDataModificacao":[""],"filtroRangeDataAtendimento":[""],"filtroRangeDataLimite":[""],"filtroRangeDataPreBaixa":[""],"filtroRangeDataAgendamento":[""],"filtroDataModificacaoInicio":null,"filtroDataModificacaoFim":null,"filtroDataAtendimentoInicio":null,"filtroDataAtendimentoFim":null,"filtroDataLimiteInicio":null,"filtroDataLimiteFim":null,"filtroDataPreBaixaInicio":null,"filtroDataPreBaixaFim":null,"filtroDataAgendamentoInicio":null,"filtroDataAgendamentoFim":null,"filtroNomeFantasia":"","filtroCpfcnpj":"","filtroCodigoCliente":"","filtroEstado":[],"filtroCidade":[],"filtroBairro":["nodata"],"filtroCEPInicial":"","filtroCEPFinal":"","filtroCanalCredenciadorEC":"","filtroCentrodeTrabalho":"","filtroPrestadores":[],"filtroTecnicos":[]}'
    },
    {
      name: 'Pesquisa Avançada', id: '2',
      preset: '{"filtroNChamado":"10162119","filtroNReferencia":"59805861","filtroStatus":[],"filtroContratante":["29"],"filtroGrupoServico":["15"],"filtroServico":[{"name":"INSTALACAO","id":3}],"filtroTipoAtendimento":[],"filtroNVisitas":"","filtroCategoriaProdutosEServicos":[""],"filtroTipodeEquipamento":[],"filtroModelo":[],"filtroNSerie":"","filtroIDEquipamento":"","filtroEquipamentoCliente":["nodata"],"filtroModeloCliente":["nodata"],"filtroNSerieCliente":"","filtroEquipamentoRetirada":["nodata"],"filtroModeloRetirada":["nodata"],"filtroNSerieRetirada":"","filtroMetodologia":["nodata"],"filtroColetaDeDados":["nodata"],"filtroPublicoAlvoPesquisas":"","filtroTipoDeEntrega":["nodata"],"filtroProduto":["nodata"],"filtroPublicoAlvoAlimentos":"","filtroCategoriaPeriodo":[""],"filtroDataAberturaInicio":null,"filtroDataAberturaFim":null,"filtroRangeDataAbertura":[""],"filtroRangeDataModificacao":[""],"filtroRangeDataAtendimento":[""],"filtroRangeDataLimite":[""],"filtroRangeDataPreBaixa":[""],"filtroRangeDataAgendamento":[""],"filtroDataModificacaoInicio":null,"filtroDataModificacaoFim":null,"filtroDataAtendimentoInicio":null,"filtroDataAtendimentoFim":null,"filtroDataLimiteInicio":null,"filtroDataLimiteFim":null,"filtroDataPreBaixaInicio":null,"filtroDataPreBaixaFim":null,"filtroDataAgendamentoInicio":null,"filtroDataAgendamentoFim":null,"filtroNomeFantasia":"","filtroCpfcnpj":"","filtroCodigoCliente":"","filtroEstado":[],"filtroCidade":[],"filtroBairro":["nodata"],"filtroCEPInicial":"","filtroCEPFinal":"","filtroCanalCredenciadorEC":"","filtroCentrodeTrabalho":"","filtroPrestadores":[],"filtroTecnicos":[]}',
    },
  ]);
  const [presetSelecionado, setPresetSelecionado] = useState<string[]>([]);

  const initialValues: IInitialValues = {
    /**Dados da OS */
    filtroNChamado: '',
    filtroNReferencia: '',
    filtroStatus: [],
    filtroContratante: [],
    filtroGrupoServico: [],
    filtroServico: [],
    filtroTipoAtendimento: [],
    filtroNVisitas: '',

    /**Produtos E Serviços */
    filtroCategoriaProdutosEServicos: [''],
    filtroTipodeEquipamento: [],
    filtroModelo: [],
    filtroNSerie: '',
    filtroIDEquipamento: '',
    filtroEquipamentoCliente: ['nodata'],
    filtroModeloCliente: ['nodata'],
    filtroNSerieCliente: '',
    filtroEquipamentoRetirada: ['nodata'],
    filtroModeloRetirada: ['nodata'],
    filtroNSerieRetirada: '',
    filtroMetodologia: ['nodata'],
    filtroColetaDeDados: ['nodata'],
    filtroPublicoAlvoPesquisas: '',
    filtroTipoDeEntrega: ['nodata'],
    filtroProduto: ['nodata'],
    filtroPublicoAlvoAlimentos: '',

    /**Perído */
    filtroCategoriaPeriodo: [''],
    filtroDataAberturaInicio: moment().subtract(7, 'days'),
    filtroDataAberturaFim: moment(),

    filtroRangeDataAbertura: [''],
    filtroRangeDataModificacao: [''],
    filtroRangeDataAtendimento: [''],
    filtroRangeDataLimite: [''],
    filtroRangeDataPreBaixa: [''],
    filtroRangeDataAgendamento: [''],

    filtroDataModificacaoInicio: null,
    filtroDataModificacaoFim: null,
    filtroDataAtendimentoInicio: null,
    filtroDataAtendimentoFim: null,
    filtroDataLimiteInicio: null,
    filtroDataLimiteFim: null,
    filtroDataPreBaixaInicio: null,
    filtroDataPreBaixaFim: null,
    filtroDataAgendamentoInicio: null,
    filtroDataAgendamentoFim: null,

    /**Dados do Cliente */
    filtroNomeFantasia: '',
    filtroCpfcnpj: '',
    filtroCodigoCliente: '',
    filtroEstado: [],
    filtroCidade: [],
    filtroBairro: ['nodata'],
    filtroCEPInicial: '',
    filtroCEPFinal: '',

    /**Atendimento */
    filtroCanalCredenciadorEC: "",
    filtroCentrodeTrabalho: "",
    filtroPrestadores: [],
    filtroTecnicos: [],
  };


  const validationSchema = yup.object({
    filtroDataAberturaInicio: yup.string().nullable().test('filtroData', 'Preencha a data de Início e Fim.',
      (val, ctx) => {
        if (!val && !!ctx.parent?.filtroDataAberturaFim) return false;
        return true;
      }),
    filtroDataAberturaFim: yup.string().nullable().test('filtroData', 'Preencha a data de Início e Fim.',
      (val, ctx) => {
        if (!val && !!ctx.parent?.filtroDataAberturaInicio) return false;
        return true;
      }),
    filtroDataModificacaoInicio: yup.string().nullable().test('filtroData', 'Preencha a data de Início e Fim.',
      (val, ctx) => {
        if (!val && !!ctx.parent?.filtroDataModificacaoFim) return false;
        return true;
      }),
    filtroDataModificacaoFim: yup.string().nullable().test('filtroData', 'Preencha a data de Início e Fim.',
      (val, ctx) => {
        if (!val && !!ctx.parent?.filtroDataModificacaoInicio) return false;
        return true;
      }),
    filtroDataAtendimentoInicio: yup.string().nullable().test('filtroData', 'Preencha a data de Início e Fim.',
      (val, ctx) => {
        if (!val && !!ctx.parent?.filtroDataAtendimentoFim) return false;
        return true;
      }),
    filtroDataAtendimentoFim: yup.string().nullable().test('filtroData', 'Preencha a data de Início e Fim.',
      (val, ctx) => {
        if (!val && !!ctx.parent?.filtroDataAtendimentoInicio) return false;
        return true;
      }),
    filtroDataLimiteInicio: yup.string().nullable().test('filtroData', 'Preencha a data de Início e Fim.',
      (val, ctx) => {
        if (!val && !!ctx.parent?.filtroDataLimiteFim) return false;
        return true;
      }),
    filtroDataLimiteFim: yup.string().nullable().test('filtroData', 'Preencha a data de Início e Fim.',
      (val, ctx) => {
        if (!val && !!ctx.parent?.filtroDataLimiteInicio) return false;
        return true;
      }),
    filtroDataPreBaixaInicio: yup.string().nullable().test('filtroData', 'Preencha a data de Início e Fim.',
      (val, ctx) => {
        if (!val && !!ctx.parent?.filtroDataPreBaixaFim) return false;
        return true;
      }),
    filtroDataPreBaixaFim: yup.string().nullable().test('filtroData', 'Preencha a data de Início e Fim.',
      (val, ctx) => {
        if (!val && !!ctx.parent?.filtroDataPreBaixaInicio) return false;
        return true;
      }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    // enableReinitialize: true,
    onSubmit: (values, actions) => handleButtonSearch()
  });

  /**Dados da OS */
  const [listaContratante, setListaContratante] = useState<{ name: string, id: string }[]>([]);
  const [listaGrupoServico, setListaGrupoServico] = useState<GrupoServico[]>();
  const [listaTipoAtendimento, setListaTipoAtendimento] = useState<{ name: string, id: string }[]>([
    { name: 'Emergencial', id: '1' },
    { name: 'Normal', id: '2' },
  ]);

  /**Produtos E Serviços */
  const [listaCategoriaProdutosEServicos, setListaCategoriaProdutosEServicos] = useState<{ name: string, id: string }[]>([
    { name: 'Tecnologia de Pagamento', id: '1' },
    { name: 'Pesquisas', id: '2' },
    { name: 'Alimentos', id: '3' },
  ]);
  const [categoriaProdutosEServicos, setCategoriaProdutosEServicos] = useState<string[]>([]);
  const [listaTipodeEquipamento, setListaTipodeEquipamento] = useState<{ name: string, id: string }[]>([]);
  const [listaModelo, setListaModelo] = useState<{ name: string, id: string }[]>([]);

  const [listaEquipamentoCliente, setListaEquipamentoCliente] = useState<{ name: string, id: string }[]>([]);
  const [listaModeloCliente, setListaModeloCliente] = useState<{ name: string, id: string }[]>([]);
  const [listaEquipamentoRetirada, setListaEquipamentoRetirada] = useState<{ name: string, id: string }[]>([]);
  const [listaModeloRetirada, setListaModeloRetirada] = useState<{ name: string, id: string }[]>([]);
  const [listaMetodologia, setListaMetodologia] = useState<{ name: string, id: string }[]>([]);
  const [listaColetaDeDados, setListaColetaDeDados] = useState<{ name: string, id: string }[]>([]);

  const [listaTipoDeEntrega, setListaTipoDeEntrega] = useState<{ name: string, id: string }[]>([]);
  const [listaProduto, setListaProduto] = useState<{ name: string, id: string }[]>([]);

  /**Perído */
  const [listaCategoriaPeriodo, setListaCategoriaPeriodo] = useState<{ name: string, id: string }[]>([
    { name: 'Modificação', id: '1' },
    { name: 'Atendimento', id: '2' },
    { name: 'Limite (SLA)', id: '3' },
    { name: 'Pré-Baixa', id: '4' },
    // { name: 'Agendamento', id: '5' },
  ]);
  const [categoriaPeriodo, setCategoriaPeriodo] = useState<string[]>([]);
  const [listaRangeDatas, setListaRangeDatas] = useState<{ name: string, id: string }[]>([
    { name: 'Semana atual', id: '1' },
    { name: 'Mês atual', id: '2' },
    { name: '15 dias anteriores', id: '3' },
    { name: '15 dias posteriores', id: '4' },
    { name: 'Neste ano', id: '5' },
  ]);

  /**Dados do Cliente */
  const [listaEstado, setListaEstado] = useState<{ name: string, id: string }[]>([]);
  const [listaCidade, setListaCidade] = useState<{ name: string, id: string }[]>([]);

  /**Atendimento */
  const [listaPrestadores, setListaPrestadores] = useState<any[]>([]);
  const [listaTecnicos, setListaTecnicos] = useState<any[]>([]);


  const [dadosConsultaOS, setDadosConsultaOS] = useState<any[]>([]);
  const [disableActions, setDisableActions] = useState(false);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [msgAlertError, setMsgAlertError] = useState("Nenhum dado encontrado");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [loading, setLoading] = useState<boolean>(false);
  const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);

  const handleCloseModalSavePreset = () => {
    setIsModalSavePresetOpen(false);
  };

  const handleCloseCategoriaProdutosEServicos = (idCategoria: string) => {
    formik.setFieldValue('filtroCategoriaProdutosEServicos', formik.values.filtroCategoriaProdutosEServicos.filter(id => id !== idCategoria));
  };

  const handleCloseCategoriaPeriodo = (idCategoria: string) => {
    formik.setFieldValue('filtroCategoriaPeriodo', formik.values.filtroCategoriaPeriodo.filter(id => id !== idCategoria));
  };

  const handleButtonSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    setDadosConsultaOS([]);
    setQtdeRegistros(0);
    setOpenAlertError(false);
    setLoading(true);
    setDisableActions(true);

    const filtros: IPostConsultarOSs = {
      /**Dados da OS */
      ...(formik.values.filtroNChamado !== '' ? { 'chamado': formik.values.filtroNChamado } : {}),
      ...(formik.values.filtroNReferencia !== '' ? { 'referencia': formik.values.filtroNReferencia } : {}),
      ...(!!formik.values.filtroStatus.length ? { 'status': formik.values.filtroStatus.map((i) => Number(i.id)) } : {}),
      ...(!!formik.values.filtroContratante.length ? { 'contratante': formik.values.filtroContratante.map(Number) } : {}),
      ...(!!formik.values.filtroGrupoServico.length ? { 'grupoServico': formik.values.filtroGrupoServico.map(Number) } : {}),
      ...(!!formik.values.filtroServico.length ? { 'servico': formik.values.filtroServico.map((i) => Number(i.id)) } : {}),
      ...(!!formik.values.filtroTipoAtendimento.length ? { 'tipoAtendimento': formik.values.filtroTipoAtendimento.map(Number) } : {}),
      ...(formik.values.filtroNVisitas !== '' ? { 'numeroVisitas': Number(formik.values.filtroNVisitas) } : {}),

      /**Produtos e Serviços */
      ...(!!formik.values.filtroTipodeEquipamento.length ? { 'idTipoEquipamento': Number(formik.values.filtroTipodeEquipamento[0]?.id) } : {}),
      ...(!!formik.values.filtroModelo.length ? { 'idModelo': Number(formik.values.filtroModelo[0]?.id) } : {}),
      ...(formik.values.filtroNSerie !== '' ? { 'numeroSerie': formik.values.filtroNSerie } : {}),
      ...(formik.values.filtroIDEquipamento !== '' ? { 'idEquipamento': Number(formik.values.filtroIDEquipamento) } : {}),

      /**Período */
      ...(!!formik.values.filtroDataAberturaInicio ? { 'inicialAbertura': formik.values.filtroDataAberturaInicio.format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.filtroDataAberturaFim ? { 'finalAbertura': formik.values.filtroDataAberturaFim.format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.filtroDataModificacaoInicio ? { 'inicialModificacao': formik.values.filtroDataModificacaoInicio.format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.filtroDataModificacaoFim ? { 'finalModificacao': formik.values.filtroDataModificacaoFim.format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.filtroDataAtendimentoInicio ? { 'inicialAtendimento': formik.values.filtroDataAtendimentoInicio.format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.filtroDataAtendimentoFim ? { 'finalAtendimento': formik.values.filtroDataAtendimentoFim.format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.filtroDataLimiteInicio ? { 'inicialSLA': formik.values.filtroDataLimiteInicio.format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.filtroDataLimiteFim ? { 'finalSLA': formik.values.filtroDataLimiteFim.format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.filtroDataPreBaixaInicio ? { 'inicialPreBaixa': formik.values.filtroDataPreBaixaInicio.format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.filtroDataPreBaixaFim ? { 'finalPreBaixa': formik.values.filtroDataPreBaixaFim.format("YYYY-MM-DDTHH:mm:ss") } : {}),

      /**Dados do Cliente */
      ...(formik.values.filtroNomeFantasia !== '' ? { 'nomeFantasia': formik.values.filtroNomeFantasia } : {}),
      ...(formik.values.filtroCpfcnpj !== '' ? { 'cpfCnpj': formik.values.filtroCpfcnpj } : {}),
      ...(formik.values.filtroCodigoCliente !== '' ? { 'codigoCliente': formik.values.filtroCodigoCliente } : {}),
      ...(!!formik.values.filtroEstado.length ? { 'estados': formik.values.filtroEstado.map((i) => Number(i.id)) } : {}),
      ...(!!formik.values.filtroCidade.length ? { 'cidades': formik.values.filtroCidade.map((i) => Number(i.id)) } : {}),
      ...(formik.values.filtroCEPInicial !== '' ? { 'cepInicial': formik.values.filtroCEPInicial } : {}),
      ...(formik.values.filtroCEPFinal !== '' ? { 'cepFinal': formik.values.filtroCEPFinal } : {}),

      /**Atendimento */
      ...(formik.values.filtroCanalCredenciadorEC !== '' ? { 'canalCredenciadorEC': formik.values.filtroCanalCredenciadorEC } : {}),
      ...(formik.values.filtroCentrodeTrabalho !== '' ? { 'codigoCT': formik.values.filtroCentrodeTrabalho } : {}),
      ...(!!formik.values.filtroPrestadores.length ? { 'prestadores': formik.values.filtroPrestadores.map(i => Number(i.id)) } : {}),
      ...(!!formik.values.filtroTecnicos.length ? { 'tecnicos': formik.values.filtroTecnicos.map(i => Number(i.id)) } : {}),

      "pageNumber": currentPage,
      "pageSize": pageSize,
    };

    try {
      const { data }: { data: IPostConsultarOSsResponse } = await consultarOSs(filtros) as AxiosResponse;

      setLoading(false);
      setDisableActions(false);
      setDadosConsultaOS(data?.items ?? []);
      setQtdeRegistros(data?.totalCount ?? 0);

    } catch (error: any) {
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaOS([]);
      setQtdeRegistros(0);
      setMsgAlertError((error?.response?.data && typeof error.response.data === 'string') ? error.response.data : "Nenhum dado encontrado");
      setOpenAlertError(true);
    }
  };

  const objTraducaoHeader = {
    chamado: "Chamado (OS)",
    contratante: "Contratante",
    status: "Status",
    codigoCliente: "Cód Cliente",
    referencia: "N.º Referencia",
    nomeCliente: "Nome do cliente",
    dataAbertura: "Data Abertura",
    dataLimite: "Data Limite",
    grupoServico: "Grupo Serviço",
    servico: "Serviço",
    prestador: "Prestador",
    tecnico: "Técnico",
    cidade: "Cidade",
    endereco: "Endereço",
    bairro: "Bairro",
  } as any;

  const getItemByID = (ID: number) => {
    let resultadoDados = [...dadosConsultaOS];

    return resultadoDados.find(
      (item) => item.chamado === ID
    )!;
  };

  const handleClickItemEdit = (data: any) => {
    navigate("/DetalhesOS", {
      state: {
        chamado: data.chamado,
        rota: {
          url: window.location.pathname,
          name: "Consulta de OS",
        },
      }
    });
  };

  const handleClickNewItem = () => {
    navigate("/CadastrarOS", {
      state: {
        rota: {
          url: window.location.pathname,
          name: "Consulta de OS",
        },
      }
    });
  };

  const smoothScroll = (target: any) => {
    var scrollContainer = target;
    do { //find scroll container
      scrollContainer = scrollContainer?.parentNode;
      if (!scrollContainer) return;
      scrollContainer.scrollTop += 1;
    } while (scrollContainer.scrollTop == 0);

    var targetY = 0;
    do { //find the top of target relatively to the container
      if (target == scrollContainer) break;
      targetY += target.offsetTop;
    } while (target = target.offsetParent);

    const scroll = function (c: any, a: number, b: number, i: number) {
      i++; if (i > 30) return;
      c.scrollTop = a + (b - a) / 30 * i;
      setTimeout(function () { scroll(c, a, b, i); }, 20);
    }
    // start scrolling
    scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
  };

  const dadosFormatados = () => {
    let dados = [...dadosConsultaOS];

    /**Formata campos para tabela */
    dados = dados.map((item: any) => {
      return {
        chamado: item.chamado,
        contratante: item.contratante,
        status: item.status,
        referencia: item.referencia,
        codigoCliente: item.cliente?.codigoCliente ?? "",
        nomeCliente: item.cliente?.nomeFantasia ?? "",
        dataAbertura: !!item.dataAbertura ? moment(item.dataAbertura, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
        dataLimite: !!item.dataLimite ? moment(item.dataLimite, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "",
        grupoServico: item.grupoServico,
        servico: item.servico,
        prestador: item.prestador,
        tecnico: item.tecnico,
        // cidade: item.cidade,
        endereco: !!item?.enderecos[0] ? item.enderecos[0].logradouro : "",
        bairro: !!item?.enderecos[0] ? item.enderecos[0].bairro : "",
      };
    })

    return dados;
  };

  const handleGetContratantes = async () => {
    await getContratantesNovo().then((res: any) => {
      const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;
      if (respSuccess) {
        const listaContratante = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id.toString() } }) || [];
        setListaContratante([...[], ...listaContratante])
      }

    }, err => {
      const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
      if (respErr) {
        setListaContratante([]);
      }
    });
  };

  const handleGetTipoEquipamento = async () => {
    try {
      const { data } = await getEquipamentoTipo({
        ativo: true,
        descricao: '',
        idFamilia: ''
      }) as AxiosResponse


      setListaTipodeEquipamento(data.map((item: IConsultarTipoEquipamentoTabela) => {
        return { name: item.descricao, id: item.id.toString() }
      }).sort(
        (a: { name: string, id: string }, b: { name: string, id: string }) =>
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))

    } catch (e) {
      setListaTipodeEquipamento([]);
    }
  };

  const handleGetModelos = async (idTipoEquipamento?: number) => {
    try {
      const { data } = await getEquipamentoModelo(
        null,
        idTipoEquipamento ?? Number(formik.values.filtroTipodeEquipamento[0]),
        null,
        true,
        1,
        300
      ) as AxiosResponse;

      setListaModelo(data?.items?.map((item: any) => {
        return { name: item.descricao, id: item.id.toString() }
      }).sort(
        (a: { name: string, id: string }, b: { name: string, id: string }) =>
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))

    } catch (e) {
      setListaModelo([]);
    }
  };

  const handleChangeTipoEquipamento = async (value: any[]) => {
    formik.setFieldValue('filtroTipodeEquipamento', value);
    if (value[0]) {
      handleGetModelos(Number(value[0].id));
    } else {
      setListaModelo([]);
    };
  };

  const handleGetPrestadores = async () => {
    try {
      const { data } = await getPrestadores() as AxiosResponse

      let prestadores = [];

      if (!!data && !!data.length) {
        prestadores = data.filter((p: any) => !!p.ativo).map((m: any) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }

      if (!_.isEmpty(user?.prestadores)) {
        prestadores = prestadores.filter((prestador: any) => user?.prestadores.includes(Number(prestador.id)))
      }

      setListaPrestadores(prestadores);

    } catch (e) {
      setListaPrestadores([]);
    }
  };

  const handleChangeProvider = (prestadores: any[]) => {
    formik.setFieldValue('filtroPrestadores', prestadores);
  }

  const requestListaTecnicos = async () => {
    let listaTemp: any[] = [];

    for (let i = 0; i < formik.values.filtroPrestadores.length; i++) {
      const id = formik.values.filtroPrestadores[i].id;
      const tecnicos = await requestTecnicosPorContratante(id) as any[];
      listaTemp.push(...tecnicos);
    };

    setListaTecnicos(listaTemp || []);
  };

  const requestTecnicosPorContratante = async (id: string) => {
    try {
      let retorno = await getListaTecnicosByPrestador(Number(id)) as AxiosResponse;
      return retorno?.data
    } catch (e) {
      return []
    }
  };

  const listaTecnicosByPrestadorTratada = () => {
    let resultadoDados;

    resultadoDados = listaTecnicos?.map((item: any) => {
      return {
        name: item.nome || item.nomeCompleto,
        id: item?.idTecnico?.toString() || item.id?.toString(),
      };
    });

    /**Remove itens duplicados */
    resultadoDados = _.uniqBy(resultadoDados, 'id');

    return _.sortBy(resultadoDados, ["name"]);
  };

  const criaListaServico = () => {
    let myServiceList: { name: string, id: string, grupo: string }[] = [];

    if (listaGrupoServico) {
      listaGrupoServico?.map(grupo => {
        if (formik.values.filtroGrupoServico.includes(grupo.id.toString())) {
          grupo.servicos.map((item: any) => (
            myServiceList.push({
              name: item.nome,
              id: item.id,
              grupo: grupo.id.toString(),
            })
          ))
        }
      })
    }

    return myServiceList;
  };

  const compare = (a: { name: string, id: string }, b: { name: string, id: string }) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const handleGetGroupesServices = async () => {
    await getGrouped_Services({
      Ativo: true,
    }).then((resp: any) => {

      const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;

      setListaGrupoServico(respSuccess?.data as any[] ?? []);
    }, err => {
      const respErr = err?.response?.data as ResponseApi<any>;
    });
  };

  const handleChangeRange = (value: any[], filtroRangeData: string, filtroDataInicio: string, filtroDataFim: string,) => {
    formik.setFieldValue(filtroRangeData, value);

    switch (value[0]) {
      case '1':
        formik.setFieldValue(filtroDataInicio, moment().startOf('isoWeek'));
        formik.setFieldValue(filtroDataFim, moment().endOf('isoWeek'));
        break;
      case '2':
        formik.setFieldValue(filtroDataInicio, moment().startOf('month'));
        formik.setFieldValue(filtroDataFim, moment().endOf('month'));
        break;
      case '3':
        formik.setFieldValue(filtroDataInicio, moment().subtract(15, 'days'));
        formik.setFieldValue(filtroDataFim, moment());
        break;
      case '4':
        formik.setFieldValue(filtroDataInicio, moment());
        formik.setFieldValue(filtroDataFim, moment().add(15, 'days'));
        break;
      case '5':
        formik.setFieldValue(filtroDataInicio, moment().startOf('year'));
        formik.setFieldValue(filtroDataFim, moment().endOf('year'));
        break;
    }
  };

  const handleSetPresetSelecionado = (id: string[]) => {
    setPresetSelecionado(id);
    const preset = listaPresets.find(p => p.id === id[0]) || null;

    if (!!preset && !!preset?.preset) {
      let jsonToObjectPreset = isJson(preset.preset) ? JSON.parse(preset.preset) : {};
      let formatedPreset: any = {};

      _.mapKeys(jsonToObjectPreset, function (value: any, key: string) {
        if (key.includes("filtroData")) {

          if (moment(value, "YYYY-MM-DDTHH:mm:ss").isValid()) formatedPreset[key] = moment(value, "YYYY-MM-DDTHH:mm:ss");
          else formatedPreset[key] = null;

        }
        // else if (key === "filtroPrestadores") {
        //   handleChangeProvider(value, true);
        //   formatedPreset[key] = value;
        // } 
        else {
          formatedPreset[key] = value;
        }

        return key;
      });

      formik.setValues({
        ...formik.values,
        ...formatedPreset,
      });
    }
  };

  const handleGetEstados = async () => {
    try {
      const { data }: { data: IGetResponseEstados } = await getEstados({ Ativo: true }) as AxiosResponse;
      const estados = data?.estados ?? [];

      setListaEstado(estados.map(item => ({ name: item.nome, id: item.id.toString(), uf: item.uf })).sort(
        (a: { name: string, id: string }, b: { name: string, id: string }) =>
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ));
    } catch (e) {
      setListaEstado([]);
    }
  };

  const handleGetCidades = async () => {
    if (_.isEmpty(formik.values.filtroEstado)) {
      setListaCidade([]);
      return;
    }

    try {
      const { data }: { data: IGetResponseCidades } = await getCidades({
        Ativo: true,
        IdEstado: Number(formik.values.filtroEstado[0].id),
      }) as AxiosResponse;
      const cidades = data?.cidades ?? [];

      setListaCidade(cidades.map(item => ({ name: item.nome, id: item.id.toString() })).sort(
        (a: { name: string, id: string }, b: { name: string, id: string }) =>
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ));

      formik.setFieldValue('filtroCidade', []);

    } catch (e) {
      setListaCidade([]);
    }
  };

  const handleLimparCampos = () => {
    formik.resetForm();
    setPresetSelecionado(['']);
    setCategoriaPeriodo([]);
    formik.setFieldValue("filtroDataAberturaInicio", null);
    formik.setFieldValue("filtroDataAberturaFim", null);
  };

  const handleClickCriarOs = () => {
    navigate("/CriaOS");
  };

  useEffect(() => {
    handleGetContratantes();
    handleGetPrestadores();
    handleGetGroupesServices();
    handleGetTipoEquipamento();
    handleGetEstados();
  }, []);

  useEffect(() => {
    handleGetCidades();
  }, [formik.values.filtroEstado]);

  useEffect(() => {
    requestListaTecnicos();
  }, [formik.values.filtroPrestadores]);

  useEffect(() => {
    if (!!dadosConsultaOS.length) smoothScroll(document.getElementById('TabelaResultadoConsultaOS'));
  }, [dadosConsultaOS]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleSearch();
    }
  }, [
    currentPage,
    pageSize
  ]);

  return (
    <Section id={"ConsultarOS"}>
      <Header
        setMenuLateral={() => setMenuLateral(true)}
        title="Consultar OS"
      />
      <Breadcrumb>
        <BreadcrumbItem>Consultar OS</BreadcrumbItem>
      </Breadcrumb>
      
      <div className="ConsultarPrestadorVeiculos-Container">
        <div className="filtro-botoes">
            <ButtonOs
              dimension="sm"
              variant="primary"
              onClick={() => handleClickCriarOs()}
            >
              Criar OS
            </ButtonOs>
        </div>
      </div>

      <form className="EditarFamilia-Form" onSubmit={formik.handleSubmit}>
        <Body>
          {!!isModalSavePresetOpen &&
            <ModalSalvarPreset
              handleClose={handleCloseModalSavePreset}
              isModalOpen={isModalSavePresetOpen}
              listaPresets={listaPresets}
              selectedPreset={listaPresets.find(p => p.id === presetSelecionado[0]) || null}
              presetAtual={formik.values}
            />
          }

          {/* <PresetsContainer>
            <Button
              variant="outlined"
              color="info"
              startIcon={<StarIcon />}
              sx={{
                height: '44px',
                borderRadius: "15px",
                lineHeight: "1",
                ".MuiButton-startIcon": { marginRight: "16px !important" },
              }}
              onClick={() => setIsModalSavePresetOpen(true)}
              disabled={_.isEqual({ ...formik.values }, { ...initialValues }) || disableActions}
            >
              Adicionar
            </Button>

            <MultipleSelectCheckmarksid
              dimension="sm"
              label={""}
              nodata={"Nenhum preset encontrado"}
              options={listaPresets}
              value={presetSelecionado}
              onChange={(value) => handleSetPresetSelecionado(value)}
              placeholder={"Presets"}
              id="multiple-checkbox-Presets"
              idOption="multiple-checkbox-option-Presets-"
              labelId="multiple-checkbox-label-Presets"
              classes={{
                select: "ConsultaOS-Seletor error",
                primary: "Seletor-Option",
                group: "Seletor-OptionGroup",
              }}
              multiple={false}
              disabled={disableActions}
            />
          </PresetsContainer> */}

          <AccordionCategoriaConsultarOS id={"AccordionDadosDaOS"} title="Dados da OS" defaultExpanded={true}>
            <div className="AccordionDadosDaOS-grid1 gridresponsive">
              <Input
                type="number"
                className={"ConsultaOS-Input"}
                dimension="sm"
                id="input-NumeroOS"
                label={"N° do Chamado"}
                value={formik.values.filtroNChamado}
                onChange={(e) => (formik.setFieldValue('filtroNChamado', e.target.value))}
                maxLength={255}
                disabled={disableActions}
              />

              <Input
                type="number"
                className={"ConsultaOS-Input"}
                dimension="sm"
                id="input-NumeroReferencia"
                label={"N° Referência"}
                value={formik.values.filtroNReferencia}
                onChange={(e) => (formik.setFieldValue('filtroNReferencia', e.target.value))}
                maxLength={255}
                disabled={disableActions}
              />

              <AutocompleteMultiple
                dimension="sm"
                label={"Status"}
                placeholder=""
                noOptionsText="Nenhum status encontrado"
                options={listaStatus}
                value={formik.values.filtroStatus}
                onChange={(e: any) => (formik.setFieldValue('filtroStatus', e))}
                fontSize={12}
                id="multiple-checkbox-Status"
                multiple
                allSelected
                disabled={disableActions}
              />
            </div>

            <div className="AccordionDadosDaOS-grid2 gridresponsive">
              <MultipleSelectCheckmarksid
                dimension="sm"
                label={"Contratante"}
                nodata={"Nenhum contratante encontrado"}
                options={listaContratante}
                value={formik.values.filtroContratante}
                onChange={(e) => (formik.setFieldValue('filtroContratante', e))}
                placeholder={""}
                id="multiple-checkbox-Contratante"
                idOption="multiple-checkbox-option-Contratante-"
                labelId="multiple-checkbox-label-Contratante"
                classes={{
                  select: "ConsultaOS-Seletor error",
                  primary: "Seletor-Option",
                  group: "Seletor-OptionGroup",
                }}
                selectAll
                disabled={!!user?.contratante || disableActions}
              />

              <MultipleSelectCheckmarksid
                dimension="sm"
                label={"Grupo de serviço"}
                nodata={"Nenhum grupo de serviço encontrado"}
                options={listaGrupoServico?.map((item: any) => ({ name: item.nome, id: item.id.toString() }))!}
                value={formik.values.filtroGrupoServico}
                onChange={(e) => {
                  formik.setFieldValue('filtroGrupoServico', e);
                  formik.setFieldValue('filtroServico', []);
                }}
                placeholder={""}
                id="multiple-checkbox-grupoServico"
                idOption="multiple-checkbox-option-grupoServico-"
                labelId="multiple-checkbox-label-grupoServico"
                disabled={disableActions}
              />

              <AutocompleteMultiple
                dimension="sm"
                label={"Serviço"}
                noOptionsText="Selecione um grupo de serviço"
                options={criaListaServico().sort(compare)}
                value={formik.values.filtroServico}
                onChange={(e: any) => {
                  formik.setFieldValue('filtroServico', e.map((item: any) => { return { name: item.name, id: item.id } }));
                }}
                fontSize={12}
                placeholder={""}
                disabled={disableActions}
              />

              <MultipleSelectCheckmarksid
                dimension="sm"
                label={"Tipo de atendimento"}
                nodata={"Nenhum tipo de atendimento encontrado"}
                options={listaTipoAtendimento}
                value={formik.values.filtroTipoAtendimento}
                onChange={(e) => (formik.setFieldValue('filtroTipoAtendimento', e))}
                placeholder={""}
                id="multiple-checkbox-tipoatendimento"
                idOption="multiple-checkbox-option-tipoatendimento-"
                labelId="multiple-checkbox-label-tipoatendimento"
                classes={{
                  select: "ConsultaOS-Seletor error",
                  primary: "Seletor-Option",
                  group: "Seletor-OptionGroup",
                }}
                disabled={disableActions}
              />

              <Input
                type="number"
                className={"ConsultaOS-Input"}
                dimension="sm"
                id="input-NVisitas"
                label={"N° de visitas"}
                value={formik.values.filtroNVisitas}
                onChange={(e) => (formik.setFieldValue('filtroNVisitas', e.target.value))}
                maxLength={255}
                disabled={disableActions}
              />
            </div>
          </AccordionCategoriaConsultarOS>

          <AccordionCategoriaConsultarOS id={"AccordionProdutosEServicos"} title="Produtos e Serviços">
            {/* <div className="AccordionProdutosEServicos-grid1 gridresponsive">
              <MultipleSelectCheckmarksid
                dimension="sm"
                label={"Categoria"}
                nodata={"Nenhuma Categoria encontrada"}
                options={listaCategoriaProdutosEServicos}
                value={categoriaProdutosEServicos}
                onChange={(value) => setCategoriaProdutosEServicos(value)}
                placeholder={""}
                id="multiple-checkbox-Range"
                idOption="multiple-checkbox-option-Range-"
                labelId="multiple-checkbox-label-Range"
                classes={{
                  select: "ConsultaOS-Seletor error",
                  primary: "Seletor-Option",
                  group: "Seletor-OptionGroup",
                }}
                disabled={disableActions}
              />

              <Button
                variant="outlined"
                color="info"
                startIcon={<AddIcon />}
                sx={{
                  borderRadius: "15px",
                  lineHeight: "1",
                  ".MuiButton-startIcon": { marginRight: "16px !important" },
                }}
                onClick={() => formik.setFieldValue('filtroCategoriaProdutosEServicos', categoriaProdutosEServicos)}
                disabled={disableActions}
              >
                Adicionar
              </Button>
            </div> */}

            <div className="AccordionProdutosEServicos-grid3 gridresponsive">
              {/* <CloseIcon className="CloseIconMaisBusca" onClick={() => handleCloseCategoriaProdutosEServicos('1')} /> */}

              <div>
                <InputLabel>Instalação</InputLabel>
                <AutocompleteMultiple
                  dimension="sm"
                  label={"Tipo de Equipamento"}
                  placeholder={""}
                  noOptionsText={"Nenhum Tipo de Equipamento encontrado"}
                  id="multiple-checkbox-TipodeEquipamento"
                  options={listaTipodeEquipamento}
                  value={formik.values.filtroTipodeEquipamento}
                  onChange={handleChangeTipoEquipamento}
                  fontSize={12}
                  multiple={false}
                  disabled={disableActions}
                />
              </div>

              <AutocompleteMultiple
                dimension="sm"
                label={"Modelo"}
                placeholder={""}
                noOptionsText={"Nenhum Modelo encontrado"}
                id="multiple-checkbox-Modelo"
                options={listaModelo}
                value={formik.values.filtroModelo}
                onChange={(e: any) => (formik.setFieldValue('filtroModelo', e))}
                fontSize={12}
                multiple={false}
                disabled={!formik.values.filtroTipodeEquipamento.length || disableActions}
              />

              <Input
                type="number"
                className={"NSerie-Input"}
                dimension="sm"
                id="input-NSerie"
                label={"Numero de Série"}
                value={formik.values.filtroNSerie}
                onChange={(e) => (formik.setFieldValue('filtroNSerie', e.target.value))}
                maxLength={255}
                disabled={disableActions}
              />

              <Input
                type="number"
                className={"filtroIDEquipamento-Input"}
                dimension="sm"
                id="input-filtroIDEquipamento"
                label={"ID do Equipamento"}
                value={formik.values.filtroIDEquipamento}
                onChange={(e) => (formik.setFieldValue('filtroIDEquipamento', e.target.value))}
                maxLength={255}
                disabled={disableActions}
              />
            </div>

            {!!formik.values.filtroCategoriaProdutosEServicos.includes('1') &&
              <div className="AccordionProdutosEServicos-grid2 gridresponsive">
                <CloseIcon className="CloseIconMaisBusca" onClick={() => handleCloseCategoriaProdutosEServicos('1')} />

                <div>
                  <InputLabel>Tecnologia de Pagamento</InputLabel>
                  <MultipleSelectCheckmarksid
                    dimension="sm"
                    label={"Equipamento Cliente"}
                    nodata={"Nenhum Equipamento Cliente encontrado"}
                    options={listaEquipamentoCliente}
                    value={formik.values.filtroEquipamentoCliente}
                    onChange={(e) => (formik.setFieldValue('filtroEquipamentoCliente', e))}
                    placeholder={""}
                    id="multiple-checkbox-EquipamentoCliente"
                    idOption="multiple-checkbox-option-EquipamentoCliente-"
                    labelId="multiple-checkbox-label-EquipamentoCliente"
                    classes={{
                      select: "ConsultaOS-Seletor error",
                      primary: "Seletor-Option",
                      group: "Seletor-OptionGroup",
                    }}
                    // multiple={false}
                    disabled={disableActions}
                  />
                </div>

                <MultipleSelectCheckmarksid
                  dimension="sm"
                  label={"Modelo Cliente"}
                  nodata={"Nenhum Modelo Cliente encontrado"}
                  options={listaModeloCliente}
                  value={formik.values.filtroModeloCliente}
                  onChange={(e) => (formik.setFieldValue('filtroModeloCliente', e))}
                  placeholder={""}
                  id="multiple-checkbox-ModeloCliente"
                  idOption="multiple-checkbox-option-ModeloCliente-"
                  labelId="multiple-checkbox-label-ModeloCliente"
                  classes={{
                    select: "ConsultaOS-Seletor error",
                    primary: "Seletor-Option",
                    group: "Seletor-OptionGroup",
                  }}
                  // multiple={false}
                  disabled={disableActions}
                />

                <Input
                  type="number"
                  className={"NSerieCliente-Input"}
                  dimension="sm"
                  id="input-NSerieCliente"
                  label={"N° Série Cliente"}
                  value={formik.values.filtroNSerieCliente}
                  onChange={(e) => (formik.setFieldValue('filtroNSerieCliente', e.target.value))}
                  maxLength={255}
                  disabled={disableActions}
                />


                <MultipleSelectCheckmarksid
                  dimension="sm"
                  label={"Equipamento Retirada"}
                  nodata={"Nenhum Equipamento Retirada encontrado"}
                  options={listaEquipamentoRetirada}
                  value={formik.values.filtroEquipamentoRetirada}
                  onChange={(e) => (formik.setFieldValue('filtroEquipamentoRetirada', e))}
                  placeholder={""}
                  id="multiple-checkbox-EquipamentoRetirada"
                  idOption="multiple-checkbox-option-EquipamentoRetirada-"
                  labelId="multiple-checkbox-label-EquipamentoRetirada"
                  classes={{
                    select: "ConsultaOS-Seletor error",
                    primary: "Seletor-Option",
                    group: "Seletor-OptionGroup",
                  }}
                  // multiple={false}
                  disabled={disableActions}
                />

                <MultipleSelectCheckmarksid
                  dimension="sm"
                  label={"Modelo Retirada"}
                  nodata={"Nenhum Modelo Retirada encontrado"}
                  options={listaModeloRetirada}
                  value={formik.values.filtroModeloRetirada}
                  onChange={(e) => (formik.setFieldValue('filtroModeloRetirada', e))}
                  placeholder={""}
                  id="multiple-checkbox-ModeloRetirada"
                  idOption="multiple-checkbox-option-ModeloRetirada-"
                  labelId="multiple-checkbox-label-ModeloRetirada"
                  classes={{
                    select: "ConsultaOS-Seletor error",
                    primary: "Seletor-Option",
                    group: "Seletor-OptionGroup",
                  }}
                  // multiple={false}
                  disabled={disableActions}
                />

                <Input
                  type="number"
                  className={"NSerieRetirada-Input"}
                  dimension="sm"
                  id="input-NSerieRetirada"
                  label={"N° Série Retirada"}
                  value={formik.values.filtroNSerieRetirada}
                  onChange={(e) => (formik.setFieldValue('filtroNSerieRetirada', e.target.value))}
                  maxLength={255}
                  disabled={disableActions}
                />
              </div>
            }

            {!!formik.values.filtroCategoriaProdutosEServicos.includes('2') &&
              <div className="AccordionProdutosEServicos-grid2 gridresponsive">
                <CloseIcon className="CloseIconMaisBusca" onClick={() => handleCloseCategoriaProdutosEServicos('2')} />

                <div>
                  <InputLabel>Pesquisas</InputLabel>
                  <MultipleSelectCheckmarksid
                    dimension="sm"
                    label={"Metodologia"}
                    nodata={"Nenhuma Metodologia encontrada"}
                    options={listaMetodologia}
                    value={formik.values.filtroMetodologia}
                    onChange={(e) => (formik.setFieldValue('filtroMetodologia', e))}
                    placeholder={""}
                    id="multiple-checkbox-Metodologia"
                    idOption="multiple-checkbox-option-Metodologia-"
                    labelId="multiple-checkbox-label-Metodologia"
                    classes={{
                      select: "ConsultaOS-Seletor error",
                      primary: "Seletor-Option",
                      group: "Seletor-OptionGroup",
                    }}
                    // multiple={false}
                    disabled={disableActions}
                  />
                </div>

                <MultipleSelectCheckmarksid
                  dimension="sm"
                  label={"Coleta de Dados"}
                  nodata={"Nenhuma Coleta de Dados encontrada"}
                  options={listaColetaDeDados}
                  value={formik.values.filtroColetaDeDados}
                  onChange={(e) => (formik.setFieldValue('filtroColetaDeDados', e))}
                  placeholder={""}
                  id="multiple-checkbox-ColetaDeDados"
                  idOption="multiple-checkbox-option-ColetaDeDados-"
                  labelId="multiple-checkbox-label-ColetaDeDados"
                  classes={{
                    select: "ConsultaOS-Seletor error",
                    primary: "Seletor-Option",
                    group: "Seletor-OptionGroup",
                  }}
                  // multiple={false}
                  disabled={disableActions}
                />

                <Input
                  className={"PublicoAlvoPesquisas-Input"}
                  dimension="sm"
                  id="input-PublicoAlvoPesquisas"
                  label={"Público Alvo"}
                  value={formik.values.filtroPublicoAlvoPesquisas}
                  onChange={(e) => (formik.setFieldValue('filtroPublicoAlvoPesquisas', e.target.value))}
                  maxLength={255}
                  disabled={disableActions}
                />
              </div>
            }

            {!!formik.values.filtroCategoriaProdutosEServicos.includes('3') &&
              <div className="AccordionProdutosEServicos-grid2 gridresponsive">
                <CloseIcon className="CloseIconMaisBusca" onClick={() => handleCloseCategoriaProdutosEServicos('3')} />

                <div>
                  <InputLabel>Alimentos</InputLabel>
                  <MultipleSelectCheckmarksid
                    dimension="sm"
                    label={"Tipo de Entrega"}
                    nodata={"Nenhum Tipo de Entrega encontrado"}
                    options={listaTipoDeEntrega}
                    value={formik.values.filtroTipoDeEntrega}
                    onChange={(e) => (formik.setFieldValue('filtroTipoDeEntrega', e))}
                    placeholder={""}
                    id="multiple-checkbox-TipoDeEntrega"
                    idOption="multiple-checkbox-option-TipoDeEntrega-"
                    labelId="multiple-checkbox-label-TipoDeEntrega"
                    classes={{
                      select: "ConsultaOS-Seletor error",
                      primary: "Seletor-Option",
                      group: "Seletor-OptionGroup",
                    }}
                    // multiple={false}
                    disabled={disableActions}
                  />
                </div>

                <MultipleSelectCheckmarksid
                  dimension="sm"
                  label={"Produto"}
                  nodata={"Nenhum Produto encontrado"}
                  options={listaProduto}
                  value={formik.values.filtroProduto}
                  onChange={(e) => (formik.setFieldValue('filtroProduto', e))}
                  placeholder={""}
                  id="multiple-checkbox-Produto"
                  idOption="multiple-checkbox-option-Produto-"
                  labelId="multiple-checkbox-label-Produto"
                  classes={{
                    select: "ConsultaOS-Seletor error",
                    primary: "Seletor-Option",
                    group: "Seletor-OptionGroup",
                  }}
                  // multiple={false}
                  disabled={disableActions}
                />

                <Input
                  className={"PublicoAlvoAlimentos-Input"}
                  dimension="sm"
                  id="input-PublicoAlvoAlimentos"
                  label={"Público Alvo"}
                  value={formik.values.filtroPublicoAlvoAlimentos}
                  onChange={(e) => (formik.setFieldValue('filtroPublicoAlvoAlimentos', e.target.value))}
                  maxLength={255}
                  disabled={disableActions}
                />
              </div>
            }
          </AccordionCategoriaConsultarOS>

          <AccordionCategoriaConsultarOS id={"AccordionPeriodo"} title="Período">
            <div className="AccordionPeriodo-grid1 gridresponsive">
              <div>
                <InputLabel>Abertura</InputLabel>
                <InputsRowDatePickers>
                  <DatePicker
                    label={"Data de início"}
                    id="DatePicker-Abertura"
                    placeholder="Início"
                    value={formik.values.filtroDataAberturaInicio}
                    onChange={(e) => (formik.setFieldValue('filtroDataAberturaInicio', e))}
                    dimension='sm'
                    maxDate={formik.values.filtroDataAberturaFim}
                    disabled={disableActions}
                    error={formik.touched.filtroDataAberturaInicio && Boolean(formik.errors.filtroDataAberturaInicio)}
                    helperText={formik.touched.filtroDataAberturaInicio && formik.errors.filtroDataAberturaInicio}
                  />
                  <DatePicker
                    label={"Data de Fim"}
                    id="DatePicker-Abertura-Fim"
                    placeholder="Fim"
                    value={formik.values.filtroDataAberturaFim}
                    onChange={(e) => (formik.setFieldValue('filtroDataAberturaFim', e))}
                    dimension='sm'
                    minDate={formik.values.filtroDataAberturaInicio}
                    disabled={disableActions}
                    error={formik.touched.filtroDataAberturaFim && Boolean(formik.errors.filtroDataAberturaFim)}
                    helperText={formik.touched.filtroDataAberturaFim && formik.errors.filtroDataAberturaFim}
                  />
                </InputsRowDatePickers>
              </div>

              <ORLabel>ou</ORLabel>

              <MultipleSelectCheckmarksid
                dimension="sm"
                label={"Range"}
                nodata={"Nenhum range encontrado"}
                options={listaRangeDatas}
                value={formik.values.filtroRangeDataAbertura}
                onChange={(value) => handleChangeRange(value, 'filtroRangeDataAbertura', 'filtroDataAberturaInicio', 'filtroDataAberturaFim')}
                placeholder={""}
                id="multiple-checkbox-RangeAbertura"
                idOption="multiple-checkbox-option-RangeAbertura-"
                labelId="multiple-checkbox-label-RangeAbertura"
                classes={{
                  select: "ConsultaOS-Seletor error",
                  primary: "Seletor-Option",
                  group: "Seletor-OptionGroup",
                }}
                multiple={false}
                disabled={disableActions}
              />
            </div>

            <div className="AccordionPeriodo-grid2 gridresponsive">
              <MultipleSelectCheckmarksid
                dimension="sm"
                label={"Outros Períodos"}
                nodata={"Nenhum período encontrado"}
                options={listaCategoriaPeriodo}
                value={categoriaPeriodo}
                onChange={(value) => setCategoriaPeriodo(value)}
                placeholder={""}
                id="multiple-checkbox-OutrosPeriodos"
                idOption="multiple-checkbox-option-OutrosPeriodos-"
                labelId="multiple-checkbox-label-OutrosPeriodos"
                classes={{
                  select: "ConsultaOS-Seletor error",
                  primary: "Seletor-Option",
                  group: "Seletor-OptionGroup",
                }}
                disabled={disableActions}
              />

              <Button
                variant="outlined"
                color="info"
                startIcon={<AddIcon />}
                sx={{
                  borderRadius: "15px",
                  lineHeight: "1",
                  ".MuiButton-startIcon": { marginRight: "16px !important" },
                }}
                onClick={() => formik.setFieldValue('filtroCategoriaPeriodo', categoriaPeriodo)}
                disabled={disableActions}
              >
                Adicionar
              </Button>
            </div>

            {!!formik.values.filtroCategoriaPeriodo.includes('1') &&
              <div className="AccordionPeriodo-grid3 gridresponsive">
                <CloseIcon className="CloseIconMaisBusca" onClick={() => handleCloseCategoriaPeriodo('1')} />

                <div>
                  <InputLabel>Modificação</InputLabel>
                  <InputsRowDatePickers>
                    <DatePicker
                      label={"Data de início"}
                      id="DatePicker-Modificacao"
                      placeholder="Início"
                      value={formik.values.filtroDataModificacaoInicio}
                      onChange={(e) => (formik.setFieldValue('filtroDataModificacaoInicio', e))}
                      dimension='sm'
                      maxDate={formik.values.filtroDataModificacaoFim}
                      disabled={disableActions}
                      error={formik.touched.filtroDataModificacaoInicio && Boolean(formik.errors.filtroDataModificacaoInicio)}
                      helperText={formik.touched.filtroDataModificacaoInicio && formik.errors.filtroDataModificacaoInicio}
                    />
                    <DatePicker
                      label={"Data de Fim"}
                      id="DatePicker-Modificacao-Fim"
                      placeholder="Fim"
                      value={formik.values.filtroDataModificacaoFim}
                      onChange={(e) => (formik.setFieldValue('filtroDataModificacaoFim', e))}
                      dimension='sm'
                      minDate={formik.values.filtroDataModificacaoInicio}
                      disabled={disableActions}
                      error={formik.touched.filtroDataModificacaoFim && Boolean(formik.errors.filtroDataModificacaoFim)}
                      helperText={formik.touched.filtroDataModificacaoFim && formik.errors.filtroDataModificacaoFim}
                    />
                  </InputsRowDatePickers>
                </div>

                <ORLabel>ou</ORLabel>

                <MultipleSelectCheckmarksid
                  dimension="sm"
                  label={"Range"}
                  nodata={"Nenhum range encontrado"}
                  options={listaRangeDatas}
                  value={formik.values.filtroRangeDataModificacao}
                  onChange={(value) => handleChangeRange(value, 'filtroRangeDataModificacao', 'filtroDataModificacaoInicio', 'filtroDataModificacaoFim')}
                  placeholder={""}
                  id="multiple-checkbox-RangeModificacao"
                  idOption="multiple-checkbox-option-RangeModificacao-"
                  labelId="multiple-checkbox-label-RangeModificacao"
                  classes={{
                    select: "ConsultaOS-Seletor error",
                    primary: "Seletor-Option",
                    group: "Seletor-OptionGroup",
                  }}
                  multiple={false}
                  disabled={disableActions}
                />
              </div>
            }

            {!!formik.values.filtroCategoriaPeriodo.includes('2') &&
              <div className="AccordionPeriodo-grid3 gridresponsive">
                <CloseIcon className="CloseIconMaisBusca" onClick={() => handleCloseCategoriaPeriodo('2')} />

                <div>
                  <InputLabel>Atendimento</InputLabel>
                  <InputsRowDatePickers>
                    <DatePicker
                      label={"Data de início"}
                      id="DatePicker-Atendimento"
                      placeholder="Início"
                      value={formik.values.filtroDataAtendimentoInicio}
                      onChange={(e) => (formik.setFieldValue('filtroDataAtendimentoInicio', e))}
                      dimension='sm'
                      maxDate={formik.values.filtroDataAtendimentoFim}
                      disabled={disableActions}
                      error={formik.touched.filtroDataAtendimentoInicio && Boolean(formik.errors.filtroDataAtendimentoInicio)}
                      helperText={formik.touched.filtroDataAtendimentoInicio && formik.errors.filtroDataAtendimentoInicio}
                    />
                    <DatePicker
                      label={"Data de Fim"}
                      id="DatePicker-Atendimento-Fim"
                      placeholder="Fim"
                      value={formik.values.filtroDataAtendimentoFim}
                      onChange={(e) => (formik.setFieldValue('filtroDataAtendimentoFim', e))}
                      dimension='sm'
                      minDate={formik.values.filtroDataAtendimentoInicio}
                      disabled={disableActions}
                      error={formik.touched.filtroDataAtendimentoFim && Boolean(formik.errors.filtroDataAtendimentoFim)}
                      helperText={formik.touched.filtroDataAtendimentoFim && formik.errors.filtroDataAtendimentoFim}
                    />
                  </InputsRowDatePickers>
                </div>

                <ORLabel>ou</ORLabel>

                <MultipleSelectCheckmarksid
                  dimension="sm"
                  label={"Range"}
                  nodata={"Nenhum range encontrado"}
                  options={listaRangeDatas}
                  value={formik.values.filtroRangeDataAtendimento}
                  onChange={(value) => handleChangeRange(value, 'filtroRangeDataAtendimento', 'filtroDataAtendimentoInicio', 'filtroDataAtendimentoFim')}
                  placeholder={""}
                  id="multiple-checkbox-RangeAtendimento"
                  idOption="multiple-checkbox-option-RangeAtendimento-"
                  labelId="multiple-checkbox-label-RangeAtendimento"
                  classes={{
                    select: "ConsultaOS-Seletor error",
                    primary: "Seletor-Option",
                    group: "Seletor-OptionGroup",
                  }}
                  multiple={false}
                  disabled={disableActions}
                />
              </div>
            }

            {!!formik.values.filtroCategoriaPeriodo.includes('3') &&
              <div className="AccordionPeriodo-grid3 gridresponsive">
                <CloseIcon className="CloseIconMaisBusca" onClick={() => handleCloseCategoriaPeriodo('3')} />

                <div>
                  <InputLabel>Limite (SLA)</InputLabel>
                  <InputsRowDatePickers>
                    <DatePicker
                      label={"Data de início"}
                      id="DatePicker-Limite"
                      placeholder="Início"
                      value={formik.values.filtroDataLimiteInicio}
                      onChange={(e) => (formik.setFieldValue('filtroDataLimiteInicio', e))}
                      dimension='sm'
                      maxDate={formik.values.filtroDataLimiteFim}
                      disabled={disableActions}
                      error={formik.touched.filtroDataLimiteInicio && Boolean(formik.errors.filtroDataLimiteInicio)}
                      helperText={formik.touched.filtroDataLimiteInicio && formik.errors.filtroDataLimiteInicio}
                    />
                    <DatePicker
                      label={"Data de Fim"}
                      id="DatePicker-Limite-Fim"
                      placeholder="Fim"
                      value={formik.values.filtroDataLimiteFim}
                      onChange={(e) => (formik.setFieldValue('filtroDataLimiteFim', e))}
                      dimension='sm'
                      minDate={formik.values.filtroDataLimiteInicio}
                      disabled={disableActions}
                      error={formik.touched.filtroDataLimiteFim && Boolean(formik.errors.filtroDataLimiteFim)}
                      helperText={formik.touched.filtroDataLimiteFim && formik.errors.filtroDataLimiteFim}
                    />
                  </InputsRowDatePickers>
                </div>

                <ORLabel>ou</ORLabel>

                <MultipleSelectCheckmarksid
                  dimension="sm"
                  label={"Range"}
                  nodata={"Nenhum range encontrado"}
                  options={listaRangeDatas}
                  value={formik.values.filtroRangeDataLimite}
                  onChange={(value) => handleChangeRange(value, 'filtroRangeDataLimite', 'filtroDataLimiteInicio', 'filtroDataLimiteFim')}
                  placeholder={""}
                  id="multiple-checkbox-RangeLimite"
                  idOption="multiple-checkbox-option-RangeLimite-"
                  labelId="multiple-checkbox-label-RangeLimite"
                  classes={{
                    select: "ConsultaOS-Seletor error",
                    primary: "Seletor-Option",
                    group: "Seletor-OptionGroup",
                  }}
                  multiple={false}
                  disabled={disableActions}
                />
              </div>
            }

            {!!formik.values.filtroCategoriaPeriodo.includes('4') &&
              <div className="AccordionPeriodo-grid3 gridresponsive">
                <CloseIcon className="CloseIconMaisBusca" onClick={() => handleCloseCategoriaPeriodo('4')} />

                <div>
                  <InputLabel>Pré-Baixa</InputLabel>
                  <InputsRowDatePickers>
                    <DatePicker
                      label={"Data de início"}
                      id="DatePicker-PreBaixa"
                      placeholder="Início"
                      value={formik.values.filtroDataPreBaixaInicio}
                      onChange={(e) => (formik.setFieldValue('filtroDataPreBaixaInicio', e))}
                      dimension='sm'
                      maxDate={formik.values.filtroDataPreBaixaFim}
                      disabled={disableActions}
                      error={formik.touched.filtroDataPreBaixaInicio && Boolean(formik.errors.filtroDataPreBaixaInicio)}
                      helperText={formik.touched.filtroDataPreBaixaInicio && formik.errors.filtroDataPreBaixaInicio}
                    />
                    <DatePicker
                      label={"Data de Fim"}
                      id="DatePicker-PreBaixa-Fim"
                      placeholder="Fim"
                      value={formik.values.filtroDataPreBaixaFim}
                      onChange={(e) => (formik.setFieldValue('filtroDataPreBaixaFim', e))}
                      dimension='sm'
                      minDate={formik.values.filtroDataPreBaixaInicio}
                      disabled={disableActions}
                      error={formik.touched.filtroDataPreBaixaFim && Boolean(formik.errors.filtroDataPreBaixaFim)}
                      helperText={formik.touched.filtroDataPreBaixaFim && formik.errors.filtroDataPreBaixaFim}
                    />
                  </InputsRowDatePickers>
                </div>

                <ORLabel>ou</ORLabel>

                <MultipleSelectCheckmarksid
                  dimension="sm"
                  label={"Range"}
                  nodata={"Nenhum range encontrado"}
                  options={listaRangeDatas}
                  value={formik.values.filtroRangeDataPreBaixa}
                  onChange={(value) => handleChangeRange(value, 'filtroRangeDataPreBaixa', 'filtroDataPreBaixaInicio', 'filtroDataPreBaixaFim')}
                  placeholder={""}
                  id="multiple-checkbox-RangePreBaixa"
                  idOption="multiple-checkbox-option-RangePreBaixa-"
                  labelId="multiple-checkbox-label-RangePreBaixa"
                  classes={{
                    select: "ConsultaOS-Seletor error",
                    primary: "Seletor-Option",
                    group: "Seletor-OptionGroup",
                  }}
                  multiple={false}
                  disabled={disableActions}
                />
              </div>
            }

            {!!formik.values.filtroCategoriaPeriodo.includes('5') &&
              <div className="AccordionPeriodo-grid3 gridresponsive">
                <CloseIcon className="CloseIconMaisBusca" onClick={() => handleCloseCategoriaPeriodo('5')} />

                <div>
                  <InputLabel>Agendamento</InputLabel>
                  <InputsRowDatePickers>
                    <DatePicker
                      label={"Data de início"}
                      id="DatePicker-Agendamento"
                      placeholder="Início"
                      value={formik.values.filtroDataAgendamentoInicio}
                      onChange={(e) => (formik.setFieldValue('filtroDataAgendamentoInicio', e))}
                      dimension='sm'
                      maxDate={formik.values.filtroDataAgendamentoFim}
                      disabled={disableActions}
                      error={formik.touched.filtroDataAgendamentoInicio && Boolean(formik.errors.filtroDataAgendamentoInicio)}
                      helperText={formik.touched.filtroDataAgendamentoInicio && formik.errors.filtroDataAgendamentoInicio}
                    />
                    <DatePicker
                      label={"Data de Fim"}
                      id="DatePicker-Agendamento-Fim"
                      placeholder="Fim"
                      value={formik.values.filtroDataAgendamentoFim}
                      onChange={(e) => (formik.setFieldValue('filtroDataAgendamentoFim', e))}
                      dimension='sm'
                      minDate={formik.values.filtroDataAgendamentoInicio}
                      disabled={disableActions}
                      error={formik.touched.filtroDataAgendamentoFim && Boolean(formik.errors.filtroDataAgendamentoFim)}
                      helperText={formik.touched.filtroDataAgendamentoFim && formik.errors.filtroDataAgendamentoFim}
                    />
                  </InputsRowDatePickers>
                </div>

                <ORLabel>ou</ORLabel>

                <MultipleSelectCheckmarksid
                  dimension="sm"
                  label={"Range"}
                  nodata={"Nenhum range encontrado"}
                  options={listaRangeDatas}
                  value={formik.values.filtroRangeDataAgendamento}
                  onChange={(value) => handleChangeRange(value, 'filtroRangeDataAgendamento', 'filtroDataAgendamentoInicio', 'filtroDataAgendamentoFim')}
                  placeholder={""}
                  id="multiple-checkbox-RangeAgendamento"
                  idOption="multiple-checkbox-option-RangeAgendamento-"
                  labelId="multiple-checkbox-label-RangeAgendamento"
                  classes={{
                    select: "ConsultaOS-Seletor error",
                    primary: "Seletor-Option",
                    group: "Seletor-OptionGroup",
                  }}
                  multiple={false}
                  disabled={disableActions}
                />
              </div>
            }
          </AccordionCategoriaConsultarOS>

          <AccordionCategoriaConsultarOS id={"AccordionDadosDoCliente"} title="Dados do Cliente">
            <div className="AccordionDadosDoCliente-grid1 gridresponsive">
              <Input
                className={"ConsultaOS-Input"}
                dimension="sm"
                id="input-NomeFantasia"
                label={"Nome Fantasia"}
                value={formik.values.filtroNomeFantasia}
                onChange={(e) => (formik.setFieldValue('filtroNomeFantasia', e.target.value))}
                maxLength={255}
                disabled={disableActions}
              />
              <Input
                className={"ConsultaOS-Input"}
                dimension="sm"
                id="input-cpfcnpj"
                label={"CPF/CNPJ"}
                value={formik.values.filtroCpfcnpj}
                // onChange={handleChangeMask}
                onChange={(e) => (formik.setFieldValue('filtroCpfcnpj', maskCPFCNPJ(e.target.value)))}
                maxLength={255}
                disabled={disableActions}
              />
              <Input
                type="number"
                className={"ConsultaOS-Input"}
                dimension="sm"
                id="input-CodigoCliente"
                label={"Cód Cliente"}
                value={formik.values.filtroCodigoCliente}
                onChange={(e) => (formik.setFieldValue('filtroCodigoCliente', e.target.value))}
                maxLength={255}
                disabled={disableActions}
              />
            </div>

            <div className="AccordionDadosDoCliente-grid2 gridresponsive">
              <AutocompleteMultiple
                dimension="sm"
                noOptionsText="Nenhum estado encontrado"
                options={listaEstado}
                value={formik.values.filtroEstado}
                onChange={(e: any) => (formik.setFieldValue('filtroEstado', e))}
                fontSize={12}
                label={"Estado"}
                placeholder=""
                id="multiple-checkbox-Estado"
                multiple={false}
                disabled={disableActions}
              />

              <AutocompleteMultiple
                dimension="sm"
                noOptionsText="Selecione um estado"
                options={listaCidade}
                value={formik.values.filtroCidade}
                onChange={(e: any) => (formik.setFieldValue('filtroCidade', e))}
                fontSize={12}
                label={"Cidade"}
                placeholder=""
                id="multiple-checkbox-Cidade"
                allSelected
                multiple
                disabled={disableActions}
              />

              <Input
                type="number"
                className={"ConsultaOS-Input"}
                dimension="sm"
                id="input-CEPInicial"
                label={"CEP Inicial"}
                value={formik.values.filtroCEPInicial}
                onChange={(e) => (formik.setFieldValue('filtroCEPInicial', e.target.value))}
                maxLength={255}
                disabled={disableActions}
              />

              <Input
                type="number"
                className={"ConsultaOS-Input"}
                dimension="sm"
                id="input-CEPFinal"
                label={"CEP Final"}
                value={formik.values.filtroCEPFinal}
                onChange={(e) => (formik.setFieldValue('filtroCEPFinal', e.target.value))}
                maxLength={255}
                disabled={!formik.values.filtroCEPInicial.length || disableActions}
              />
            </div>

          </AccordionCategoriaConsultarOS>

          <AccordionCategoriaConsultarOS id={"AccordionAtendimento"} title="Atendimento">
            <div className="AccordionAtendimento-grid1 gridresponsive">
              <Input
                dimension="sm"
                label={"Canal Credenciador EC"}
                className={"ConsultaOS-Input"}
                id="input-canalCredenciadorEC"
                value={formik.values.filtroCanalCredenciadorEC}
                onChange={(e) => (formik.setFieldValue('filtroCanalCredenciadorEC', e.target.value))}
                maxLength={255}
                disabled={disableActions}
              />

              <Input
                dimension="sm"
                label={"Centro de  Trabalho"}
                className={"ConsultaOS-Input"}
                id="input-centrodeTrabalho"
                value={formik.values.filtroCentrodeTrabalho}
                onChange={(e) => (formik.setFieldValue('filtroCentrodeTrabalho', e.target.value))}
                maxLength={255}
                disabled={disableActions}
              />

              <AutocompleteMultiple
                dimension="sm"
                label={"Prestadores"}
                placeholder={""}
                noOptionsText={"Nenhum prestador encontrado"}
                id="multiple-checkbox-Prestadores-Farol"
                options={listaPrestadores}
                value={formik.values.filtroPrestadores}
                onChange={handleChangeProvider}
                fontSize={12}
                disabled={disableActions}
              />

              <AutocompleteMultiple
                dimension="sm"
                label={"Técnico"}
                placeholder={""}
                noOptionsText={"Selecione um prestador"}
                id="multiple-checkbox-Tecnico"
                options={listaTecnicosByPrestadorTratada()}
                value={formik.values.filtroTecnicos}
                onChange={(e: any) => (formik.setFieldValue('filtroTecnicos', e))}
                fontSize={12}
                disabled={disableActions}
              />

              {/* <Autocompletetecnico
                dimension="sm"
                label={"Técnico"}
                placeholder={""}
                noOptionsText={"Selecione um prestador"}
                id="multiple-checkbox-Tecnico"
                autocompleteValue={formik.values.filtroTecnicos}
                onChangeValue={(e: any) => (formik.setFieldValue('filtroTecnicos', e))}
                options={listaTecnicosByPrestadorTratada()}
                requestOptions={requestListaTecnicos}
                disabled={disableActions}
                fontSize={16}
              /> */}
            </div>

          </AccordionCategoriaConsultarOS>

          <ContainerButtons>
            <NewUserButton
              variant="contained"
              size="small"
              onClick={handleLimparCampos}
              disabled={disableActions}
            >
              Limpar
            </NewUserButton>

            <SearchButton
              variant="contained"
              size="small"
              type="submit"
              disabled={disableActions}
            >
              Buscar
            </SearchButton>
          </ContainerButtons>
        </Body>
      </form>

      <AlertMessage
        isOpenAlert={openAlertError}
        alertType="error"
        setOpenAlert={setOpenAlertError}
        msgAlert={msgAlertError}
      />

      {!!loading && <CircularProgress style={{ margin: '0px auto' }} />}

      {!!dadosConsultaOS.length && (
        <>
          <ResultLabel>
            {(!!formik.values.filtroDataAberturaInicio && !!formik.values.filtroDataAberturaFim) ?
              `Foram encontrados ${qtdeRegistros} registros no período de data início ${formik.values.filtroDataAberturaInicio.format("DD/MM/YYYY")} à data fim ${formik.values.filtroDataAberturaFim.format("DD/MM/YYYY")}.`
              :
              `Foram encontrados ${qtdeRegistros} registros.`
            }
          </ResultLabel>

          {/* <ExportContainer>
            <Button
              variant="outlined"
              color="info"
              startIcon={<ExportIcon />}
              sx={{
                borderRadius: "15px",
                lineHeight: "1",
                ".MuiButton-startIcon": { marginRight: "16px !important" },
              }}
              disabled={disableActions}
            >
              Exportar
            </Button>
          </ExportContainer> */}

          <StickyTable
            id={"TabelaResultadoConsultaOS"}
            data={dadosFormatados()}
            handleClickEdit={handleClickItemEdit}
            objTraducaoHeader={objTraducaoHeader}
            edit={true}
            allowEdit={!!profileHasPermission("Editar Registro")}
          />

          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={qtdeRegistros}
            pageSize={pageSize}
            setPageSize={(value: string) => setPageSize(Number(value))}
            onPageChange={(page: number) => setCurrentPage(Number(page))}
            showLabel={false}
          />
        </>
      )}
    </Section>
  );
};

export default ConsultaOS;
