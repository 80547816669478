import { useContext, useEffect, useMemo, useState } from "react";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import { Body, ButtonsBox, FlexTopoAcoes, Section } from "./mensagensInsucesso_style";
import Header from "../../../components/header/header";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import StickyTable from "../../../components/stickyTable/stickyTable";
import Pagination from "../../../components/pagination/paginacao";
import { IGetMotivoContratante, IGetMotivoContratanteItem, IGetMotivoContratanteResponse } from "../types/types";
import { getListaMotivoContratante, putMotivoContratante } from "../services/services";
import { AxiosResponse } from "axios";
import Button from '../../../components/Button/styles';
import YesOrNoModal from '../../../components/yesOrNoModal/yesOrNoModal';
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import { getContratantesNovo } from "../../farol/services";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import { IContratantesDTO } from "../../contratante/types";
import { IMotivosCancelamento } from "../../parametroInsucesso/types";
import { getMotivosCancelamentoVinculadosContratante } from "../../parametroInsucesso/services/services";
import _ from "lodash";
import { createErrorMsg, createSuccessMsg } from "../../../services/api";
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";

const MensagensInsucesso = () => {
    const { setMenuLateral } = useContext(HomeContext);
    const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);

    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Mensagens de Insucesso", funcionalidade);
    const navigate = useNavigate();

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
    const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);

    const [disableActions, setDisableActions] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const [dadosConsultaMensagemInsucesso, setDadosConsultaMensagemInsucesso] = useState<IGetMotivoContratanteItem[]>([]);
    const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [yesOrNoModalOpen, setYesOrNoModalOpen] = useState(false);
    const [tempItemToDelete, setTempItemToDelete] = useState<IGetMotivoContratanteItem | null>(null);

    const [listaContratante, setListaContratante] = useState<IAutocompleteValue>([]);
    const [listaMotivos, setListaMotivos] = useState<any[]>([]);
    const [filtroContratante, setFiltroContratante] = useState<any[]>([]);
    const [filtroMotivoInsucesso, setFiltroMotivoInsucesso] = useState<any[]>([]);

    const handleButtonSearch = () => {
        if (currentPage !== 1) {
            setCurrentPage(1);
        } else {
            handleSearch();
        }
    };

    const handleSearch = async () => {
        setLoading(true);
        setDisableActions(true);

        const params: IGetMotivoContratante = {
            ...(!!filtroContratante[0] ? { idContratante: Number(filtroContratante[0]?.id) } : {}),
            ...(!!filtroMotivoInsucesso[0] ? { idMotivoCancelamento: Number(filtroMotivoInsucesso[0]?.id) } : {}),
            // pageNumber: currentPage,
            // pageSize: pageSize
        }

        try {
            const { data }: { data: IGetMotivoContratanteResponse } = await getListaMotivoContratante(params) as AxiosResponse;

            const dados = (data?.items ?? []);
            setLoading(false);
            setDisableActions(false);
            setDadosConsultaMensagemInsucesso(dados);
            setQtdeRegistros(dados.length);
        } catch (error) {
            setLoading(false);
            setDisableActions(false);
            setDadosConsultaMensagemInsucesso([]);
            setQtdeRegistros(0);
            setTipoMsgAlert("error");
            setMsgAlert(createErrorMsg(error, "Nenhum dado encontrado"));
            setOpenAlert(true);
        }
    }

    const paginacaoLocal = (dados: any[]) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return dados.slice(firstPageIndex, lastPageIndex);
    };

    const dadosFormatados = useMemo(() => {
        const dados: typeof objTraducaoHeader = [...dadosConsultaMensagemInsucesso].map(item => {
            return {
                id: item.id,
                contratante: item.contratante,
                descricao: item.descricaoMotivoInsucesso,
                legendaFoto: item.legendaFoto,
                quantidadeFoto: item.quantidadeFoto,
                exigeDadosCliente: item.exigeDadosCliente === null ? "" : (!!item.exigeDadosCliente ? "Sim" : "Não"),
            };
        });

        return paginacaoLocal(dados);
    }, [dadosConsultaMensagemInsucesso, currentPage, pageSize]);

    const objTraducaoHeader = {
        id: "ID registro",
        contratante: "Contratante",
        descricao: "Motivo insucesso",
        legendaFoto: "Mensagem da Foto",
        quantidadeFoto: "Quantidade de Fotos",
        exigeDadosCliente: "Exige Assinatura do Cliente",
    } as any;

    const getItemByID = (ID: number | string) => {
        let resultadoDados = [...dadosConsultaMensagemInsucesso];

        return resultadoDados.find(
            (item) => item.id === ID
        )!;
    };

    const handleClickItemEdit = (data: any) => {
        navigate("/IncluirMensagemInsucesso", {
            state: {
                registro: getItemByID(data.id),
                rota: {
                    url: window.location.pathname,
                    name: "Consultar Fluxo de Trabalho",
                },
            }
        });
    };

    const handleClickDeleteItem = (item: typeof objTraducaoHeader) => {
        setYesOrNoModalOpen(true);
        setTempItemToDelete(getItemByID(item.id));
    };

    const handleCloseYesOrNoModal = () => {
        setYesOrNoModalOpen(false);
        setTempItemToDelete(null);
    };

    const handleConfirmDeleteItem = async () => {
        setLoading(true);

        try {
            const itemToDelete = tempItemToDelete as IGetMotivoContratanteItem;

            const { data } = await putMotivoContratante(
                itemToDelete.id,
                {
                    id: itemToDelete.id,
                    exigeDadosCliente: null,
                    legendaFoto: null,
                    quantidadeFoto: null
                }
            ) as AxiosResponse;

            setLoading(false);
            setDisableActions(false);
            setTipoMsgAlert("success");
            setMsgAlert(createSuccessMsg(data, "Dados removidos com sucesso!"));
            setOpenAlert(true);

            const dadosFiltrados = [...dadosConsultaMensagemInsucesso].map(motivo => {
                let motive = { ...motivo };
                if (motivo.id === itemToDelete.id) {
                    motive = {
                        ...motive,
                        exigeDadosCliente: null,
                        legendaFoto: null,
                        quantidadeFoto: null
                    }
                }

                return motive;
            });
            setDadosConsultaMensagemInsucesso(dadosFiltrados);
        } catch (error) {
            setLoading(false);
            setDisableActions(false);
            setTipoMsgAlert("error");
            setMsgAlert(createErrorMsg(error, "Não foi possível atualizar os dados no momento!"));
            setOpenAlert(true);
        }

        setYesOrNoModalOpen(false);
        setTempItemToDelete(null);
    };

    const localGetContratantes = async () => {
        return await getContratantesNovo()
            .then((res: any) => {
                const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;

                if (respSuccess) {
                    const listaContratante = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id?.toString() ?? "", idOppay: item.id_Oppay?.toString() ?? "" } }) || [];
                    setListaContratante(filtraContratantesDoUsuario([...[], ...listaContratante]));
                };

            }, err => {
                const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
                if (respErr) setListaContratante([]);
            });
    };

    const filtraContratantesDoUsuario = (contratantes: IAutocompleteValue) => {
        let options = [...contratantes];

        if (!!user?.contratanteOrdemServico) {
            options = options.filter((o: any) => Number(o.idOppay) === user.contratanteOrdemServico);

            setFiltroContratante(options);
        }

        return options;
    };

    const motivosCancelamentoContratante = async () => {
        try {
            const { data }: { data: IMotivosCancelamento[] } = await getMotivosCancelamentoVinculadosContratante(Number(filtroContratante[0].id)) as AxiosResponse
            setListaMotivos(_.sortBy(data?.filter(i => !!i.status).map(item => ({ id: item?.id, name: item?.descricao, ativo: item?.status })), ["name"]));

        } catch (e) {
            setListaMotivos([]);
        }
    };

    useEffect(() => {
        localGetContratantes();
    }, []);

    useEffect(() => {
        if (!!filtroContratante.length) {
            motivosCancelamentoContratante();
        }
        setFiltroMotivoInsucesso([]);
        setListaMotivos([]);
    }, [filtroContratante]);

    return (
        <Section>
            <Header
                setMenuLateral={() => setMenuLateral(true)}
                title="Parametrização de mensagens de insucesso"
            />

            <FlexTopoAcoes>
                <Breadcrumb>
                    <BreadcrumbItem>Parametrização de mensagens de insucesso</BreadcrumbItem>
                </Breadcrumb>

                <Voltar onClick={() => navigate(-1)} className='botaoVoltar' />
            </FlexTopoAcoes>

            {!!yesOrNoModalOpen && (
                <YesOrNoModal
                    title={
                        <div style={{ fontWeight: 400 }}>
                            Deseja confirmar a exclusão dos campos?<br />
                            <strong>Mensagem da Foto, Quantidade de Fotos e Exige Assinatura do Cliente</strong>
                            <br />
                            <br />
                            <div style={{ fontStyle: "italic" }}>(Isso não removerá o vínculo do motivo ao contratante)</div>
                        </div>
                    }
                    isModalOpen={yesOrNoModalOpen}
                    handleClose={handleCloseYesOrNoModal}
                    handleClickYes={handleConfirmDeleteItem}
                />
            )}

            <Body>
                <div className="grid1">
                    <AutocompleteMultiple
                        label={"Contratante"}
                        placeholder={""}
                        noOptionsText={"Nenhum contratante encontrado"}
                        id="multiple-checkbox-contratante-Estoque"
                        options={listaContratante}
                        value={filtroContratante}
                        onChange={setFiltroContratante}
                        disabled={!!user?.contratanteOrdemServico}
                        multiple={false}
                        dimension='sm'
                        limitTags={2}
                        showCheckbox
                    />

                    <AutocompleteMultiple
                        label={"Motivo Insucesso"}
                        placeholder={""}
                        noOptionsText={!!filtroContratante.length ? "Nenhum motivo de insucesso encontrado" : "Selecione um contratante"}
                        id="multiple-checkbox-motivoDescricao"
                        options={listaMotivos}
                        value={filtroMotivoInsucesso}
                        onChange={setFiltroMotivoInsucesso}
                        multiple={false}
                        dimension='sm'
                        limitTags={2}
                        showCheckbox
                    />

                    <ButtonsBox>
                        <Button
                            type="button"
                            variant="primary"
                            dimension="sm"
                            margin='0px 0px 0px 0px'
                            onClick={handleButtonSearch}
                        >
                            Buscar
                        </Button>
                        {!!profileHasPermission("Atualiza Vinculo Contratante") ?
                            <Button
                                type="button"
                                variant="primary"
                                dimension="sm"
                                margin='0px 0px 0px 0px'
                                padding='12.5px 5px'
                                onClick={() => navigate("/IncluirMensagemInsucesso")}
                                disabled={disableActions}
                            >
                                + Cadastrar mensagem
                            </Button>
                            :
                            <Button
                                type="button"
                                variant="primary"
                                dimension="sm"
                                margin='0px 0px 0px 0px'
                                padding='12.5px 5px'
                                disabled={true}
                            >
                                + Cadastrar mensagem
                            </Button>
                        }
                    </ButtonsBox>
                </div>
            </Body>

            <AlertMessage
                isOpenAlert={openAlert}
                setOpenAlert={setOpenAlert}
                alertType={tipomsgAlert}
                msgAlert={msgAlert}
            />

            {!!loading && <FullscreenLoading />}

            {!!dadosConsultaMensagemInsucesso.length && (
                <>
                    <StickyTable
                        id={"TabelaResultadoConsultaMensagemInsucesso"}
                        data={dadosFormatados}
                        handleClickEdit={handleClickItemEdit}
                        objTraducaoHeader={objTraducaoHeader}
                        allowEdit={!disableActions && !!profileHasPermission("Atualiza Vinculo Contratante")}
                        edit={true}
                        labelEdit={"Detalhes"}
                        deleteColum={true}
                        allowDelete={!disableActions && !!profileHasPermission("Atualiza Vinculo Contratante")}
                        handleClickDelete={handleClickDeleteItem}
                    />

                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={qtdeRegistros}
                        pageSize={pageSize}
                        setPageSize={(value: string) => setPageSize(Number(value))}
                        onPageChange={(page: number) => setCurrentPage(Number(page))}
                        showLabel={false}
                    />
                </>
            )}
        </Section>
    );
};

export default MensagensInsucesso;
