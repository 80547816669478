import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../../contexts/auth";
import { HomeContext } from "../../../contexts/homeContext";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/header/header";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import { useFormik } from "formik";
import * as yup from "yup";
import { AxiosResponse } from "axios";
import { getListaMotivoContratante, putMotivoContratante } from "../services/services";
import { IGetMotivoContratanteItem, IGetMotivoContratanteResponse } from "../types/types";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";
import Input, { TextArea } from "../../../components/Input/Input";
import Button from "../../../components/Button/styles";
import "./incluirEditarMensagemInsucesso.scss";
import { getContratantesNovo } from "../../farol/services";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import { IContratantesDTO } from "../../contratante/types";
import { IMotivosCancelamento } from "../../parametroInsucesso/types";
import { getMotivosCancelamentoVinculadosContratante } from "../../parametroInsucesso/services/services";
import _ from "lodash";
import Checkbox from "../../../components/checkbox/checkbox";
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";
import { FlexTopoAcoes } from "./mensagensInsucesso_style";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import { createErrorMsg, createSuccessMsg } from "../../../services/api";

interface IInitialValues {
    contratante: IAutocompleteValue,
    motivoInsucesso: IAutocompleteValue,
    exigeDadosCliente: boolean,
    quantidadeFoto: string,
    legendaFoto: string,
};

const IncluirEditarMensagemInsucesso = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const registro: IGetMotivoContratanteItem | null = location.state?.registro ?? null;

    const { funcionalidadeDaTelaTemPermissao, user } = useContext(AuthContext);

    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Incluir Mensagens de Insucesso", funcionalidade);
    const { setMenuLateral } = useContext(HomeContext);

    const [listaContratante, setListaContratante] = useState<any[]>([]);
    const [listaMotivos, setListaMotivos] = useState<any[]>([]);

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
    const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);
    const [disableActions, setDisableActions] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [dadosConsultaMensagemInsucesso, setDadosConsultaMensagemInsucesso] = useState<IGetMotivoContratanteItem[]>([]);
    const [registroAtual, setRegistroAtual] = useState<IGetMotivoContratanteItem | null>(null);

    const validationSchema = yup.object({
        contratante: yup.array().max(1).min(1, 'Campo obrigatório'),
        motivoInsucesso: yup.array().max(1).min(1, 'Campo obrigatório'),
        exigeDadosCliente: yup.bool().required('Campo obrigatório'),
        quantidadeFoto: yup.string().required('Campo obrigatório'),
        legendaFoto: yup.string().required('Campo obrigatório'),
    });

    const initialValues: IInitialValues = useMemo(() => ({
        contratante: !!registroAtual ? listaContratante.filter(c => c.id === String(registroAtual?.idContratante)) : [],
        motivoInsucesso: !!registroAtual ? listaMotivos.filter(m => m.id === String(registroAtual?.idMotivoInsucesso)) : [],
        exigeDadosCliente: !!registroAtual ? !!registroAtual.exigeDadosCliente : false,
        quantidadeFoto: !!registroAtual && registroAtual?.quantidadeFoto !== null ? String(registroAtual?.quantidadeFoto) : "",
        legendaFoto: !!registroAtual && registroAtual?.legendaFoto !== null ? String(registroAtual?.legendaFoto) : "",
    }), [registroAtual]);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (!!profileHasPermission("Atualiza Vinculo Contratante")) return handleIncludeMensagemInsucesso(values);
        }
    });

    const handleSearchMotivoContratante = async () => {
        try {
            const { data }: { data: IGetMotivoContratanteResponse } = await getListaMotivoContratante({}) as AxiosResponse;
            const dados = (data?.items ?? []);
            setDadosConsultaMensagemInsucesso(dados);
        } catch (_) { }
    }

    const handleIncludeMensagemInsucesso = async (values: IInitialValues) => {
        setLoading(true);
        setDisableActions(true);

        try {
            const itemToUpdate = registroAtual as IGetMotivoContratanteItem;

            const { data } = await putMotivoContratante(
                itemToUpdate.id,
                {
                    id: itemToUpdate.id,
                    exigeDadosCliente: values.exigeDadosCliente,
                    legendaFoto: values.legendaFoto,
                    quantidadeFoto: Number(values.quantidadeFoto)
                }
            ) as AxiosResponse;

            setLoading(false);
            setDisableActions(false);
            setTipoMsgAlert("success");
            setMsgAlert(createSuccessMsg(data, "Dados cadastrados com sucesso!"));
            setOpenAlert(true);

            const dadosFiltrados = [...dadosConsultaMensagemInsucesso].map(motivo => {
                let motive = { ...motivo };
                if (motivo.id === itemToUpdate.id) {
                    motive = {
                        ...motive,
                        exigeDadosCliente: values.exigeDadosCliente,
                        legendaFoto: values.legendaFoto,
                        quantidadeFoto: Number(values.quantidadeFoto)
                    }
                }

                return motive;
            });
            setDadosConsultaMensagemInsucesso(dadosFiltrados);
        } catch (error) {
            setLoading(false);
            setDisableActions(false);
            setTipoMsgAlert("error");
            setMsgAlert(createErrorMsg(error, "Não foi possível cadastrar os dados no momento!"));
            setOpenAlert(true);
        }
    };

    const localGetContratantes = async () => {
        return await getContratantesNovo()
            .then((res: any) => {
                const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;

                if (respSuccess) {
                    const listaContratante = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id?.toString() ?? "", idOppay: item.id_Oppay?.toString() ?? "" } }) || [];
                    setListaContratante(filtraContratantesDoUsuario([...[], ...listaContratante]));
                };

            }, err => {
                const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
                if (respErr) setListaContratante([]);
            });
    };

    const filtraContratantesDoUsuario = (contratantes: IAutocompleteValue) => {
        let options = [...contratantes];

        if (!!user?.contratanteOrdemServico) {
            options = options.filter((o: any) => Number(o.idOppay) === user.contratanteOrdemServico);

            formik.setFieldValue('contratante', options);
        }

        return options;
    };

    const motivosCancelamentoContratante = async (idContratante?: number, registro?: IGetMotivoContratanteItem) => {
        if (!!registro) setLoading(true);

        try {
            const { data }: { data: IMotivosCancelamento[] } = await getMotivosCancelamentoVinculadosContratante(idContratante ?? Number(formik.values.contratante?.[0]?.id)) as AxiosResponse
            const listaMotivoTratada = _.sortBy(data?.filter(i => !!i.status).map(item => ({ id: item?.id?.toString(), name: item?.descricao, ativo: item?.status })), ["name"]);
            setListaMotivos(listaMotivoTratada);
            
            if (!!registro) {
                setTimeout(() => {
                    setRegistroAtual(registro);
                    setLoading(false);
                }, 600);
            } else {
                setLoading(false);
            }
        } catch (e) {
            setListaMotivos([]);
            setLoading(false);
        }
    };

    const handleChangeContratante = (contratantes: any[]) => {
        formik.setFieldValue('contratante', contratantes);

        if (!!contratantes.length) {
            motivosCancelamentoContratante(Number(contratantes[0].id));
        } else {
            setListaMotivos([]);
        }

        formik.setFieldValue('motivoInsucesso', []);
    };

    useEffect(() => {
        handleSearchMotivoContratante();
        localGetContratantes();
    }, []);
    
    useEffect(() => {
        if (!!registro) motivosCancelamentoContratante(registro?.idContratante, registro);
    }, [registro]);

    useEffect(() => {
        if (!!formik.values.contratante.length && !!formik.values.motivoInsucesso.length) {
            const newRegister = [...dadosConsultaMensagemInsucesso].find(reg => Number(formik.values.contratante[0].id) === reg.idContratante && Number(formik.values.motivoInsucesso[0].id) === reg.idMotivoInsucesso);
            if (!!newRegister) {
                setRegistroAtual(newRegister);
            } else {
                setRegistroAtual(null);
            }
        }
    }, [formik.values.motivoInsucesso]);

    return (
        <section className="IncluirEditarMensagemInsucesso">
            <Header setMenuLateral={() => setMenuLateral(true)} title={'Cadastro de mensagens de insucesso'} />

            <FlexTopoAcoes>
                <Breadcrumb>
                    <BreadcrumbItem onClick={() => navigate("/MensagensInsucesso")}>Parametrização de mensagens de insucesso</BreadcrumbItem>
                    <BreadcrumbItem>{'Cadastro de mensagens de insucesso'}</BreadcrumbItem>
                </Breadcrumb>

                <Voltar onClick={() => navigate(-1)} className='botaoVoltar' />
            </FlexTopoAcoes>

            <form className="Container" onSubmit={formik.handleSubmit}>
                <div className="IncluirMensagemInsucesso">
                    <div className="firstRow grid">
                        <AutocompleteMultiple
                            disableClearable
                            dimension="sm"
                            label={"Contratante"}
                            placeholder={""}
                            noOptionsText="Nenhum contratante encontrado"
                            options={listaContratante}
                            value={formik.values.contratante}
                            onChange={handleChangeContratante}
                            id="multiple-checkbox-contratante"
                            multiple={false}
                            error={formik.touched.contratante && Boolean(formik.errors.contratante)}
                            helperText={formik.touched.contratante && formik.errors.contratante}
                            disabled={disableActions}
                            showCheckbox
                        />

                        <AutocompleteMultiple
                            disableClearable
                            dimension="sm"
                            label={"Motivo de Insucesso"}
                            placeholder={""}
                            noOptionsText={!!formik.values.contratante.length ? "Nenhum motivo de insucesso encontrado" : "Selecione um contratante"}
                            options={listaMotivos}
                            value={formik.values.motivoInsucesso}
                            onChange={(e: any) => formik.setFieldValue('motivoInsucesso', e)}
                            id="multiple-checkbox-motivoInsucesso"
                            multiple={false}
                            error={formik.touched.motivoInsucesso && Boolean(formik.errors.motivoInsucesso)}
                            helperText={formik.touched.motivoInsucesso && formik.errors.motivoInsucesso}
                            disabled={disableActions}
                            showCheckbox
                        />
                    </div>

                    <div className="secondRow grid">
                        <Checkbox
                            label="Exige Assinatura do Cliente"
                            value={formik.values.exigeDadosCliente}
                            onChange={() => formik.setFieldValue("exigeDadosCliente", !formik.values.exigeDadosCliente)}
                            disabled={disableActions}
                            error={formik.touched.exigeDadosCliente && Boolean(formik.errors.exigeDadosCliente)}
                            helperText={formik.touched.exigeDadosCliente && formik.errors.exigeDadosCliente}
                        />

                        <Input
                            dimension="sm"
                            label='Quantidade de Fotos'
                            name='quantidadeFoto'
                            id='quantidadeFoto'
                            value={formik.values.quantidadeFoto}
                            onChange={(e) => formik.setFieldValue("quantidadeFoto", e.target.value?.replace(/\D/g, ""))}
                            error={formik.touched.quantidadeFoto && Boolean(formik.errors.quantidadeFoto)}
                            helperText={formik.touched.quantidadeFoto && formik.errors.quantidadeFoto}
                            maxLength={2}
                            disabled={disableActions}
                        />
                    </div>

                    <div className="thirdRow grid">
                        <TextArea
                            dimension="sm"
                            label='Mensagem da Foto'
                            name='legendaFoto'
                            id='legendaFoto'
                            value={formik.values.legendaFoto}
                            onChange={formik.handleChange}
                            error={formik.touched.legendaFoto && Boolean(formik.errors.legendaFoto)}
                            helperText={formik.touched.legendaFoto && formik.errors.legendaFoto}
                            maxLength={100}
                            disabled={disableActions}
                        />
                    </div>

                    <div className="gridBotoes grid">
                        <div />

                        <Button
                            type="button"
                            variant='grey'
                            dimension='sm'
                            onClick={() => navigate(-1)}
                            disabled={disableActions}
                        >
                            Voltar
                        </Button>

                        {!!profileHasPermission("Atualiza Vinculo Contratante") ?
                            <Button
                                type="submit"
                                variant='primary'
                                dimension='sm'
                                disabled={disableActions}
                            >
                                Salvar
                            </Button>
                            :
                            <Button
                                type="button"
                                variant='primary'
                                dimension='sm'
                                disabled={true}>
                                Salvar
                            </Button>
                        }
                    </div>
                </div>

                {!!loading && <FullscreenLoading />}

                <AlertMessage
                    isOpenAlert={openAlert}
                    setOpenAlert={setOpenAlert}
                    alertType={tipomsgAlert}
                    msgAlert={msgAlert}
                />
            </form>
        </section>
    );
};

export default IncluirEditarMensagemInsucesso;
