import React, { useState, useEffect, createContext, useContext } from "react";
import _ from "lodash";
import { getListaOSs, IPostGetListaOSFarol } from "../pages/home/services/services";
import { VisualizacaoOSContext } from "./visualizacaoOSContext";
import { DashboardContext } from "./dashboardContext";
import { FarolContext } from "./farolContext";
import { EncaminharOSContext } from "./encaminharOSContext";
import { getPrestadorPorId } from "../pages/farol/services";
import { AxiosResponse } from "axios";
import { IOSPendenteContext } from "./types/osPendenteTypes";
import { OSPendenteContext } from './osPendenteContext'
import { IHomeContext, IPrestador, IHomeProvider } from './types/homeTypes'
import { IOrdemServico } from './types/farolTypes'
import { ConsultaOMContext } from "./consultaOMContext";
import { PerfilContext } from "./perfilContext";
import { FamiliaContext } from "./familiaContext";
import { TipoEquipamentoContext } from "./tipoEquipamentoContext";
import { ModeloContext } from "./modeloContext";
import { AuthContext } from "./auth";
import { PrestadorContext } from "./prestadorContext";
import { TecnicoContext } from "./tecnicoContext";
import { GrupoServicoContext } from "./grupoServicoContext";
import { PrestadorResponsavelContext } from "./prestadorResponsavelContext";
import { ServicoContext } from "./servicosContext";
import { createStatusAndPriorityOS } from "../helpers/statusAndPriorityOS";
import { RegiaoContext } from "./regioesContext";
import { OrdemServicoStatusContext } from "./ordemServicoStatusContext"
import { CidadeContext } from "./cidadesContext";
import { EstadoContext } from "./estadosContext";
import { ContratanteServicoParametroContext } from "./parametroContext"
import { ChecklistContext } from "./checklistContext";
import { JustificativaAbonoContext } from "./justificativaAbonoContext";
import { ContratanteContext } from "./contratanteContext";


export const HomeContext = createContext<IHomeContext>({} as IHomeContext);

export const HomeProvider = ({ children }: IHomeProvider) => {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [loadingFarol, setLoadingFarol] = useState(false);
  const [getDone, setGetDone] = useState(false);
  const [listaosDefault, setListaosDefault] = useState([]);
  const [listaos, setListaos] = useState<IOrdemServico[]>([] as IOrdemServico[]);
  const [prestador, setPrestador] = useState<IPrestador>({} as IPrestador)
  const [totalCount, setTotalCount] = useState(0)
  const [totalEmergencial, setTotalEmergencial] = useState(0)
  const [totalVencidas, setTotalVencidas] = useState(0)
  const [totalVenceHoje, setTotalVenceHoje] = useState(0)
  const [totalAVencer, setTotalAVencer] = useState(0)
  const [filtroFarol, setFiltroFarol] = useState("0");
  const [menuLateral, setMenuLateral] = useState(false)

  useEffect(() => {
    setLoading(false);
  }, []);

  const requestGetListaOSs = async (params: IPostGetListaOSFarol) => {
    setLoadingFarol(true);
    let listaos: IOrdemServico[] = [];

    try {
      const { data } = await getListaOSs(params) as AxiosResponse;

      if (data?.success) {
        /*Ajusta dados de OS para o Farol*/
        listaos = data.data.items.map(createStatusAndPriorityOS);
      }

      setTotalCount(data?.data?.totalCount || 0);
      setTotalEmergencial(data?.data?.totalEmergencial || 0);
      setTotalAVencer(data?.data?.totalParaVencer || 0);
      setTotalVenceHoje(data?.data?.totalVenceHoje || 0);
      setTotalVencidas(data?.data?.totalVencidas || 0);
      setListaosDefault(data?.data?.items || []);
      setListaos(listaos);
      setLoadingFarol(false);

    } catch (e: any) {

      if (params?.tipoSLA === 0) {
        setTotalCount(0);
        setTotalEmergencial(0);
        setTotalAVencer(0);
        setTotalVenceHoje(0);
        setTotalVencidas(0);
      }

      setListaosDefault([]);
      setListaos([]);
      setLoadingFarol(false);
    }
  };

  const requestGetPrestadorById = async (prestadorId: number) => {
    const { data } = await getPrestadorPorId(prestadorId) as AxiosResponse;

    if (!!data) {
      setPrestador(data);
    };
  };

  return (
    <HomeContext.Provider
      value={{
        requestGetListaOSs,
        loading,
        loadingFarol,
        setLoadingFarol,
        getDone,
        menuLateral,
        totalCount,
        totalEmergencial,
        totalVencidas,
        totalVenceHoje,
        totalAVencer,
        filtroFarol,
        prestador,
        listaos,
        setListaos,
        requestGetPrestadorById,
        setFiltroFarol,
        setMenuLateral,
        ...VisualizacaoOSContext(
          listaos,
          totalCount,
          totalEmergencial,
          totalVencidas,
          totalVenceHoje,
          totalAVencer,
        ),
        ...DashboardContext(listaos, filtroFarol),
        ...FarolContext(listaos, filtroFarol, user),
        ...EncaminharOSContext(listaos),
        ...OSPendenteContext(),
        ...ConsultaOMContext(),
        ...PerfilContext(),
        ...FamiliaContext(),
        ...TipoEquipamentoContext(),
        ...ModeloContext(),
        ...PrestadorContext(),
        ...TecnicoContext(),
        ...GrupoServicoContext(),
        ...PrestadorResponsavelContext(),
        ...ServicoContext(),
        ...CidadeContext(),
        ...OrdemServicoStatusContext(),
        ...RegiaoContext(),
        ...EstadoContext(),
        ...ContratanteServicoParametroContext(),
		    ...ChecklistContext(),
        ...JustificativaAbonoContext(),
        ...ContratanteContext()
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
