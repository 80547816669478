import React, { useContext, useEffect, useState } from "react";
import Header from "../../../components/header/header";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import Input, { TextArea } from '../../../components/Input/Input';
import moment from 'moment';
import Button from '../../../components/Button/styles';
import _ from 'lodash';
import { useFormik } from "formik";
import * as yup from "yup";
import "./ajusteMassivoOS.scss";
import { AxiosResponse } from "axios";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import { ITecnicos } from "../../../contexts/types/encaminharTypes";
import DateTimePicker from "../../../components/dateTimePicker/dateTimePicker";
import { ReactComponent as Voltar } from "../../../assets/icons/Voltar.svg";
import YesOrNoModal from "../../../components/yesOrNoModal/yesOrNoModal";
import { putOSMassivo } from "../services/services";
import { createErrorMsg, createSuccessMsg } from "../../../services/api";
import {
  AddressValidationError,
  IPutOSMassivo,
  OrdemServico2,
  OrdemServicoContratante,
} from "../types";
import { IPostConsultarOSsResponseOS } from "../../consultaOS/types";
import {
  getListaTecnicosByPrestadorOrdemServico,
  getPrestadoresAtivos,
} from "../../farol/services";
import { IGetResponsePrestadoresOrdemServico } from "../../farol/types";
import { getMotivosCancelamento } from "../../parametroInsucesso/services/services";
import { IMotivosCancelamento } from "../../parametroInsucesso/types";
import EnderecoTable from "./enderecoTable";

interface IInitialValues {
  status: IAutocompleteValue,
  prestador: IAutocompleteValue,
  tecnico: IAutocompleteValue,
  dataLimite: null,
  dataAgendamento: null,
  motivoSemSucesso: string,
  motivoRetencao: string,
  dataAtendimento: null,
  motivoDescricao: IAutocompleteValue,
  observacao: string,
};

const AjusteMassivoOS = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const osList: IPostConsultarOSsResponseOS[] = location.state?.osList ?? [];

  const { funcionalidadeDaTelaTemPermissao, user } = useContext(AuthContext);
  const [editingAddressId, setEditingAddressId] = useState<number | null>(null);
  const profileHasPermission = (funcionalidade: string) =>
    funcionalidadeDaTelaTemPermissao("Ajuste Massivo OS", funcionalidade);

  const { setMenuLateral, listaStatus } = useContext(HomeContext);

  const [listaOS, setListaOS] = useState<IPostConsultarOSsResponseOS[]>([]);
  const [listaPrestadores, setListaPrestadores] = useState<any[]>([]);
  const [listaTecnicos, setListaTecnicos] = useState<any[]>([]);
  const [listaMotivos, setListaMotivos] = useState<any[]>([]);

  const [yesOrNoModalOpen, setYesOrNoModalOpen] = useState(false);
  const [tipomsgAlert, setTipoMsgAlert] = useState<
    "success" | "info" | "warning" | "error"
  >("success");
  const [msgAlert, setMsgAlert] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editingAddresses, setEditingAddresses] = useState<boolean>(false);
  const validationSchema = yup.object({
    tecnico: yup
      .array()
      .test(
        "filtrotecnico",
        "Campo obrigatório ao alterar o prestador",
        (newValue, ctx) => {
          if (_.isEmpty(newValue) && !_.isEmpty(ctx?.parent?.prestador ?? []))
            return false;
          return true;
        }
      ),
  });

  const handleSaveAddress = (updatedAddress: any) => {
    const updatedList = listaOS.map(os => ({
      ...os,
      enderecos: os.enderecos.map(end => 
        end.id === updatedAddress.id ? updatedAddress : end
      )
    })) as IPostConsultarOSsResponseOS[];

    setListaOS(updatedList);
    setEditingAddresses(true);
  };

  const initialValues: IInitialValues = {
    status: [],
    prestador: [],
    tecnico: [],
    dataLimite: null,
    dataAgendamento: null,
    motivoSemSucesso: "",
    motivoRetencao: "",
    dataAtendimento: null,
    motivoDescricao: [],
    observacao: ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => setYesOrNoModalOpen(true),
  });

  const handleChangeField = (value: any, field: string) =>
    formik.setFieldValue(field, value);

  const getProvider = async () => {
    try {
      const { data }: { data: IGetResponsePrestadoresOrdemServico } =
        (await getPrestadoresAtivos()) as AxiosResponse;

      let prestadores = [];

      if (!!data?.success && !!data.data?.prestadores?.length) {
        prestadores = data.data.prestadores
          .filter((p: any) => !!p.ativo)
          .map((m: any) => ({
            ...m,
            name: m.nomePrestador,
            id: m.id?.toString(),
            idOppay: m.idOppay?.toString(),
          }))
          .sort((a: any, b: any) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          );
      }

      setListaPrestadores(filtraPrestadoresDoUsuario(prestadores));
    } catch (e) {
      setListaPrestadores([]);
    }
  };

  const filtraPrestadoresDoUsuario = (prestadores: any) => {
    let options: any[] = [...prestadores];

    if (!!user?.prestadores && !!user.prestadores.length) {
      options = options.filter((o: any) =>
        user.prestadores.includes(Number(o.idOppay))
      );
    }

    return options;
  };

  const requestListaTecnicosByLotePrestador = async () => {
    let listAllPrestadores: number[] = [];

    if (!!formik.values.prestador.length) {
      listAllPrestadores = formik.values.prestador.map((p) => parseInt(p.id));
    }

    const tecnicos = (await requestTecnicosByLotePrestador(
      listAllPrestadores
    )) as ITecnicos[];
    setListaTecnicos(
      _.sortBy(
        tecnicos.map((p) => ({
          name: p?.nome ?? "",
          id: p?.idTecnico?.toString() ?? "",
        })),
        ["name"]
      )
    );
  };

  const requestTecnicosByLotePrestador = async (ids: number[]) => {
    try {
      if (!!ids.length) {
        let retorno = (await getListaTecnicosByPrestadorOrdemServico(
          ids
        )) as AxiosResponse;
        return retorno?.data?.data ?? [];
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const motivosCancelamento = async () => {
    try {
      const { data }: { data: IMotivosCancelamento[] } =
        (await getMotivosCancelamento()) as AxiosResponse;
      setListaMotivos(
        _.sortBy(
          data
            ?.filter((i) => !!i.status)
            .map((item) => ({
              id: item?.id,
              name: item?.descricao,
              ativo: item?.status,
            })),
          ["name"]
        )
      );
    } catch (e) {
      setListaMotivos([]);
    }
  };

  const validateAddresses = (params: IPutOSMassivo): AddressValidationError[] => {
    const errors: AddressValidationError[] = [];

      const fieldDisplayNames = {
        cep: 'CEP',
        logradouro: 'Endereço',
        numero: 'Número',
        bairro: 'Bairro'
      };
  
    params.ordensServico.forEach(os => {
      os.enderecos?.forEach(endereco => {
        const requiredFields = {
          cep: endereco.cep,
          logradouro: endereco.logradouro,
          numero: endereco.numero,
          bairro: endereco.bairro
        };
  
        Object.entries(requiredFields).forEach(([field, value]) => {
          if (!value || value.toString().trim() === '') {
            errors.push({
              message: `O Campo ${fieldDisplayNames[field as keyof typeof fieldDisplayNames]} no endereço da OS ${os.chamado} é de preenchimento obrigatório`
            });
          }
        });
  
        if (!endereco.idEstado || endereco.idEstado <= 0) {
          errors.push({
            message: `O Campo Estado no endereço da OS ${os.chamado} é de preenchimento obrigatório`
          });
        }
  
        if (!endereco.idCidade || endereco.idCidade <= 0) {
          errors.push({
            message: `O Campo Cidade no endereço da OS ${os.chamado} é de preenchimento obrigatório`
          });
        }
      });
    });
  
    return errors;
  };


  const handleUpdateDadosOS = async () => {
    setLoading(true);
    setDisableActions(true);

    const paramsOrdemServico: OrdemServico2 = {
      ...(!!formik.values.status[0] ? { idOrdemServicoStatus: Number(formik.values.status[0]?.id) } : {}),
      ...(!!formik.values.prestador[0] ? { idPrestador: Number(formik.values.prestador[0]?.id) } : {}),
      ...(!!formik.values.tecnico[0] ? { idTecnico: Number(formik.values.tecnico[0]?.id) } : {}),
      ...(!!formik.values.dataLimite && moment(formik.values.dataLimite).isValid() ? { dataLimite: moment(formik.values.dataLimite).format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.dataAgendamento && moment(formik.values.dataAgendamento).isValid() ? { dataAgendamento: moment(formik.values.dataAgendamento).format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.dataAtendimento && moment(formik.values.dataAtendimento).isValid() ? { dataAtendimento: moment(formik.values.dataAtendimento).format("YYYY-MM-DDTHH:mm:ss") } : {}),
      ...(!!formik.values.motivoDescricao[0] ? { idMotivoCancelamento: Number(formik.values.motivoDescricao[0]?.id) } : {}),
      ...(!!formik.values.observacao ? { observacao: formik.values.observacao } : {}),
    };

    const paramsOrdemServicoContratante: OrdemServicoContratante = {
      ...(!!formik.values.motivoSemSucesso ? { contratante_Motivo_Sem_Sucesso: formik.values.motivoSemSucesso } : {}),
      ...(!!formik.values.motivoRetencao ? { motivo_Retencao: formik.values.motivoRetencao } : {}),
    };

    const params: IPutOSMassivo = {
      //@ts-ignore
      ordensServico: listaOS.map((os) => ({
        chamado: os.chamado,
        ...(!_.isEmpty(paramsOrdemServico)
          ? { ordemServico: { chamado: os.chamado, ...paramsOrdemServico } }
          : {}),
        ...(!_.isEmpty(paramsOrdemServicoContratante)
          ? {
              ordemServicoContratante: {
                id: os.contratantes?.[0]?.id ?? 0,
                idOrdemServico: os.chamado,
                ...paramsOrdemServicoContratante,
              },
            }
          : {}),
          enderecos: os.enderecos.map((m: any) => ({
            ...m,
            idEndereco: m.id,
          }))
        
        })),
    };

    const validationErrors = validateAddresses(params);

    if (validationErrors.length > 0) {
      setLoading(false);
      setDisableActions(false);
      setOpenAlert(true);
      setMsgAlert(validationErrors.map(error => error.message).join(',\n'));
      setTipoMsgAlert("error");
      return;
    }

    try {
      const { data }: { data: any } = await putOSMassivo(params) as AxiosResponse;

      setLoading(false);
      setDisableActions(false);
      setOpenAlert(true);
      setMsgAlert(createSuccessMsg(data, "Sucesso ao atualizar dados das OSs!"));
      setTipoMsgAlert("success");
      navigate("/ConsultaAjusteMassivoOS");
    } catch (error: any) {
      setLoading(false);
      setDisableActions(false);
      const msg = createErrorMsg(error);
      setOpenAlert(true);
      setMsgAlert(msg ? msg : "Erro ao atualizar dados das OSs!");
      setTipoMsgAlert("error");
    }
  };

  useEffect(() => {
    if (!!osList && !!osList.length) {
      getProvider();
      motivosCancelamento();
      setListaOS(osList);
    } else {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    requestListaTecnicosByLotePrestador();
    formik.setFieldValue("tecnico", []);
  }, [formik.values.prestador]);

  return (
    <>
      <section className="AjusteMassivoOS">
        {loading && <FullscreenLoading />}

        <Header setMenuLateral={() => setMenuLateral(true)} title="Ajuste" />

        <div className="flexTopoAcoes">
          <Breadcrumb>
            <BreadcrumbItem
              onClick={() => navigate("/ConsultaAjusteMassivoOS")}
            >
              Ajuste
            </BreadcrumbItem>
          </Breadcrumb>

          <Voltar onClick={() => navigate(-1)} className="botaoVoltar" />
        </div>

        {!!yesOrNoModalOpen && (
          <YesOrNoModal
            title={"Deseja concluir a edição das OSs?"}
            isModalOpen={yesOrNoModalOpen}
            handleClose={() => setYesOrNoModalOpen(false)}
            handleClickYes={() => {
              setYesOrNoModalOpen(false);
              handleUpdateDadosOS();
            }}
          />
        )}

        <div className="boxGridSearchFields">
          <div className="grid1">
            <AutocompleteMultiple
              label={"Status"}
              placeholder={""}
              noOptionsText={"Nenhum status encontrado"}
              id="multiple-checkbox-Prestadores-Estoque"
              options={listaStatus}
              value={formik.values.status}
              onChange={(value: any) => handleChangeField(value, "status")}
              multiple={false}
              dimension="sm"
              limitTags={2}
              showCheckbox
              allSelected
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
            />

            <AutocompleteMultiple
              label={"Prestador"}
              placeholder={""}
              noOptionsText={"Nenhum prestador encontrado"}
              id="multiple-checkbox-Prestadores"
              options={listaPrestadores}
              value={formik.values.prestador}
              onChange={(value: any) => handleChangeField(value, "prestador")}
              disabled={!!user?.prestadores && user.prestadores.length === 1}
              multiple={false}
              dimension="sm"
              limitTags={2}
              showCheckbox
              allSelected
              error={
                formik.touched.prestador && Boolean(formik.errors.prestador)
              }
              helperText={formik.touched.prestador && formik.errors.prestador}
            />

            <AutocompleteMultiple
              label={"Técnico"}
              placeholder={""}
              noOptionsText={"Nenhum técnico encontrado"}
              id="multiple-checkbox-tecnico"
              options={listaTecnicos}
              value={formik.values.tecnico}
              onChange={(value: any) => handleChangeField(value, "tecnico")}
              disabled={false}
              multiple={false}
              dimension="sm"
              limitTags={2}
              showCheckbox
              allSelected
              error={formik.touched.tecnico && Boolean(formik.errors.tecnico)}
              helperText={formik.touched.tecnico && formik.errors.tecnico}
            />
          </div>

        <div className='grid2'>
          <DateTimePicker
            label={"Data Limite Prestador"}
            id="DatePicker-dataLimite"
            placeholder="Início"
            value={formik.values.dataLimite}
            onChange={(value: any) => handleChangeField(value, 'dataLimite')}
            dimension='sm'
            error={formik.touched.dataLimite && Boolean(formik.errors.dataLimite)}
            helperText={formik.touched.dataLimite && formik.errors.dataLimite} 
          />

            <DateTimePicker
              label={"Data de Agendamento"}
              id="DatePicker-dataAgendamento"
              placeholder="Fim"
              value={formik.values.dataAgendamento}
              onChange={(value: any) =>
                handleChangeField(value, "dataAgendamento")
              }
              dimension="sm"
              error={
                formik.touched.dataAgendamento &&
                Boolean(formik.errors.dataAgendamento)
              }
              helperText={
                formik.touched.dataAgendamento && formik.errors.dataAgendamento
              }
            />

            <DateTimePicker
              label={"Data de Atendimento"}
              id="DatePicker-dataAtendimento"
              placeholder="Fim"
              value={formik.values.dataAtendimento}
              onChange={(value: any) =>
                handleChangeField(value, "dataAtendimento")
              }
              dimension="sm"
              error={
                formik.touched.dataAtendimento &&
                Boolean(formik.errors.dataAtendimento)
              }
              helperText={
                formik.touched.dataAtendimento && formik.errors.dataAtendimento
              }
            />
          </div>

          <div className="grid2">
            <Input
              label={"Motivo Sem Sucesso"}
              placeholder={""}
              id="input-motivoSemSucesso"
              value={formik.values.motivoSemSucesso}
              onChange={(e: any) =>
                handleChangeField(e.target.value, "motivoSemSucesso")
              }
              dimension="sm"
              maxLength={255}
              error={
                formik.touched.motivoSemSucesso &&
                Boolean(formik.errors.motivoSemSucesso)
              }
              helperText={
                formik.touched.motivoSemSucesso &&
                formik.errors.motivoSemSucesso
              }
            />

            <Input
              label={"Motivo Retenção"}
              placeholder={""}
              id="input-motivoRetencao"
              value={formik.values.motivoRetencao}
              onChange={(e: any) =>
                handleChangeField(e.target.value, "motivoRetencao")
              }
              dimension="sm"
              maxLength={255}
              error={
                formik.touched.motivoRetencao &&
                Boolean(formik.errors.motivoRetencao)
              }
              helperText={
                formik.touched.motivoRetencao && formik.errors.motivoRetencao
              }
            />

            <AutocompleteMultiple
              label={"Descrição Motivo"}
              placeholder={""}
              noOptionsText={"Nenhum motivo encontrado"}
              id="multiple-checkbox-motivoDescricao"
              options={listaMotivos}
              value={formik.values.motivoDescricao}
              onChange={(value: any) =>
                handleChangeField(value, "motivoDescricao")
              }
              multiple={false}
              dimension="sm"
              limitTags={2}
              showCheckbox
              allSelected
              error={
                formik.touched.motivoDescricao &&
                Boolean(formik.errors.motivoDescricao)
              }
              helperText={
                formik.touched.motivoDescricao && formik.errors.motivoDescricao
              }
            />
          </div>

        <TextArea
            label={"Observações"}
            placeholder={""}
            id="input-observacao"
            value={formik.values.observacao}
            onChange={(e: any) => handleChangeField(e.target.value, 'observacao')}
            dimension='sm'
            error={formik.touched.observacao && Boolean(formik.errors.observacao)}
            helperText={formik.touched.observacao && formik.errors.observacao}
            className='rowsTextArea'
            rows={5}
          />

        <div className='grid3'>
          <div />

            <Button
              id={"submitButton"}
              dimension="sm"
              variant="primary"
              className="searchButton"
              type="submit"
              disabled={
                (!profileHasPermission("Editar registro") || !formik.dirty) && !editingAddresses
              }
              onClick={() => formik.handleSubmit()}
            >
              Salvar
            </Button>
          </div>
        </div>

        <AlertMessage
          isOpenAlert={openAlert}
          setOpenAlert={setOpenAlert}
          alertType={tipomsgAlert}
          msgAlert={msgAlert}
        />
      </section>
      <div className="address-table-container">
      <EnderecoTable listaOS={listaOS} handleSaveAddress={handleSaveAddress} />
</div>
    </>
  );
};

export default AjusteMassivoOS;
