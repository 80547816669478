import { useContext, useEffect, useState } from "react";
import Header from "../../../components/header/header";
import { AllowanceSwitch, Form, GoBack, Section } from "./styles";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import * as yup from 'yup'
import { CircularProgress, FormControlLabel, FormGroup, Grid, Stack } from "@mui/material";
import Input from "../../../components/Input/Input";
import DateTimePicker from "../../../components/dateTimePicker/dateTimePicker";
import { FormikSubmitHandler } from "../../../types";
import { useFormik } from "formik";
import MultipleSelectCheckmarks from "../../../components/seletorMultiploId/seletorMultiploId";
import Button from "../../../components/Button/styles";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { AuthContext } from "../../../contexts/auth";
import moment from "moment";
import { postJustificativaAbono, putJustificativaAbono } from "../services";
import { AxiosResponse } from "axios";
import { IPostJustificativaAbono, IPutJustificativaAbono } from "../types";
import AlertMessage from "../../../components/AlertMessage/alertMessage";

const registerOrderServiceStatusSchema = yup.object({
    status: yup.boolean().required('O status da ordem de serviço é obrigatório.'),
    tipo: yup.string().required('O tipo é obrigatório.'),
    idOppay: yup.number().required('O id é obrigatório.'),
    descricao: yup.string().required('O descrição da justificativa é obrigatório.').max(100, 'A sua descrição pode ter no máximo 100 caracteres.'),
    idContratante: yup.number(),
    contratante: yup.array(yup.object({
        id: yup.string().required(),
        name: yup.string().required()
    })).compact(v => v.id).required(),
    codigo: yup.string().required('O código é obrigatório.'),
    codigoSap: yup.string().required('O código SAP é obrigatório.'),
    abonadoContratante: yup.string()
        .oneOf(['sim', 'nao'], 'Valor inválido')
        .required('Campo obrigatório'),
    criadoPor: yup.string(),
    alteradoPor: yup.string(),
    criadoEm: yup.mixed().nullable(),
    alteradoEm: yup.mixed().nullable(),
});

type RegisterAllowanceJustificationValues = yup.InferType<typeof registerOrderServiceStatusSchema>;

const REGISTER_ALLOWANCE_JUSTIFICATION_INITIAL_VALUES: RegisterAllowanceJustificationValues = {
    status: false,
    tipo: "",
    idOppay: 0,
    descricao: "",
    contratante: [],
    idContratante: 0,
    codigo: "",
    codigoSap: "",
    abonadoContratante: "nao",
    criadoPor: "",
    criadoEm: null,
    alteradoPor: "",
    alteradoEm: null
};

export function RegisterAllowanceJustificationPage() {
    const { setMenuLateral, listaContratante, localGetContratantes, justificativaAbonoSelecionada, setJustificativaAbonoSelecionada } = useContext(HomeContext);

    const navigate = useNavigate();

    const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
    const isIncluding = window.location.pathname.includes('CadastrarJustificativaAbono');
    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(isIncluding ? "Cadastrar Justificativas de Abono" : "Editar Justificativas de Abono", funcionalidade);

    useEffect(() => {
        if (isIncluding) {
            formik.resetForm();
            setJustificativaAbonoSelecionada(null);
        }
    }, [isIncluding]);

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
    const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);
    const [disableActions, setDisableActions] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (justificativaAbonoSelecionada) {
            formik.setValues({
                status: justificativaAbonoSelecionada.status || false,
                tipo: justificativaAbonoSelecionada.tipo?.toLocaleLowerCase() || "",
                idOppay: justificativaAbonoSelecionada?.idOppay || 0,
                descricao: justificativaAbonoSelecionada.descricao || "",
                contratante: justificativaAbonoSelecionada.idContratante
                    ?
                    [{
                        id: justificativaAbonoSelecionada.idContratante.toString(),
                        name: justificativaAbonoSelecionada.contratante[0].name
                    }] : [],
                idContratante: justificativaAbonoSelecionada.idContratante || 0,
                codigo: justificativaAbonoSelecionada.codigo || "",
                codigoSap: justificativaAbonoSelecionada.codigoSap || "",
                abonadoContratante: justificativaAbonoSelecionada.abonadoContratante ? 'sim' : 'nao',
                criadoPor: justificativaAbonoSelecionada.usuarioCriacao || "",
                alteradoPor: justificativaAbonoSelecionada.usuarioModificacao || "",
                criadoEm: justificativaAbonoSelecionada.dataCriacao ? moment(justificativaAbonoSelecionada.dataCriacao) : null,
                alteradoEm: justificativaAbonoSelecionada.dataModificacao ? moment(justificativaAbonoSelecionada.dataModificacao) : null,
            })
        }
    }, [justificativaAbonoSelecionada]);

    const initialValuesEditar = {
        status: justificativaAbonoSelecionada?.status || false,
        tipo: justificativaAbonoSelecionada?.tipo?.toLocaleLowerCase() || "",
        idOppay: justificativaAbonoSelecionada?.id || 0,
        descricao: justificativaAbonoSelecionada?.descricao || "",
        contratante: justificativaAbonoSelecionada?.contratante || [],
        idContratante: justificativaAbonoSelecionada?.idContratante || 0,
        codigo: justificativaAbonoSelecionada?.codigo || "",
        codigoSap: justificativaAbonoSelecionada?.codigoSap || "",
        abonadoContratante: justificativaAbonoSelecionada?.abonadoContratante === false ? 'nao' : 'sim',
        criadoPor: justificativaAbonoSelecionada?.usuarioCriacao || "",
        criadoEm: null,
        alteradoPor: justificativaAbonoSelecionada?.usuarioModificacao || "",
        alteradoEm: null
    }

    const handleFormikSubmit: FormikSubmitHandler<RegisterAllowanceJustificationValues> = async (values, actions) => {
        await new Promise((resolve) => setTimeout(resolve, 5000))
        setDisableActions(true);
        setOpenAlert(false);
        setLoading(true);

        try {

            if (isIncluding) {
                const novoRegistro: IPostJustificativaAbono = {
                    tipo: values.tipo.toUpperCase(), // Convertendo para maiúsculo para manter padrão
                    descricao: values.descricao,
                    idContrante: Number(values.contratante[0]?.id || 0),
                    abonadoContratante: values.abonadoContratante === 'sim',
                    codigo: values.codigo,
                    codigoSap: values.codigoSap,
                    idOppay: values.idOppay // Valor padrão para novo registro
                };

                const { data } = await postJustificativaAbono(novoRegistro) as AxiosResponse;
                setLoading(false);
                setMsgAlert(!!data && typeof data === "string" ? data : "Justificativa de Abono cadastrada com sucesso!");
                setTipoMsgAlert("success");
                setOpenAlert(true);
                setDisableActions(false);
            } else {
                // Comparando valores atuais com os valores originais
                const modificacoes: Partial<IPutJustificativaAbono> = {};

                // Verifica cada campo e só adiciona se foi modificado
                if (values.status !== justificativaAbonoSelecionada.status) {
                    modificacoes.status = values.status;
                }

                if (values.tipo !== justificativaAbonoSelecionada.tipo.toLowerCase()) {
                    modificacoes.tipo = values.tipo;
                }

                if (values.descricao !== justificativaAbonoSelecionada.descricao) {
                    modificacoes.descricao = values.descricao;
                }

                if (Number(values.contratante[0]?.id) !== justificativaAbonoSelecionada.idContrante) {
                    modificacoes.idContrante = Number(values.contratante[0]?.id);
                }

                if (values.codigo !== justificativaAbonoSelecionada.codigo) {
                    modificacoes.codigo = values.codigo;
                }

                if (values.codigoSap !== justificativaAbonoSelecionada.codigoSap) {
                    modificacoes.codigoSap = values.codigoSap;
                }

                const abonadoContratanteAtual = values.abonadoContratante === 'sim';
                if (abonadoContratanteAtual !== justificativaAbonoSelecionada.abonadoContratante) {
                    modificacoes.abonadoContratante = abonadoContratanteAtual;
                }

                // Se não houver modificações, não faz a chamada
                if (Object.keys(modificacoes).length === 0) {
                    setMsgAlert("Nenhuma alteração foi realizada");
                    setTipoMsgAlert("info");
                    setOpenAlert(true);
                    setDisableActions(false);
                    setLoading(false);
                    return;
                }

                const { data } = await putJustificativaAbono(
                    modificacoes as IPutJustificativaAbono,
                    justificativaAbonoSelecionada.id
                ) as AxiosResponse;

                setLoading(false);
                setMsgAlert(!!data && typeof data === "string" ? data : "Justificativa de Abono alterada com sucesso!");
                setTipoMsgAlert("success");
                setOpenAlert(true);
                setDisableActions(false);
            }
        } catch (e: any) {
            setLoading(false);

            setMsgAlert(e?.response?.data?.errors ? Object.values(e.response.data.errors).join("<br>") : "Erro ao editar serviço");

            setTipoMsgAlert("error");
            setOpenAlert(true);
            setDisableActions(false);
        }
    };

    const formik = useFormik({
        initialValues: isIncluding ? REGISTER_ALLOWANCE_JUSTIFICATION_INITIAL_VALUES : initialValuesEditar,
        validationSchema: registerOrderServiceStatusSchema,
        onSubmit: handleFormikSubmit
    });

    const getContratanteOnLoad = () => {
        const contratante = listaContratante.find((contratante) => Number(contratante.id) === justificativaAbonoSelecionada?.idContrante);
        formik.setFieldValue('contratante', contratante ? [{ id: contratante.id, name: contratante.name }] : []);
    }

    useEffect(() => {
        localGetContratantes();
        getContratanteOnLoad();
    }, [justificativaAbonoSelecionada]);

    function handleChangeAutocomplete(values: {
        name: string;
        id: string;
    }[]) {
        formik.setFieldValue('contratante', values)
    };

    return (
        <Section id={"register-allowance-justification"}>
            <Header
                setMenuLateral={() => setMenuLateral(true)}
                title={isIncluding ? "Cadastrar Justificativa de Abono" : "Editar Justificativa de Abono"}
            />

            <GoBack type="button" onClick={() => navigate('/ConsultarJustificativaAbono')}>
                <ArrowBack fontSize="small" />
            </GoBack>

            <Stack width="100%" bgcolor="#F9F9F9" px="1.625rem" py="2.625rem" flexDirection="column" rowGap="1rem" borderRadius="1.75rem">
                <Form onSubmit={formik.handleSubmit}>
                    <Grid container width="100%" spacing={4}>
                        <Grid item xs={12} md={3}>
                            <Stack maxWidth="4.5rem">
                                <FormGroup style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <FormControlLabel
                                        sx={{
                                            '&.Mui-disabled': { backgroundColor: 'transparent' },
                                            '& .MuiTypography-root': {
                                                fontSize: '0.875rem !important'
                                            }
                                        }}
                                        label="Status"
                                        checked={formik.values.status}
                                        onChange={() => formik.setFieldValue('status', !formik.values.status)}
                                        control={
                                            <AllowanceSwitch />
                                        }
                                    />
                                </FormGroup>
                            </Stack>
                        </Grid>

                        <Grid item xs={0} md={9}>
                            <div />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <MultipleSelectCheckmarks
                                nodata={"Nenhum tipo encontrado"}
                                options={[{ id: 'geral', name: 'Geral' }, { id: 'contratante', name: 'Contratante' }]}
                                value={[formik.values.tipo]}
                                onChange={(e) => formik.setFieldValue('tipo', e[0])}
                                id="multiple-checkbox-tipo"
                                idOption="multiple-checkbox-option-tipo"
                                labelId="multiple-checkbox-label-tipo"
                                multiple={false}
                                dimension='sm'
                                name="tipo"
                                label='Tipo'
                                error={formik.touched.tipo && !!formik.errors.tipo}
                                helperText={formik.touched.tipo && formik.errors.tipo}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Input
                                label='ID Oppay'
                                id='id'
                                dimension='sm'
                                name='id'
                                error={formik.touched.idOppay && Boolean(formik.errors.idOppay)}
                                helperText={formik.touched.idOppay && formik.errors.idOppay}
                                value={formik.values.idOppay}
                                maxLength={255}
                                onChange={(e) => formik.setFieldValue('idOppay', e.target.value)}
                            // disabled={disableActions}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Input
                                label='Descrição da Justificativa'
                                id='justify'
                                dimension='sm'
                                name='justificativa'
                                error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                                helperText={formik.touched.descricao && formik.errors.descricao}
                                value={formik.values.descricao}
                                maxLength={100}
                                onChange={(e) => formik.setFieldValue('descricao', e.target.value)}
                            // disabled={disableActions}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            {/* <MultipleSelectCheckmarks
                            nodata={"Nenhum contratante encontrado"}
                            options={listaContratante}
                            value={[formik.values.contratante]}
                            onChange={(e) => formik.setFieldValue('contratante', e[0])}
                            id="multiple-checkbox-contratante"
                            idOption="multiple-checkbox-option-contratante"
                            labelId="multiple-checkbox-label-contratante"
                            multiple
                            dimension='sm'
                            name="contratante"
                            label='Contratante'
                            error={formik.touched.contratante && !!formik.errors.contratante}
                            helperText={formik.touched.contratante && formik.errors.contratante}
                        /> */}

                            <Stack sx={{
                                '.MuiInputBase-root': {
                                    background: '#fff !important'
                                }
                            }}>
                                <AutocompleteMultiple
                                    noOptionsText={"Nenhum contratante encontrado"}
                                    options={listaContratante}
                                    value={formik.values.contratante}
                                    onChange={handleChangeAutocomplete}
                                    fontSize={12}
                                    allSelected
                                    label="Contratante"
                                    limitTags={1}
                                    multiple={false}
                                    id="multiple-checkbox-contratante"
                                    dimension="sm"
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Input
                                label='Código'
                                id='code'
                                dimension='sm'
                                name='codigo'
                                error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                                helperText={formik.touched.codigo && formik.errors.codigo}
                                value={formik.values.codigo}
                                maxLength={255}
                                onChange={(e) => formik.setFieldValue('codigo', e.target.value)}
                            // disabled={disableActions}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Input
                                label='Código SAP'
                                id='sapCode'
                                dimension='sm'
                                name='codigoSap'
                                error={formik.touched.codigoSap && Boolean(formik.errors.codigoSap)}
                                helperText={formik.touched.codigoSap && formik.errors.codigoSap}
                                value={formik.values.codigoSap}
                                maxLength={255}
                                onChange={formik.handleChange}
                            // disabled={disableActions}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <MultipleSelectCheckmarks
                                nodata={"Nenhum contratante encontrado"}
                                options={[{ id: 'sim', name: 'Sim' }, { id: 'nao', name: 'Não' }]}
                                value={[formik.values.abonadoContratante]}
                                onChange={(e) => formik.setFieldValue('abonadoContratante', e[0])}
                                id="multiple-checkbox-abonadoContratante"
                                idOption="multiple-checkbox-option-abonadoContratante"
                                labelId="multiple-checkbox-label-abonadoContratante"
                                multiple={false}
                                dimension='sm'
                                name="abonadoContratante"
                                label='Abonado pelo Contratante'
                                error={formik.touched.abonadoContratante && !!formik.errors.abonadoContratante}
                                helperText={formik.touched.abonadoContratante && formik.errors.abonadoContratante}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Input
                                label='Criado por'
                                id='createdBy'
                                dimension='sm'
                                name='criadoPor'
                                error={formik.touched.criadoPor && Boolean(formik.errors.criadoPor)}
                                helperText={formik.touched.criadoPor && formik.errors.criadoPor}
                                value={formik.values.criadoPor}
                                maxLength={255}
                                onChange={formik.handleChange}
                                disabled
                            // disabled={disableActions}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <DateTimePicker
                                dimension="sm"
                                label='Criado em'
                                id="createdAt"
                                value={formik.values.criadoEm}
                                sx={{
                                    '.MuiInputBase-root': {
                                        backgroundColor: 'white !important'
                                    }
                                }}
                                onChange={(e) => (formik.setFieldValue('criadoEm', e))}
                                error={formik.touched.criadoEm && Boolean(formik.errors.criadoEm)}
                                helperText={(formik.touched.criadoEm && formik.errors.criadoEm) ? "Campo obrigatório" : ""}
                                disabled
                            // disabled={disableActions}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Input
                                label='Alterado por'
                                id='changedBy'
                                dimension='sm'
                                name='alteradoPor'
                                error={formik.touched.alteradoPor && Boolean(formik.errors.alteradoPor)}
                                helperText={formik.touched.alteradoPor && formik.errors.alteradoPor}
                                value={formik.values.alteradoPor}
                                maxLength={255}
                                onChange={formik.handleChange}
                                disabled={true}
                            // disabled={disableActions}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <DateTimePicker
                                dimension="sm"
                                label='Alterado em'
                                id="changedAt"
                                value={formik.values.alteradoEm}
                                onChange={(e) => (formik.setFieldValue('alteradoEm', e))}
                                error={false}
                                helperText={(formik.touched.alteradoEm && formik.errors.alteradoEm) ? "Campo obrigatório" : ""}
                                disabled={true}
                                sx={{
                                    '.MuiInputBase-root': {
                                        backgroundColor: 'white !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Stack direction="row" columnGap="1.5rem">
                        <Button
                            dimension='sm'
                            variant='primary'
                            margin='0'
                            width="11rem"
                            type='submit'
                            disabled={isIncluding ? !profileHasPermission("Novo Registro") : !profileHasPermission("Editar Registro")}
                        >
                            {formik.isSubmitting ?
                                <Stack direction="row" alignItems="center" columnGap="1rem">
                                    Salvando...
                                    <CircularProgress size={14} sx={{ color: '#fff' }} />
                                </Stack> : 'Salvar'}
                        </Button>
                    </Stack>
                </Form>
            </Stack>
            <AlertMessage
                isOpenAlert={openAlert}
                setOpenAlert={setOpenAlert}
                alertType={tipomsgAlert}
                msgAlert={msgAlert}
            />
        </Section>
    );
};
