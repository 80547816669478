import React, { useState, useContext, useEffect, useRef } from "react";
import _ from "lodash";
import "./farol.scss";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import Modal from "../../../components/modal";
import Autocompletetecnicos from "../../../components/autocompleteTecnicos/autocompleteTecnicos";
import {
  getListaTecnicos,
  encaminharOSs,
  getListaTecnicosByPrestador,
} from "../../encaminharOS/services/services";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from '../../../components/collapse/collapse';
import { FormControlLabel, Checkbox } from '@mui/material';
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  Container,
  ContainerAutocomplete,
  Body,
  Column1,
  Column2,
  Row,
} from "./farolModalEncaminharOSStyles";
import { IModalEncaminharOS, IOSEncaminhada, IOrdemServicoEncaminhada } from "../types";
import { AxiosResponse } from "axios";
import { ITecnico } from "../../encaminharOS/types";
import { IOrdemServico } from "../../../contexts/types/farolTypes";
import { IFindContratanteServicoParametro } from '../types';
import { siteTheme } from "../../../styles/themeVariables";
import { getListaTecnicosByPrestadorEncaminhar, findContratanteServicoParametro } from "../services";
import { ITecnicos } from "../../../contexts/types/encaminharTypes";
import Input from "../../../components/Input/Input";
import { Contratante } from "../../../helpers/contratantes";
import ErroRouteasyModal from "./farolErroRouteasyModal";

const { color } = siteTheme

const ModalEncaminharOS = ({isModalOpen, handleClose}: IModalEncaminharOS) => {
  const {
    osselected,
    getOSsByID,
  } = useContext(HomeContext);
  const { user } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(isModalOpen)
  const [modalEncaminharOSData, setModalEncaminharOSData] = useState<{ chamado?:number, tecnico?:string, status?:string, cep?:string, modelo?:string, bairro?:string, logradouro?:string, numeroSerie?:string, idPrestador?:number, nomePrestador?:string  }  >({
    chamado:0, tecnico:'', status:'', cep:'', modelo:'', bairro:'', logradouro:'', numeroSerie:'', idPrestador:0, nomePrestador:'' 
  })
  const [tecnicoSelecionado, setTecnicoSelecionado] = useState<ITecnico | null>(null);
  const [tecnicoSelecionadoOppay, setTecnicoSelecionadoOppay] = useState<ITecnicos | null>(null);
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("success");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [msgAlertSuccess, setMsgAlertSuccess] = useState("OS encaminhada com  sucesso!");
  const [msgAlertError, setMsgAlertError] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [showEditSerie, setShowEditSerie] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [listaTecnicos, setListaTecnicos] = useState<ITecnicos[]>([]);
  const [requestDone, setRequestDone] = useState(false);

  const [ehPularRoteirizacaoModal, setEhPularRoteirizacaoModal] = useState(false);
  const [ehPularRoteirizacao, setEhPularRoteirizacao] = useState(true);
  const [contadorAtualizacao, setContadorAtualizacao] = useState(0);
  const deveInvocarEncaminhar = useRef(false);

  const { chamado, tecnico, status, cep, modelo, bairro, logradouro, numeroSerie, idPrestador, nomePrestador  } =
    getOSsByID(osselected)[0]! || {};
    
  const requestListaTecnicos = async () => {
    try{
      let { data } = await getListaTecnicosByPrestador(idPrestador) as AxiosResponse;
      setListaTecnicos(data);
      
    } catch(e){
      setListaTecnicos([]);
    }
  };

  const listaTecnicosByPrestadorTratada = () => {
    let resultadoDados;

    resultadoDados = listaTecnicos?.map((item: ITecnicos) => {
      return {
        label: item.nome || item.nomeCompleto,
        id: item?.idTecnico?.toString() || item.id?.toString(),
      };
    });

    /**Remove itens duplicados */
    resultadoDados = _.uniqBy(resultadoDados, 'id');

    return _.sortBy(resultadoDados, ["label"]);
  };

  const handleClickEncaminhar = async () => {
    //Validações
    setDisableActions(true);
    setRequestDone(false);
    setEhPularRoteirizacaoModal(false);

    if (!!openAlert) {
      setOpenAlert(false);
      setOpenAlertError(false);
      setOpenAlertSuccess(false);
      setMsgAlertSuccess('')
      setMsgAlertError('')
    }

    if (!tecnicoSelecionado) {
      setTipoMsgAlert("error");
      setMsgAlert("Selecione um técnico!");
      setOpenAlert(true);
      setDisableActions(false);
      return;
    }

    //Obsolete - serial é apenas enviado para entrega de equipamento - const serialsUnico:Array<{serial:string}> = [{serial:modalEncaminharOSData?.numeroSerie ?? ''}]

    //verifica se o numero de série é novo e encaminha o novo numero de série, 
    //caso contrário envia o numero de série anterior

    const OSs = getOSsByID(osselected).map((item: IOrdemServico) => ({
      id: item.chamado,
      serial: modalEncaminharOSData?.numeroSerie != item.numeroSerie ? modalEncaminharOSData.numeroSerie : item.numeroSerie || '', 
    }))

    const serials = getOSsByID(osselected).map((item: IOrdemServico) => ({
      serial: item.numeroSerie || ''
    }))

    await encaminharOSs({
      idUsuario: user!.idUsuario,
      idTecnico: tecnicoSelecionado?.id ?? '',
      idPrestador: idPrestador,
      ordensServico: OSs,
      seriais: [],
      ehPularRoteirizacao: true
    })
      .then((retorno: any) => {
        setRequestDone(true);
        setDisableActions(false);

        const { data } = retorno as AxiosResponse;

        const listaEncaminharResponseMsgError = data.ordensOppay.filter(
          (item: IOrdemServicoEncaminhada) => !item.isSucesso);

        const listaEncaminharResponseMsgSuccess = data.ordensOppay.filter(
          (item: IOrdemServicoEncaminhada) => item.isSucesso);

        setMsgAlertSuccess(listaEncaminharResponseMsgSuccess.map(
          (item: IOrdemServicoEncaminhada) =>
            item.message !== '' ?
              `${item.message}` :
              `OS ${item.idOs} encaminhado com sucesso`
        )
          .toString()
          .replaceAll(',', '</br>'));

        setMsgAlertError(listaEncaminharResponseMsgError.map(
          (item: IOrdemServicoEncaminhada) =>
            item.message !== '' ?
              `${item.message}` :
              `Situação da OS ${item.idOs} inválida para o encaminhamento.`
        )
          .toString()
          .replaceAll(',', '</br>'));

        if (!_.isEmpty(listaEncaminharResponseMsgSuccess)) {
          setOpenAlertSuccess(true);
        };
        if (!_.isEmpty(listaEncaminharResponseMsgError)) {
          setOpenAlertError(true);
        };

      })
      .catch((erro) => {
        setRequestDone(true);
        setDisableActions(false);
        setMsgAlertError(erro.response.data);
        setOpenAlertError(true);

        setEhPularRoteirizacao(false);
        if (erro.response.data.includes("Erro ao roteirizar")) {
          setEhPularRoteirizacaoModal(true);
          setOpenAlertError(false);
        }
      });
  };

  useEffect(() => {
    const findParametroContratanteAsync = async () => {
      try   {
        let { data }: { data: IFindContratanteServicoParametro } = await findContratanteServicoParametro(chamado) as AxiosResponse;
        setShowEditSerie(data.data.dynamicProperties.exigeSerialEncaminhamento);
        setModalEncaminharOSData({chamado, tecnico, status, cep, modelo, bairro, logradouro, numeroSerie, idPrestador, nomePrestador })

      } catch (error) {
        setShowEditSerie(false);
      }
    };

    setIsOpen(isModalOpen);
    if (isModalOpen)
    {
      setModalEncaminharOSData({chamado, tecnico, status, cep, modelo, bairro, logradouro, numeroSerie, idPrestador, nomePrestador })
    }
  }, [isModalOpen]);

  useEffect(() => {
    const tecnicoSel = listaTecnicos.find((fd) => fd.idTecnico?.toString() == tecnicoSelecionado?.id);
    if (tecnicoSel) {
      setTecnicoSelecionadoOppay(tecnicoSel);
    }
  }, [tecnicoSelecionado]);

  const handleCliqueBotao = (valor: boolean) => {
    if (ehPularRoteirizacao !== valor) {
      setEhPularRoteirizacao(valor);
    } else {
      setContadorAtualizacao((prev) => prev + 1);
    }
    deveInvocarEncaminhar.current = true;
  };

  useEffect(() => {
    if (deveInvocarEncaminhar.current) {
      handleClickEncaminhar();
      deveInvocarEncaminhar.current = false;
    }
  }, [ehPularRoteirizacao, contadorAtualizacao]);


  return (
    <>
    <Modal
      handleCloseModal={() => handleClose(requestDone)}
      title={"Encaminhar"}
      isOpen={isOpen}
    >
      <Container>
        <ContainerAutocomplete>
          <Autocompletetecnicos
            id={"ModalEncaminharOS-Autocompletetecnico"}
            listboxProps={{
              sx: {
                height: 250,
              },
            }}
            placeholder="Selecionar técnico"
            noOptionsText={"Não há técnicos ativos"}
            autocompleteValue={tecnicoSelecionado}
            onChangeValue={setTecnicoSelecionado}
            //@ts-ignore
            options={listaTecnicosByPrestadorTratada()}
            requestOptions={requestListaTecnicos}
            disabled={disableActions}
          />

          <Button
            id={"ModalEncaminharOS-BotaoEncaminhar"}
            disabled={disableActions}
            onClick={handleClickEncaminhar}
            sx={{
              bgcolor: color.primary,
              color: "#FFF",
              "&:hover": {
                backgroundColor: color.primary,
                color: "#FFF",
              },
              minWidth: "126px",
              marginLeft: "10px",
              fontSize: "12px",
              fontFamily: "Open Sans",
              padding: "6px 8px",
            }}
          >
            Enviar a Campo
          </Button>
        </ContainerAutocomplete>
        <Body>
          <Column1>
            <Row>
              <span>OS</span>
              {chamado?.toString()}
            </Row>
            <Row>
            <span>Série</span>
              <Input
                dimension="sm"
                name='serie'
                id='serie'
                maxLength={100}
                value={modalEncaminharOSData.numeroSerie}
                onChange={(e)=> setModalEncaminharOSData({...modalEncaminharOSData,numeroSerie:e.target.value}) }
              />
            </Row>
            
            <Row>
              <span>Status</span>
              {status}
            </Row>
            <Row>
              <span>CEP</span>
              {cep}
            </Row>
          </Column1>
          <Column2>
            <Row>
              <span>Prestador</span>
              {nomePrestador}
            </Row>
            <Row>
              <span>Modelo</span>
              {modelo}
            </Row>
            <Row>
              <span>Bairro</span>
              {bairro}
            </Row>
            <Row>
              <span>Rua</span>
              {logradouro}
            </Row>
          </Column2>
        </Body>

          <Collapse
            onClose={setOpenAlertSuccess}
            in={openAlertSuccess}
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Alert
              severity='success'
              icon={<div />}
              variant="filled"
              sx={{
                mb: 2,
                bgcolor: "#178B319E",
                height: "67px",
                boxSizing: "border-box",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 0,
                borderRadius: 0,
              }}
              action={
                <IconButton
                  id={"ModalEncaminharOS-AlertCloseIcon"}
                  aria-label="close"
                  color="inherit"
                  size="medium"
                  onClick={() => setOpenAlertSuccess(false)}
                >
                  <CloseIcon
                    fontSize="inherit"
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              }
            >
              <span
               style={{
                color: "white",
                fontSize: "14px",
               }}
               dangerouslySetInnerHTML={{__html: msgAlertSuccess}}
              />
            </Alert>
          </Collapse>
        <Collapse
          onClose={setOpenAlertError}
          in={openAlertError}
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <Alert
            severity='error'
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "#FF23239E",
              height: "67px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 0,
              borderRadius: 0,
            }}
            action={
              <IconButton
                id={"ModalEncaminharOS-AlertCloseIcon"}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertError(false)}
              >
                <CloseIcon
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
             style={{
              color: "white",
              fontSize: "14px",
             }}
             dangerouslySetInnerHTML={{__html: msgAlertError}}
            />
          </Alert>
        </Collapse>
      </Container>
    </Modal>

      <ErroRouteasyModal
        isModalOpen={ehPularRoteirizacaoModal}
        handleCloseModal={() => setEhPularRoteirizacaoModal(!ehPularRoteirizacaoModal)}
        handleClickButton={(valor) => handleCliqueBotao(valor)}
      />
    </>
  );
};

export default ModalEncaminharOS;
