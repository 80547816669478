import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import Modal from "../../../components/modal";
import {
  titleStyle,
  Container,
  CardOSBody,
  InfoWrapper,
  BlockTitle,
  BodyInfo,
  BodyInfoItem,
  SpanInfo,
  ButtonContainer,
  buttonStyle,
  PerimeterDiv,
} from "./farolDetalhesOSModalStyles";
import { IMelhorEquipamento, IOrdemServico } from "../../../contexts/types/farolTypes";
import moment from "moment";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import SwitchMUI from "../../../components/switch/switch";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from '../../../contexts/auth';
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import { AxiosResponse } from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { updatevalidaPerimetro } from "../services";
import { removeSpecialCharSimple } from "../../../helpers/removeSpecialCharSimple";

interface IDetalhesOSModal {
  isModalOpen: boolean,
  handleClose: () => void
  ordemServico: IOrdemServico | null
}

const DetalhesOSModal = ({ isModalOpen, handleClose, ordemServico }: IDetalhesOSModal) => {
  const { listaos, setListaos } = useContext(HomeContext);
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Farol de OS", funcionalidade);

  const [isOpen, setIsOpen] = useState(isModalOpen);
  const [os, setOS] = useState<IOrdemServico | null>(ordemServico);
  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const renderDataProtocoloTecnico = (date: any) => {
    if (!date) return " ";

    const dataProtocolo = moment(date, "YYYY-MM-DDTHH:mm:ss").format(
      "DD/MM/YYYY"
    );

    if (date === "0001-01-01T00:00:00" || dataProtocolo === "01/01/0001") return " ";

    return dataProtocolo;
  };

  const osPossuiContratanteID = (id: number) => {
    // [{"id": 63, "descricao": "PUNTO"}];

    return os?.idContratante === id;
  };

  const handleClickDetails = (data: any) => {
    navigate("/DetalhesOS", {
      state: {
        chamado: data.chamado,
        idContratante: data.idContratante,
        rota: {
          url: window.location.pathname,
          name: "Farol de OS",
        },
      }
    });
  };

  const handleShowPerimeter = () => {
    return removeSpecialCharSimple(os?.status || "") === "emcampo";
  }

  const handleSwitchValidaPerimetro = async (value: boolean) => {
    setLoading(true);
    try {
      const { data } = await updatevalidaPerimetro({
        idOS: os?.chamado,
        validaPerimetro: value
      }) as AxiosResponse;

      setLoading(false);
      handleSetValueOS(value);
      setOpenAlert(true);
      setTipoMsgAlert('success');

      if (!!data && typeof data === 'string') {
        setMsgAlert(data);
      } else {
        setMsgAlert('Sucesso ao atualizar validação de perímetro.');
      }

    } catch (e: any) {
      setLoading(false);
      setOpenAlert(true);
      setTipoMsgAlert('error');

      if (!!e?.response?.data && typeof e?.response?.data === 'string') {
        setMsgAlert(e?.response?.data);
      } else if (e?.response?.data?.errors) {
        setMsgAlert(Object.values(e.response.data.errors).join("<br>"));
      } else {
        setMsgAlert("Erro ao atualizar validação de perímetro" + (e?.response?.status ? ` status: ${e?.response?.status}` : ""));
      }
    }
  };

  const handleSetValueOS = (value: boolean) => {
    const updatedOSModal = {
      ...ordemServico,
      validaPerimetro: value
    };

    const listOSFarolUpdated = [...listaos].map((osFarol) => {
      if (osFarol.chamado === os?.chamado) {
        osFarol.validaPerimetro = value;
      }
      return osFarol;
    });

    //@ts-ignore
    setOS(updatedOSModal);
    setListaos(listOSFarolUpdated);
  };

  useEffect(() => {
    setIsOpen(isModalOpen);
    setOS(ordemServico);
  }, [isModalOpen]);

  return (
    <Modal
      handleCloseModal={handleClose}
      title={" "}
      isOpen={isOpen}
      titleStyle={titleStyle}
      sx={{ "& .MuiPaper-elevation": { maxWidth: "1000px" } }}
    >
      <Container>
        <CardOSBody>
          <InfoWrapper>
            <BlockTitle id={"detalhesOSModalBlockTitle:DadosCliente"}>Dados do Cliente</BlockTitle>
            <BodyInfo id={"detalhesOSModalBody:DadosCliente"}>
              <BodyInfoItem>
                <SpanInfo>Cod. Cliente</SpanInfo><br /> {os?.codigoCliente}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Nome Cliente</SpanInfo><br /> {os?.nomeFantasia}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>CPF/CNPJ</SpanInfo><br /> {os?.cpfCnpj}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Contratante</SpanInfo><br /> {os?.contratante}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Prestador</SpanInfo><br /> {os?.nomePrestador}
              </BodyInfoItem>
              <BodyInfoItem style={{ gridColumnStart: 2, gridColumnEnd: 5 }}>
                <SpanInfo>Endereço</SpanInfo><br /> {`${os?.logradouro ?? ""}, ${os?.numero ?? ""} ${os?.bairro ?? ""}, ${os?.cidade ?? ""} , ${os?.cep ?? ""}`}
              </BodyInfoItem>
            </BodyInfo>

            <BlockTitle id={"detalhesOSModalBlockTitle:DadosDaOs"}>Dados da OS</BlockTitle>
            <BodyInfo id={"detalhesOSModalBody:DadosDaOs"}>
              <BodyInfoItem>
                <SpanInfo>Dt Ger. do Prot. Técnico</SpanInfo><br />
                {renderDataProtocoloTecnico(os?.dataGeracaoProtocolo)}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Modelo</SpanInfo><br /> {os?.modelo}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Tipo Atendimento</SpanInfo><br /> {os?.tipoOS}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Serviço</SpanInfo><br /> {os?.servico}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Grupo Serviço</SpanInfo><br /> {os?.grupoServico}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Série Ins</SpanInfo><br /> {os?.numeroSerie}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Técnico</SpanInfo><br /> {os?.tecnico || ""}
              </BodyInfoItem>

              {!!osPossuiContratanteID(63) &&
                <>
                  <BodyInfoItem title={os?.idTerminal}>
                    <SpanInfo>ID Terminal</SpanInfo><br /> {os?.idTerminal}
                  </BodyInfoItem>
                  <BodyInfoItem>
                    <SpanInfo>Aplicação Equipamento</SpanInfo><br /> {os?.aplicacaoEquipamento}
                  </BodyInfoItem>
                </>
              }

              <BodyInfoItem>
                <SpanInfo>Série Ret</SpanInfo><br /> {os?.numeroSerieRemover}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Operadora 1</SpanInfo><br /> {os?.operadora1}
              </BodyInfoItem>
              <BodyInfoItem>
                <SpanInfo>Operadora 2</SpanInfo><br /> {os?.operadora2}
              </BodyInfoItem>

              {/* <BodyInfoItem>
              <SpanInfo>Data Agendamento</SpanInfo><br/> {!!os?.dataAgendamento ? moment(os?.dataAgendamento, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm") : ""}
            </BodyInfoItem> */}
              {!!os?.linkLaudo &&
                <BodyInfoItem>
                  <SpanInfo><a id={"linkLaudoOS:" + (os?.chamado)} href={os?.linkLaudo} target="_blank">Link Laudo</a></SpanInfo>
                </BodyInfoItem>
              }
            </BodyInfo>
            {!_.isEmpty(os?.melhorEquipamento) &&
              <>
                <BlockTitle id={"detalhesOSModalBlockTitle:MelhorEquipamento"}>Melhor Equipamento</BlockTitle>
                <BodyInfo className={"bottomblock"} id={"detalhesOSModalBody:MelhorEquipamento"}>
                  {os?.melhorEquipamento?.map((equipamento: IMelhorEquipamento) => {
                    return (
                      <BodyInfoItem key={equipamento.id}>
                        <SpanInfo>Modelo</SpanInfo><br /> {equipamento.descricao} - {equipamento.situacao}
                      </BodyInfoItem>
                    )
                  })}

                </BodyInfo>
              </>
            }
          </InfoWrapper>
          <ButtonContainer>
            {handleShowPerimeter() ?
              <PerimeterDiv title={!profileHasPermission('Liberar atendimento fora do perímetro') ? "Sem permissão" : ""}>
                Permite atender fora do perímetro

                <SwitchMUI
                  checked={!!os?.validaPerimetro}
                  setChecked={(value: boolean) => handleSwitchValidaPerimetro(value)}
                  type={"IOS"}
                  sx={(
                    !profileHasPermission('Liberar atendimento fora do perímetro') ?
                      { "&.Mui-disabled": { cursor: 'not-allowed', backgroundColor: 'transparent' } }
                      : {}
                  )}
                  disabled={loading || !profileHasPermission('Liberar atendimento fora do perímetro')}
                />

                {!!loading && <CircularProgress size={25} />}
              </PerimeterDiv>
              :
              <div />
            }

            <Button
              id={"buttonOpenDetalhesOSPage"}
              onClick={() => handleClickDetails(os)}
              sx={buttonStyle}
            >
              Mais detalhes
            </Button>
          </ButtonContainer>

          <AlertMessage
            isOpenAlert={openAlert}
            setOpenAlert={setOpenAlert}
            alertType={tipomsgAlert}
            msgAlert={msgAlert}
          />
        </CardOSBody>
      </Container>
    </Modal>
  );
};

export default DetalhesOSModal;
