export const EPerguntaGrupo = {
    VISAO_GERAL: "FDB77C4F-D6CE-470B-A9D4-BEDBDC29B459",
    ITENS_MOTO: "59BE47E2-59B6-42A4-B869-1E9EACBF8291",
    EPIS: "7463EA83-55A6-4D20-AEB1-8A89789EE4AF",
    VISAO_GERAL_ITENS_MOTO_COMPLETO: "A325D79A-7D37-4F16-B0AD-36D6F2EBC4BA",
    VISAO_GERAL_EPIS_COMPLETO: "B2331E57-D8B4-427D-93B6-5FCB2E718130",
}

export const EPergunta = {
    QUILOMETRAGEM: "B9625349-A63C-40FC-842D-1E7D29679BFA",
    ITENS_MOTO_OBSERVACOES: "2483E310-63B3-4B8F-85EA-DB91CF2D8289",
    ITENS_MOTO_FOTOS: "CD06AD66-A280-484C-A58F-FA16C9103C9D",
    EPIS_OBSERVACOES: "79C42604-21DF-4D9B-8D87-39A5E0927A49",
    EPIS_FOTOS: "5873CD05-966D-46B9-AD19-D1118132A6BC",
    CHECKLIST_COMPLETO_ITEM_MOTO: "E61D214E-15D6-4CA1-B9E9-08CCB0CF4C0A",
    FOTO_CHECKLIST_COMPLETO_ITEM_MOTO: "D34FED9F-3769-4473-AF5C-252222D82099",
    CHECKLIST_COMPLETO_EPI: "9BD13D61-CC60-44AA-9128-94FF863BB21E",
    FOTO_CHECKLIST_COMPLETO_EPI: "C11FCDE1-C416-465A-AB4D-D9FC98440543",
}

export const ETipoOpcao = {
    TEXT: 1,
    TEXT_AREA: 2,
    BOOLEAN: 3,
    DATE: 4,
    CHECKBOX: 5,
    MULTSELECT: 6,
    URL: 7,
}

export const EPerguntasNaoVisiveisNaTabela = [
    "2483E310-63B3-4B8F-85EA-DB91CF2D8289",
    "CD06AD66-A280-484C-A58F-FA16C9103C9D",
    "79C42604-21DF-4D9B-8D87-39A5E0927A49",
];

export const RESPOSTAS_NEGATIVAS = [
    "NOK",
    "nao",
    "Não"
]