import { apiGet, apiPost, apiPut } from "../../../services/api";
import { IPutOrderService } from "../types";

const baseUrl = process.env.REACT_APP_API_ORDEMSERVICO;
const baseUrlAtendimento = process.env.REACT_APP_API_ATENDIMENTO;

export const consultarDetalheProdutosPartesPecas = async (id: number) =>
    await apiGet(`${baseUrl}/ordemservicoprodutospartespecas/produto?idOrdemServicoProduto=${id}`);

export const consultarDadosChip = async (chamado: number) => 
    await apiGet(`${baseUrlAtendimento}/Atendimento/ordem-servico/${chamado}`);

export const atualizarDadosOrdemServico = async (chamado: number, params: IPutOrderService) => 
    await apiPut(`${baseUrl}/OrdemServico_/${chamado}/sincronizar`, params);


export const consultaJustificativaAbonoPorContratante = async (idContratane: number) =>
    await apiGet(`${baseUrl}/JustificativaAbono?IdContrante=${idContratane}`);

export const justificativaAbono = async () =>
    await apiGet(`${baseUrl}/JustificativaAbonoOrdemServico/GetEnumsJustificativaAbono`);

export const salvarJustificativaAbono = async (dados:any) => 
    await apiPost(`${baseUrl}/JustificativaAbonoOrdemServico`,dados);

export const obterAbono = async (numeroChamado:number) => 
    await apiGet(`${baseUrl}/JustificativaAbonoOrdemServico?chamadoOrdemServico=${numeroChamado}`);


export const getTecnicos = async () => 
    await apiGet(`${baseUrl}/tecnicos?Ativo=true`);

export const getPrestadores = async () => 
    await apiGet(`${baseUrl}/prestadores?Ativo=true`);