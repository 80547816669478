import React, { useContext, useEffect, useState } from 'react';
import { Section } from "./consultarTermoOs_styles";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import "./editarTermoOs.scss";
import Button from "../../../components/Button/styles";
import CloseIcon from "@mui/icons-material/Close";
import { TextAreaTermo } from "./textAreaTermoOs";
import { Alert, CircularProgress, IconButton } from "@mui/material";
import Collapse from "../../../components/collapse/collapse";
import Input from "../../../components/Input/Input";
import moment from "moment";
import { GrupoServico, Grupo_Servico, IServico } from "../../../contexts/types/farolTypes";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { getGrouped_Services } from "../../farol/services";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import { getListaServicosComFiltro } from "../../servicos/services";
import { TermoPostPut } from "../types";
import { atualizarTermo } from "../services";
import { AxiosResponse } from "axios";
import _, { get } from 'lodash';
const EditarTermoOs = () => {
  const location = useLocation();
  const isIncluding = window.location.pathname.includes("EditarTermoOs");
  const dadosTermoOs = location.state?.dadosTermoOs || {};
console.log("dadosTermoOs",dadosTermoOs)
  const { setMenuLateral,localGetContratantes,listaContratante} = useContext(HomeContext);
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const [listaGrupoServico, setListaGrupoServico] = useState<GrupoServico[]>();
  const [listaServicos, setListaServicos] = useState<IServico[]>([]);

  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
    "Editar Termo de OS",
    funcionalidade);

  const navigate = useNavigate();

  const [disableActions, setDisableActions] = useState(false);
  const listaStatus = [
    { name: "Ativo", id: "1" },
    { name: "Inativo", id: "0" },
  ];

  const handleEditarTermo = async (values: any, actions: any) => {
    setOpenAlertError(false);
    setOpenAlertSuccess(false);
    setLoading(true);
    setDisableActions(true);
    const statusBool = values.status[0] === "0" ? false : true;
    const dados: TermoPostPut = {
      ativo: statusBool,
      termo: values.termo,
      idContratante: parseInt(values.contratante[0]),
      idGrupoServico: values?.grupoServico[0]?.id ?? null,
      idsServicos: values?.servico?.map((servico: any) => parseInt(servico.id)),
    };
    try {
      const retorno = (await atualizarTermo(
        dados,
        values.idTermo
      )) as AxiosResponse;
      if (retorno.status === 200) {
        setMsgAlertSuccess("Termo alterado com sucesso!!");
        setLoading(false);
        setDisableActions(false);
        setOpenAlertSuccess(true);
        navigate("/ConsultarTermoOs");
      } else {
        setLoading(false);
        setDisableActions(false);
        setOpenAlertError(true);
        setMsgAlertError(retorno.data.errors[0].Message);
      }
    } catch (error:any) {
      setLoading(false);
      setDisableActions(false);
      setOpenAlertError(true);
      setMsgAlertError(error.response.data.errors[""][0]);
    }
  };
  useEffect(() => {
    localGetContratantes();
    handleGetGroupesServices();
  }, []);

  useEffect(() => {
    handleGetGroupesServices();
  }, [])
  const getGroupByID = (ID: number) => {
    const resultadoDados: any = _.isArray(listaGrupoServico) ? [...listaGrupoServico] : [];
    handleGetServicosPorGrupo(ID);
    return resultadoDados.find(
      (item: any) => item.id === ID
    )!;
  };

  const [openAlertError, setOpenAlertError] = useState(false);
  const [msgAlertError, setMsgAlertError] = useState("");
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [msgAlertSuccess, setMsgAlertSuccess] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const validationSchema = yup.object({
    status: yup.array().required("Campo obrigatório"),
    termo: yup.string().required("Campo obrigatório"),
    contratante: yup.array().max(1).min(1, "Campo obrigatório"),
  });
  const selectIDByLabel = (
    label: string,
    dados: any,
    defaultReturn?: string
  ) => {
    label = removeSpaces(label);
    let itemObj = dados.find(
      (item: any) => removeSpaces(item.name) === label
    );

    if (!!itemObj) return [itemObj.id];

    return !!defaultReturn ? [defaultReturn] : [];
  };
  const removeSpaces = (str: string): string => {
    return str?.replace(/\s+/g, "").toUpperCase();
  };

  const handleGetGroupesServices = async () => {
    await getGrouped_Services().then((resp: any) => {

      const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;
      const grupos = respSuccess?.data as any[] ?? [];

      setListaGrupoServico(grupos);
    }, (err: any) => {
      const respErr = err?.response?.data as ResponseApi<any>;
    });
  };
  const handleChangeGrupoServico = (e: any) => {
    handleGetServicosPorGrupo(Number(e[0]?.id));
    return formik.setFieldValue("grupoServico", e);
  };
  const handleGetServicosPorGrupo = async (idGrupoServico: number) => {
    await getListaServicosComFiltro({ IdGrupoServico: idGrupoServico }).then(
      (resp: any) => {
        const respSuccess = resp?.data as ResponseApi<IServico[]>;
        const servicos = (respSuccess?.data as any[]) ?? [];
        setListaServicos(servicos);
      },
      (err) => {
        const respErr = err?.response?.data as ResponseApi<any>;
      }
    );
  };

  const initialValues: any = {
    status: !!dadosTermoOs.ativo ? ["1"] : ["0"],
    idTermo: dadosTermoOs.id,
    termo: dadosTermoOs.termo,
    criadoEm:
      !!dadosTermoOs.criadoEm &&
      moment(dadosTermoOs.criadoEm, "YYYY-MM-DDTHH:mm:ss").isValid()
        ? moment(dadosTermoOs.criadoEm, "YYYY-MM-DDTHH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          )
        : "",
    criadoPor: dadosTermoOs.criadoPor,
    alteradoPor: dadosTermoOs.alteradoPor,
    alteradoEm:
      !!dadosTermoOs.alteradoEm &&
      moment(dadosTermoOs.alteradoEm, "YYYY-MM-DDTHH:mm:ss").isValid()
        ? moment(dadosTermoOs.alteradoEm, "YYYY-MM-DDTHH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          )
        : "",
    contratante: selectIDByLabel(
      dadosTermoOs?.contratante?.nome ?? "",
      listaContratante
    ),
    grupoServico: setInitialGroup(),
    servico: setInitalServicos(),
  };
  function setInitialGroup(): { name: string; id: string; }[] {
    const grupo = getGroupByID(dadosTermoOs?.grupoServico?.id);
    return !!grupo ? [{ name: grupo?.nome ?? "", id: String(dadosTermoOs.grupoServico.id) }] : [];
  }
  function setInitalServicos():{ name: string; id: string; }[]{
    const servicos = dadosTermoOs?.servicos?.map((d: any) => ({name: d.nome, id: String(d.id)}))  ?? [];
    return servicos
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (!!profileHasPermission("Editar Termo de OS"))
        return handleEditarTermo(values, actions);
    },
  });
  return (
    <Section id={"EditarTermo"} className="EditarTermo">
      <Header setMenuLateral={() => setMenuLateral(true)} title="Termo de OS" />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/ConsultarTermoOs")}>
          Consultar Termo de OS
        </BreadcrumbItem>
        <BreadcrumbItem>Editar Termo</BreadcrumbItem>
      </Breadcrumb>
      <form className="EditarTermo-Form" onSubmit={formik.handleSubmit}>
        <div className="EditarTermo-Container">
          <div className="InsideGrid">
            <MultipleSelectCheckmarksid
              dimension="sm"
              id="multiple-checkbox-status"
              idOption="multiple-checkbox-option-status"
              labelId="multiple-checkbox-label-status"
              nodata="Nenhum status encontrado"
              value={formik.values.status}
              onChange={(e) => formik.setFieldValue("status", e)}
              options={listaStatus}
              label="Status"
              name="status"
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              multiple={false}
              disabled={disableActions}
            />
            <MultipleSelectCheckmarksid
              dimension="sm"
              label={"Contratante"}
              nodata={"Nenhum contratante encontrado"}
              options={[{ name: "--", id: "" }, ...listaContratante]}
              value={formik.values.contratante}
              onChange={(e) => formik.setFieldValue("contratante", e)}
              placeholder={""}
              id="multiple-checkbox-Contratante"
              idOption="multiple-checkbox-option-Contratante-"
              labelId="multiple-checkbox-label-Contratante"
              classes={{
                select: "ConsultaTermo-Seletor error",
                primary: "Seletor-Option",
                group: "Seletor-OptionGroup",
              }}
              multiple={false}
              error={
                formik.touched.contratante && Boolean(formik.errors.contratante)
              }
              helperText={
                formik.touched.contratante && formik.errors.contratante
              }
              disabled={disableActions}
            />
            <AutocompleteMultiple
              dimension="sm"
              label={"Grupo Serviços"}
              placeholder={""}
              noOptionsText={"Nenhum grupo de serviço encontrado"}
              options={listaGrupoServico?.map((item: any) => ({ name: item.nome, id: item.id.toString() }))!}
              value={formik.values.grupoServico}
              onChange={handleChangeGrupoServico}
              id="multiple-checkbox-grupoServico"
              error={formik.touched.grupoServico && Boolean(formik.errors.grupoServico)}
              helperText={formik.touched.grupoServico && formik.errors.grupoServico}
              multiple={false}
              disabled={disableActions}
            />
            <AutocompleteMultiple
              dimension="sm"
              label={"Serviços"}
              placeholder={""}
              noOptionsText={"Nenhum serviço encontrado"}
              options={
                listaServicos?.map((item: any) => ({
                  name: item.nome,
                  id: item.id.toString(),
                }))!
              }
              value={formik.values.servico}
              onChange={(e: any) => formik.setFieldValue("servico", e)}
              id="multiple-checkbox-servico"
              error={formik.touched.servico && Boolean(formik.errors.servico)}
              helperText={formik.touched.servico && formik.errors.servico}
              multiple={true}
              disabled={disableActions}
            />
          </div>
          <TextAreaTermo
            dimension="sm"
            label="Termo"
            maxLength={2000}
            name="termo"
            id="termo"
            value={formik.values.termo}
            onChange={formik.handleChange}
            error={formik.touched.termo && Boolean(formik.errors.termo)}
            helperText={formik.touched.termo && formik.errors.termo}
            disabled={disableActions}
          />
          <div className="InsideGrid4">
            <Input
              dimension="sm"
              label="Criado Por"
              id="criadoPor"
              value={formik.values.criadoPor}
              onChange={(e) => formik.setFieldValue("criadoPor", e)}
              error={
                formik.touched.criadoPor && Boolean(formik.errors.criadoPor)
              }
              helperText={
                formik.touched.criadoPor && formik.errors.criadoPor
                  ? "Campo obrigatório"
                  : ""
              }
              readOnly
            />
            <Input
              dimension="sm"
              label="Criado em"
              id="criadoEm"
              value={formik.values.criadoEm}
              onChange={(e) => formik.setFieldValue("criadoEm", e)}
              error={formik.touched.criadoEm && Boolean(formik.errors.criadoEm)}
              helperText={
                formik.touched.criadoEm && formik.errors.criadoEm
                  ? "Campo obrigatório"
                  : ""
              }
              readOnly
            />
            <Input
              dimension="sm"
              label="Editado Por"
              name="alteradoPor"
              id="alteradoPor"
              value={formik.values.alteradoPor}
              onChange={formik.handleChange}
              error={
                formik.touched.alteradoPor && Boolean(formik.errors.alteradoPor)
              }
              helperText={formik.touched.alteradoPor && formik.errors.alteradoPor}
              readOnly
            />
            <Input
              dimension="sm"
              label="Editado em"
              id="alteradoEm"
              value={formik.values.alteradoEm}
              onChange={(e) => formik.setFieldValue("editadoEm", e)}
              error={
                formik.touched.alteradoEm && Boolean(formik.errors.alteradoEm)
              }
              helperText={
                formik.touched.alteradoEm && formik.errors.alteradoEm
                  ? "Campo obrigatório"
                  : ""
              }
              readOnly
            />
          </div>
        </div>
        <div className="button-list-right">
          {!!profileHasPermission("Editar Termo de OS") ? (
            <Button variant="primary" type="submit" disabled={disableActions}>
              Salvar
            </Button>
          ) : (
            <Button
              variant="primary"
              style={{ cursor: "not-allowed", opacity: 0.5 }}
              type="button"
              disabled={true}
            >
              Salvar
            </Button>
          )}
        </div>
      </form>
      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
      {openAlertError && (
        <Collapse onClose={setOpenAlertError} in={openAlertError}>
          <Alert
            severity="error"
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "rgb(255, 35, 35, 0.7)",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "50px",
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertError(false)}
              >
                <CloseIcon
                  id={"ModalTermoOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlertError }}
            />
          </Alert>
        </Collapse>
      )}
      {openAlertSuccess && (
        <Collapse onClose={setOpenAlertSuccess} in={openAlertSuccess}>
          <Alert
            severity="success"
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "#178B319E",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertSuccess(false)}
              >
                <CloseIcon
                  id={"ModalTermoOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
            >
              {msgAlertSuccess}
            </span>
          </Alert>
        </Collapse>
      )}
    </Section>
  );
};

export default EditarTermoOs;
