import React, { useState, useEffect, useContext } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import './geralTab.scss';
import Input, { MaskedInput } from '../../../components/Input/Input';
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId';
import { maskTelefone } from '../../../middlewares/masks';
import { buscaCEP, getEstados, getCidades } from '../../prestador/services/services';
import { AxiosResponse } from 'axios';
import _, { set } from 'lodash';
import { tipoEnderecoLista, tipoContratanteListaID } from '../../../contexts/helpers/dadosPadrao'
import FullscreenLoading from '../../../components/fullscreenLoading/fullscreenLoading';
import { HomeContext } from '../../../contexts/homeContext';
import { IIncluirEditarContratanteInitialValues } from '../types';
import { IGetResponseCidades, IGetResponseEstados } from '../../prestador/types';
import { FormikErrors, FormikTouched } from 'formik';
import AutocompleteMultiple from '../../../components/autocompleteMultiple/autocompleteMultiple';
import { removeSpecialCharSimple } from '../../../helpers/removeSpecialCharSimple';

interface GeralTabProps {
  formik: {
    values: IIncluirEditarContratanteInitialValues;
    resetForm: () => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleBlur: (e: React.FocusEvent<any>) => void;
    isValid: boolean;
    errors: FormikErrors<IIncluirEditarContratanteInitialValues>;
    touched: FormikTouched<IIncluirEditarContratanteInitialValues>;
  };
  isIncluding: boolean;
};

const GeralTab: React.FC<GeralTabProps> = ({ formik, isIncluding }) => {

  const {
    contratanteSelected,
    setTipoContratante,
    setTipoEndereco,
    setComplemento,
    setCEP,
    estadoListaContratante,
    setEstadoListaContratante,
    estadoContratante,
    setEstadoContratante,
    cidadeListaContratante,
    setCidadeListaContratante,
    setCidadeContratante,
    nomeCidade,
    setNomeCidade,
    setEmailError,
  } = useContext(HomeContext);

  const [loading, setLoading] = useState(false);

  const tipoContratanteLocal = !isIncluding ?
    [...tipoContratanteListaID]?.filter(item => item?.name.toLowerCase() === contratanteSelected?.tipo?.toLowerCase()) :
    [];

  const handleChangeCEP = async (e: any) => {
    setCEP(e.target.value.replace(/\D/g, ""));

    if (e.target.value.replace(/\D/g, "").length === 8) {
      setLoading(true);

      try {
        const { data } = await buscaCEP(e.target.value.replace(/\D/g, "")) as AxiosResponse;

        formik.setFieldValue('bairro', data?.bairro);
        formik.setFieldValue('tipoEndereco',[tipoEnderecoList.find(
          item => item.id === data?.logradouro.substring(0, data?.logradouro.indexOf(' ')))?.id!]);
          setTipoEndereco([tipoEnderecoList.find(
            item => item.id === data?.logradouro.substring(0, data?.logradouro.indexOf(' ')))?.id!])
        formik.setFieldValue('endereco',data?.logradouro.substring(data?.logradouro.indexOf(' ') + 1));

        const estadoPesquisado = [...estadoListaContratante]?.filter(estado => estado.uf.toLowerCase() === data?.uf.toLowerCase());

        setLoading(false);
        formik.setFieldValue('pais', 'Brasil');
        if (!!estadoPesquisado) formik.setFieldValue('estado',estadoPesquisado);
        setEstadoContratante(estadoPesquisado);
        setNomeCidade(data?.localidade.toLowerCase());

      } catch (_) {
        setLoading(false);
      };
    };
  };

  const handleGetEstados = async () => {
    try {
      const { data }: { data: IGetResponseEstados } = await getEstados({ Ativo: true }) as AxiosResponse;
      const estados = data?.estados ?? [];

      setEstadoListaContratante(estados.map(item => ({ name: item.nome, id: item.id.toString(), uf: item.uf })).sort(
        (a: { name: string, id: string }, b: { name: string, id: string }) =>
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))
    } catch (e) {
      setEstadoListaContratante([]);
    }
  };

  const handleGetCidades = async () => {
    if (_.isEmpty(estadoContratante)) {
      setCidadeListaContratante([]);
      return;
    }

    try {
      const { data }: { data: IGetResponseCidades } = await getCidades({
        Ativo: true,
        IdEstado: Number(estadoContratante[0].id),
      }) as AxiosResponse;
      const cidades = data?.cidades ?? [];

      console.log(estadoContratante)

      setCidadeListaContratante(cidades.map(item => ({ name: item.nome, id: item.id.toString() })).sort(
        (a: { name: string, id: string }, b: { name: string, id: string }) =>
          (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      ))

    } catch (e) {
      setCidadeListaContratante([]);
    }
  };

  const handleBlurEmail = (e: any) => {
    const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value);

    if (!isValid && e.target.value.length !== 0) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const docLabel = () => {
    if (_.isEmpty(formik.values.pessoa)) return "CPF / CNPJ"
    if (formik.values.pessoa === 'pj') return 'CNPJ'
    return "CPF"
  };

  const formatTipoEndereco = (tipo: string | string[] | undefined): string[] => {
    if (!tipo) return [];
    if (Array.isArray(tipo)) return tipo;
    return [tipo];
  };

  const tipoEnderecoList = tipoEnderecoLista.map(item => { return { name: item, id: item } });

  useEffect(() => {
    handleGetEstados();

    if (!isIncluding) {
      formik.setFieldValue('pessoa', contratanteSelected?.tipo || "");
      formik.setFieldValue('documento', contratanteSelected?.cpfCnpj?.replace(/\D/g, "") || "");

      const tipoPesquisado = tipoContratanteListaID?.filter(tipo => removeSpecialCharSimple(tipo.name) === removeSpecialCharSimple(contratanteSelected?.tipo ?? ""));
      if (!!tipoPesquisado) setTipoContratante(tipoPesquisado);
      console.log(contratanteSelected)

      formik.setFieldValue('cep', contratanteSelected?.cep || "");
      const cidadePesquisada = cidadeListaContratante?.filter(cidade => removeSpecialCharSimple(cidade.name) === removeSpecialCharSimple(contratanteSelected?.cidade ?? ""));
      if (!!cidadePesquisada) formik.setFieldValue('cidade', cidadePesquisada);
      setCidadeContratante(cidadePesquisada);
      setComplemento(contratanteSelected?.complemento || "");

      console.log(contratanteSelected.cidade)

      const estadoPesquisado = estadoListaContratante?.filter(estado => removeSpecialCharSimple(estado.uf) === removeSpecialCharSimple(contratanteSelected?.estado ?? ""));
      if (!!estadoPesquisado) formik.setFieldValue('estado', estadoPesquisado);
      setEstadoContratante(estadoPesquisado);
      setTipoEndereco(formatTipoEndereco(contratanteSelected.tipoEndereco));
      formik.setFieldValue('pais', contratanteSelected?.pais || "Brasil");
    };
  }, []);

  useEffect(() => {
    handleGetCidades();
    formik.setFieldValue('pais', 'Brasil');
  }, [estadoContratante]);

  useEffect(() => {
    if (!!nomeCidade) {
      const cidadePesquisada = cidadeListaContratante?.filter(cidade => removeSpecialCharSimple(cidade.name) === removeSpecialCharSimple(nomeCidade));
      if (!!cidadePesquisada) {
        formik.setFieldValue('cidade', cidadePesquisada);
        setCidadeContratante(cidadePesquisada);
      }
    } else {
      setNomeCidade("");
    }
  }, [cidadeListaContratante]);

  const handleChangeCEPLocal = (e: any) => {
    formik.setFieldValue('cep', e.target.value.replace(/\D/g, ""));
    handleChangeCEP(e);
  }

  return (
    <div className='GeralTabContainer'>
      <div className='NormalGrid'>
        <FormControl>
          <RadioGroup
            row
            name="radio-buttons-pessoa"
            value={formik.values.pessoa}
            onChange={(e) => formik.setFieldValue('pessoa', e.target.value)}
          >
            <FormControlLabel value="pf" control={<Radio />} label="Pessoa Física" />
            <FormControlLabel value="pj" control={<Radio />} label="Pessoa Júridica" />
          </RadioGroup>
        </FormControl>
        <MaskedInput
          value={formik.values.documento}
          onChange={(e) => formik.setFieldValue('documento', e.target.value)}
          label={docLabel()}
          dimension='sm'
          mask={formik.values.pessoa === 'pf' ? '999.999.999-99' : ' 99.999.999/9999-99'}
          id='CPFCNPJInput'
          readOnly={!formik.values.pessoa}
          maskChar={''}
          error={formik.touched.documento && Boolean(formik.errors.documento)}
          helperText={formik.touched.documento && formik.errors.documento}
          required
        />

        <Input
          value={formik.values.inscricaoEstadual}
          onChange={(e) => formik.setFieldValue('inscricaoEstadual', e.target.value.replace(/\D/g, ""))}
          label='Inscrição Estadual'
          name='inscricaoEstadual'
          dimension='sm'
          id='InscricaoEstadual'
        />
        <Input
          value={formik.values.contato}
          onChange={(e) => formik.setFieldValue('contato', e.target.value)}
          label='Nome para contato'
          name='contato'
          dimension='sm'
          id='Contato'
          error={formik.touched.contato && Boolean(formik.errors.contato)}
          helperText={formik.touched.contato && formik.errors.contato}
        />
        <Input
          value={formik.values.email}
          onChange={(e) => formik.setFieldValue('email', e.target.value)}
          onBlur={handleBlurEmail}
          label='E-Mail'
          dimension='sm'
          name='email'
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          onFocus={() => setEmailError(false)}
          id="email"
        />
        <Input
          value={formik.values.telefone}
          onChange={(e) => formik.setFieldValue('telefone',maskTelefone(e.target.value))}
          onBlur={formik.handleBlur}
          label='Número de Telefone'
          dimension='sm'
          id='telefone'
          name='telefone'
          maxLength={15}
          error={formik.touched.telefone && Boolean(formik.errors.telefone)}
          helperText={formik.touched.telefone && formik.errors.telefone}
        />
      </div>
      <div className='EnderecoGrid'>
        <MaskedInput
          value={formik.values.cep}
          onChange={handleChangeCEPLocal}
          label='CEP'
          name='cep'
          dimension='sm'
          mask='99999-999'
          id='EnderecoCEP'
          maskChar={''}
          error={formik.touched.cep && Boolean(formik.errors.cep)}
          helperText={formik.touched.cep && formik.errors.cep}
        />
        <MultipleSelectCheckmarksId
          nodata={"Nenhum tipo de endereço encontrado"}
          options={tipoEnderecoList}
          value={formatTipoEndereco(formik.values.tipoEndereco)}
          onChange={(e: string[]) => formik.setFieldValue('tipoEndereco', e)}
          id="multiple-checkbox-tipo-endereco"
          name="tipoEndereco"
          idOption="multiple-checkbox-option-tipo-endereco-"
          labelId="multiple-checkbox-label-tipo-endereco"
          multiple={false}
          className="IncluirEditarModeloSelectTipoEndereco"
          dimension='sm'
          label="Tipo de Endereço"
          error={formik.touched.tipoEndereco && Boolean(formik.errors.tipoEndereco)}
          helperText={formik.touched.tipoEndereco && formik.errors.tipoEndereco}
        />
        <Input
          value={formik.values.endereco}
          onChange={(e) => formik.setFieldValue('endereco', e.target.value)}
          onBlur={formik.handleBlur}
          label='Endereço'
          dimension='sm'
          id='Endereco'
          name='endereco'
          error={formik.touched.endereco && Boolean(formik.errors.endereco)}
          helperText={formik.touched.endereco && formik.errors.endereco}
        />

      </div>
      <div className='NormalGrid'>
        <Input
          value={formik.values.numero}
          onChange={(e) => formik.setFieldValue('numero', e.target.value.replace(/\D/g, ""))}
          label='Número'
          dimension='sm'
          id='EnderecoNumero'
          name='numero'
          error={formik.touched.numero && Boolean(formik.errors.numero)}
          helperText={formik.touched.numero && formik.errors.numero}
        />
        <Input
          value={formik.values.complemento}
          onChange={(e) => formik.setFieldValue('complemento', e.target.value)}
          label='Complemento'
          dimension='sm'
          id='EnderecoComplemento'
          name='complemento'
          error={formik.touched.complemento && Boolean(formik.errors.complemento)}
          helperText={formik.touched.complemento && formik.errors.complemento}
        />
        <Input
          value={formik.values.bairro}
          onChange={(e) => formik.setFieldValue('bairro', e.target.value)}
          label='Bairro'
          dimension='sm'
          id='EnderecoBairro'
          name='bairro'
          error={formik.touched.bairro && Boolean(formik.errors.bairro)}
          helperText={formik.touched.bairro && formik.errors.bairro}
        />
        <Input
          value={formik.values.pais}
          name='pais'
          label='País'
          dimension='sm'
          disabled
          id='EnderecoPais'
          error={formik.touched.pais && Boolean(formik.errors.pais)}
          helperText={formik.touched.pais && formik.errors.pais}
        />
        <AutocompleteMultiple
          dimension='sm'
          noOptionsText="Erro ao buscar estados"
          options={estadoListaContratante}
          value={estadoContratante}
          onChange={setEstadoContratante}
          fontSize={12}
          label="Estado"
          placeholder=""
          id="multiple-checkbox-Estado"
          name='estado'
          allSelected
          limitTags={1}
          multiple={false}
          error={formik.touched.estado && Boolean(formik.errors.estado)}
          helperText={formik.touched.estado && formik.errors.estado}
        />
        <AutocompleteMultiple
          dimension='sm'
          noOptionsText="Erro ao buscar cidades"
          options={cidadeListaContratante}
          value={formik.values.cidade}
          onChange={(selectedValues: any) => formik.setFieldValue('cidade', selectedValues)}
          fontSize={12}
          label="Cidade"
          placeholder=""
          name='cidade'
          id="multiple-checkbox-Cidade"
          allSelected
          limitTags={1}
          multiple={false}
          error={formik.touched.cidade && Boolean(formik.errors.cidade)}
          helperText={formik.touched.cidade && formik.errors.cidade}
        />
        <Input
          value={formik.values.tempoGarantia}
          onChange={(e) => formik.setFieldValue('tempoGarantia', e.target.value)}
          name='tempoGarantia'
          label='Tempo de garantia'
          dimension='sm'
          id='TempoGarantia'
          error={formik.touched.tempoGarantia && Boolean(formik.errors.tempoGarantia)}
          helperText={formik.touched.tempoGarantia && formik.errors.tempoGarantia}
        />
        <Input
          value={formik.values.codigo}
          onChange={(e) => formik.setFieldValue('codigo', e.target.value)}
          label='Código do Contratante'
          dimension='sm'
          id='CodigoContratante'
          name='codigo'
        />
        <Input
          value={formik.values.slaTratativa}
          onChange={(e) => formik.setFieldValue('slaTratativa', e.target.value)}
          label='SLA Tratativa'
          dimension='sm'
          id='SLATratativa'
          name='slaTratativa'
        />
      </div>
      {loading && <FullscreenLoading />}
    </div>
  )
};

export default GeralTab;
