import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  IconButton,
  SelectChangeEvent,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  IEnderecoOS,
  IPostConsultarOSsResponseOS,
} from "../../consultaOS/types";
import { obterCidades, obterEstados } from "../services/services";
import { ICidades, IEstado } from "../types";
import { AxiosResponse } from "axios";

interface EnderecoTableProps {
  listaOS: IPostConsultarOSsResponseOS[];
  handleSaveAddress: (updatedAddress: any) => void;
}

const EnderecoTable: React.FC<EnderecoTableProps> = ({
  listaOS,
  handleSaveAddress,
}) => {
  const [editingAddresses, setEditingAddresses] = useState<{
    [key: number]: boolean;
  }>({});
  const [estados, setEstados] = useState<IEstado[]>([]);
  const [cidadesPorEstado, setCidadesPorEstado] = useState<ICidades[]>([]);
  const [loading, setLoading] = useState(true);
  const handleEditAddress = (id: number) => {
    setEditingAddresses((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleChange = useCallback(async (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>,
    endereco: IEnderecoOS,
    field: keyof IEnderecoOS
  ) => {
    const value = event.target.value;
    const updatedAddress = { ...endereco, [field]: value };
    
    if (field === 'idEstado') {
      const idEstado = parseInt(value);
      if (!cidadesPorEstado[idEstado]) {
      }
      updatedAddress.idCidade = 0;
    }
    
    handleSaveAddress(updatedAddress);
  }, [cidadesPorEstado, handleSaveAddress]);

  const getEstados = useCallback(async () => {
    setLoading(true);
    try {
      const request = (await obterEstados()) as AxiosResponse;
      setEstados(request.data.estados || []);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao obter estados:", error);
      setEstados([]);
      setLoading(false);
    }
  }, []);

  const getCidades = async () => {
    setLoading(true);
    try {
      const request = (await obterCidades()) as AxiosResponse;
      setCidadesPorEstado(request.data || []);
      setLoading(false);
    } catch (error) {
      console.error(`Erro ao obter cidades `, error);
      setCidadesPorEstado([]);
      setLoading(false);
    }
  };



  useEffect(() => {
    getEstados();
    getCidades();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="tabela de endereços">
        <TableHead>
          <TableRow>
            <TableCell>OS</TableCell>
            <TableCell>CEP</TableCell>
            <TableCell>Endereço</TableCell>
            <TableCell>Número</TableCell>
            <TableCell>Bairro</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Cidade</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listaOS.flatMap((os) =>
            os.enderecos.map((endereco: any) => (
              <TableRow key={endereco.id}>
                <TableCell>
                  <TextField
                    value={os.chamado}
                    disabled
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={endereco.cep}
                    disabled={!editingAddresses[endereco.id]}
                    onChange={(e) => handleChange(e, endereco, "cep")}
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={endereco.logradouro}
                    disabled={!editingAddresses[endereco.id]}
                    onChange={(e) => handleChange(e, endereco, "logradouro")}
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={endereco.numero}
                    disabled={!editingAddresses[endereco.id]}
                    onChange={(e) => handleChange(e, endereco, "numero")}
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={endereco.bairro}
                    disabled={!editingAddresses[endereco.id]}
                    onChange={(e) => handleChange(e, endereco, "bairro")}
                    size="small"
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <Select
                    value={endereco.idEstado ? endereco.idEstado.toString() : ''}
                    disabled={!editingAddresses[endereco.id]}
                    onChange={(e) => handleChange(e, endereco, "idEstado")}
                    size="small"
                    fullWidth
                  >
                    {estados.map((c: IEstado) => (
                      <MenuItem key={c.id} value={c.id.toString()}>
                        {c.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    value={endereco.idCidade ? endereco.idCidade.toString() : ''}
                    disabled={!editingAddresses[endereco.id]}
                    onChange={(e) => handleChange(e, endereco, "idCidade")}
                    size="small"
                    fullWidth
                  >
                    {cidadesPorEstado.filter(x => x.estadoId == endereco.idEstado).map((cidade: ICidades) => (
                      <MenuItem key={cidade.id} value={cidade.id.toString()}>
                        {cidade.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleEditAddress(endereco.id)}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EnderecoTable;
