import React, { useContext } from 'react'
import { Table, TableRow } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import * as Styles from "../../../components/table/styles";
import {ReactComponent as EditIcon} from "../../../assets/icons/EditIcon.svg";
import { IConsultaContratanteTabela } from '../types'
import moment from 'moment';
import { HomeContext } from '../../../contexts/homeContext';
import { useNavigate } from 'react-router-dom';

interface IConsultarPerfilTable {
  tableData: IConsultaContratanteTabela[]
  allowEdit?: boolean
}

const ConsultaContratanteTabela = ({tableData, allowEdit = true}: IConsultarPerfilTable) => {

  const { setContratanteSelected } = useContext(HomeContext)

  const navigate = useNavigate()

  const header = [
    'Status',
    'ID',
    'Nome Contratante',
    'Tipo Contratante',
    'Código Contratante',
    'Data de Criação',
    'Criado Por',
    'Data de Modificação',
    'Modificado Por',
    'Editar'
  ]

  const handleEditContratante = (contratante: IConsultaContratanteTabela) => {
    setContratanteSelected(contratante)
    navigate('/EditarContratante')
  }

  const formatPessoa = (pessoa: string) => {
    if (pessoa === 'pf') {
      return 'FÍSICA'
    } else {
      return 'JURÍDICA'
    }
  }

  return(
    <TableContainer component={Styles.StyledPaper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {header?.map((title) => 
              <Styles.StyledTableCell className={`${title} center`} key={title}>{title}</Styles.StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map(item => (
            <TableRow key={item?.id}>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.ativo ? 'Ativo' : 'Inativo'}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.id}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.nomeFantasia}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {formatPessoa(item?.tipo)}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.codigo}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.dataCriacao && moment(item?.dataCriacao)?.format('DD/MM/YYYY - HH:mm:ss')} 
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.usuarioCriacao}
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.dataModificacao && moment(item?.dataModificacao)?.format('DD/MM/YYYY - HH:mm:ss')} 
              </Styles.StyledTableCell>
              <Styles.StyledTableCell component="th" scope="row">
                {item?.usuarioModificacao}
              </Styles.StyledTableCell>
             
              <Styles.StyledTableCell className='center' component="th" scope="row">
                {!!allowEdit ?
                  <EditIcon onClick={() => handleEditContratante(item)} style={{ cursor: 'pointer' }} />
                  :
                  <EditIcon style={{ cursor: 'not-allowed', opacity: 0.5 }} />
                }
              </Styles.StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ConsultaContratanteTabela