import { apiPost, apiPut, apiGet } from "../../../services/api";
import { IPostConsultarOSs, IPostCidade, IPutCidade, IGetCidades } from "../types";

const baseUrlOrdemServico = process.env.REACT_APP_API_ORDEMSERVICO;

export const consultarOSs = async (params: IPostConsultarOSs) =>
    await apiPost(`${baseUrlOrdemServico}/OrdemServico_/consultar-os`, params);

export const getListaCidades = async (idEstado:number) =>
    await apiGet(`${baseUrlOrdemServico}/cidades?Ativo=true&IdEstado=${idEstado}`);

export const getListaEstados = async () =>
    await apiGet(`${baseUrlOrdemServico}/estados`);

export const getListaClientes = async (nomeFantasia: string,page:number) =>
    await apiGet(`${baseUrlOrdemServico}/cliente/clientes-menu-paginado?NomeFantasia=${nomeFantasia}&PageNumber=${page}&PageSize=20`)


export const getListaGrupoServico = async (params?: any) =>
    await apiGet(`${baseUrlOrdemServico}/GrupoServico_?CarregarServicos=true`, params);

export const criarOs = async (params:any) =>
    await apiPost(`${baseUrlOrdemServico}/OrdemServico_/cria-ordemservico-manual`, params);