import React, { useState, useEffect, useContext } from "react";
import {
  Section,
  Body,
} from "./consultaParametro_styles";
import "./consultaParametro.scss";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/auth";
import { getContratantesNovo } from "../../farol/services";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import { Grupo_Servico, IServico } from "../../../contexts/types/farolTypes";
import { getGroup_Services } from "../../grupoServico/services/services";
import { IContratantesDTO } from "../../contratante/types";
import { IGetContratanteServicoParametros, IGetResponseContratanteServicoParametro, IGetResponseContratanteServicoParametros, IGetResponseParametro } from "../types";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";
import { getContratanteServicoParametrosComFiltro, getParametros } from "../services/services";
import { AxiosResponse } from "axios";
import { smoothScroll } from "../../../helpers/smoothScroll";
import { getPrestadoresAtivos } from '../../farol/services';
import { IGetResponsePrestadoresOrdemServico } from "../../farol/types";
import _, { set } from "lodash";
import BasicRichTreeView from "../../../components/treeView/treeView";
import FormParametro from "../../../components/formParametro/formParametro";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import FormBreadcrumb from "../../../components/formBreadcrumb/formBreadcrumb";

interface SelectedNode {
  id: string;
  type: 'contratante' | 'grupoServico' | 'prestador' | 'servico' | 'parametro';
  path: string[];
  data?: any;
}

const ConsultaParametro = () => {
  const { setMenuLateral, setContratanteServicoParametroSelecionado: setContratanteServicoParametroSelecionado } = useContext(HomeContext);

  const { user, funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consultar Parâmetros", funcionalidade);

  const navigate = useNavigate();

  const [disableActions, setDisableActions] = useState(false);

  const [listaContratantes, setListaContratantes] = useState<any[]>([]);
  const [listaPrestadores, setListaPrestadores] = useState<any[]>([]);
  const [listaGrupoServico, setListaGrupoServico] = useState<Grupo_Servico[]>([]);
  const [listaServicos, setListaServicos] = useState<IServico[]>([]);
  const [listaParametros, setListaParametros] = useState<IAutocompleteValue>([]);

  const [filtroContratante, setFiltroContratante] = useState<any[]>([]);
  const [filtroPrestadores, setFiltroPrestadores] = useState<any[]>([]);
  const [filtroGrupoServico, setFiltroGrupoServico] = useState<any[]>([]);
  const [filtroServico, setFiltroServico] = useState<any[]>([]);
  const [filtroParametro, setFiltroParametro] = useState<IAutocompleteValue>([]);

  const [dadosConsultaParametro, setDadosConsultaParametro] = useState<any[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
  const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
  const [openAlert, setOpenAlert] = useState(false);
  const [treeData, setTreeData] = useState<any[]>([]);

  const [showForm, setShowForm] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [selectedNode, setSelectedNode] = useState<SelectedNode | null>(null);

  const handleSearch = async () => {
    setLoading(true);
    setDisableActions(true);

    const params: IGetContratanteServicoParametros = {
      ...(!!filtroContratante.length && !!filtroContratante[0]?.id ? { 'IdContratante': Number(filtroContratante[0].id) } : {}),
      ...(!!filtroPrestadores.length && !!filtroPrestadores[0]?.id ? { 'IdPrestador': Number(filtroPrestadores[0].id) } : {}),
      ...(!!filtroGrupoServico.length && !!filtroGrupoServico[0]?.id ? { 'IdGrupoServico': Number(filtroGrupoServico[0].id) } : {}),
      ...(!!filtroServico.length && !!filtroServico[0]?.id ? { 'IdServico': Number(filtroServico[0].id) } : {}),
      ...(!!filtroParametro.length && !!filtroParametro[0]?.id ? { 'IdParametro': Number(filtroParametro[0].id) } : {}),
      PageNumber: currentPage,
      PageSize: pageSize
    };


    try {
      const { data }: { data: IGetResponseContratanteServicoParametros } = await getContratanteServicoParametrosComFiltro(params) as AxiosResponse;
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaParametro(data?.data?.items ?? []);
      setQtdeRegistros(data?.data?.totalCount ?? 0);

    } catch (error) {
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaParametro([]);
      setQtdeRegistros(0);
      setTipoMsgAlert("error");
      setMsgAlert("Nenhum dado encontrado");
      setOpenAlert(true);
    }
  };

  const handleGetAllParametros = async () => {
    setLoading(true);
    setDisableActions(true);

    try {
      // Chama a API sem paginação ou com um número grande de registros
      const { data }: { data: IGetResponseContratanteServicoParametros } = await getContratanteServicoParametrosComFiltro({
        PageNumber: 1,
        PageSize: 1000 // Um número grande para pegar todos os registros
      }) as AxiosResponse;

      setLoading(false);
      setDisableActions(false);
      setDadosConsultaParametro(data?.data?.items ?? []);
      setQtdeRegistros(data?.data?.totalCount ?? 0);

    } catch (error) {
      setLoading(false);
      setDisableActions(false);
      setDadosConsultaParametro([]);
      setQtdeRegistros(0);
      setTipoMsgAlert("error");
      setMsgAlert("Nenhum dado encontrado");
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    if (dadosConsultaParametro.length > 0) {
      setTreeData(dadosConsultaParametro); // Passa os dados brutos diretamente
    }
  }, [dadosConsultaParametro]);

  const localGetContratantes = async () => {
    return await getContratantesNovo()
      .then((res: any) => {
        const respSuccess = res?.data as ResponseApi<IContratantesDTO[]> | null;

        if (respSuccess) {
          const listaContratante = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id?.toString() ?? "", idOppay: item.id_Oppay?.toString() ?? "" } }) || [];
          setListaContratantes(filtraContratantesDoUsuario(listaContratante));
        };

      }, err => {
        const respErr = err?.response?.data as ResponseApi<IContratantesDTO[]>;
        if (respErr) setListaContratantes([]);
      });
  };

  const filtraContratantesDoUsuario = (contratantes: IAutocompleteValue) => {
    let options = [...contratantes];

    if (!!user?.contratanteOrdemServico) {
      options = options.filter((o: any) => Number(o.idOppay) === user.contratanteOrdemServico);

      setFiltroContratante(options);
    }

    return options;
  };

  const handleGetGruposServicos = async () => {
    await getGroup_Services({
      Ativo: true,
    }).then((resp: any) => {
      const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;
      const grupos = respSuccess?.data as any[] ?? [];
      setListaGrupoServico(grupos);
    }, err => {
      const respErr = err?.response?.data as ResponseApi<any>;
      // Implementar tratamento de erro
    });
  };

  const getProvider = async () => {
    try {
      const { data }: { data: IGetResponsePrestadoresOrdemServico } = await getPrestadoresAtivos() as AxiosResponse;

      let prestadores: IAutocompleteValue = [];

      if (!!data?.success && !_.isEmpty(data?.data?.prestadores)) {
        prestadores = data?.data?.prestadores.map((m) => ({ ...m, name: m.nomePrestador, id: m.id?.toString() }))
          .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      };

      setListaPrestadores(filtraPrestadoresDoUsuario(prestadores));

    } catch (e) {
      setListaPrestadores([]);
    }
  };

  const filtraPrestadoresDoUsuario = (prestadores: any) => {
    let options: any[] = [...prestadores];

    if (!!user?.prestadores && !!user.prestadores.length) {
      options = options.filter((o: any) => user.prestadores.includes(Number(o.id)));

      /**Auto preenche o filtro prestadores */
      if (user.prestadores.length === 1) {
        setFiltroPrestadores(options);
      }
    }

    return options;
  };

  const handleParametros = async () => {
    try {

      await getParametros().then((resp: any) => {
        const respSuccess = resp?.data as ResponseApi<IGetResponseParametro[]> | null;

        if (respSuccess) {
          const parametros = respSuccess.data?.map((item: any) => { return { name: item.nome, id: item.id?.toString() ?? "" } }) || [];
          setListaParametros(parametros);
        };
      });
    } catch (e) {
      setListaParametros([])
    }
  }

  const handleNodeToggle = (nodeIds: string[]) => {
    setExpandedNodes(nodeIds);
  };

  useEffect(() => {
    handleGetAllParametros();
    localGetContratantes();
    getProvider();
    handleGetGruposServicos();
    handleParametros();
    setContratanteServicoParametroSelecionado({} as IGetResponseContratanteServicoParametro)
  }, []);

  useEffect(() => {
    if (!!dadosConsultaParametro.length) smoothScroll(document.getElementById("TabelaResultadoConsultaParametro"));
  }, [dadosConsultaParametro]);

  const handleNodeSelect = (nodeId: string, nodeType: string) => {
    // Remove o prefixo do nodeId se necessário
    const actualId = nodeType === 'parametro' ? Number(nodeId.replace('p_', '')) : nodeId;

    const itemSelecionado = dadosConsultaParametro.find(item => nodeType === 'parametro' && item.id === actualId);

    if (itemSelecionado) {
      setShowForm(true);
      setContratanteServicoParametroSelecionado(itemSelecionado);
      const path = [
        'Parâmetro',
        itemSelecionado.contratante,
        itemSelecionado.grupoServico,
        itemSelecionado.servico,
        itemSelecionado.parametro
      ];

      if (itemSelecionado.prestador)
        path.splice(2, 0, itemSelecionado.prestador);

      setSelectedNode({
        id: nodeId,
        type: nodeType as 'contratante' | 'grupoServico' | 'servico' | 'parametro' | 'prestador',
        path,
        data: itemSelecionado
      });
    }
  };

  const handleSaveParametro = (updatedData: any) => {
    setDadosConsultaParametro(prevData =>
      prevData.map(item =>
        item.id === updatedData.id ? { ...item, ...updatedData } : item
      )
    );
  };

  const handleEditSuccess = () => {
    setShowForm(false);
    setSelectedNode(null);
    setAlertMessage("Parâmetro editado com sucesso!");
    setShowAlert(true);
    setExpandedNodes([]);
    handleGetAllParametros();
  }

  return (
    <Section id={"ConsultaParametro"}>
      <Header setMenuLateral={() => setMenuLateral(true)} title="Parâmetro" />
      <Breadcrumb>
        <BreadcrumbItem>Parâmetro</BreadcrumbItem>
      </Breadcrumb>
      <AlertMessage
        isOpenAlert={showAlert}
        setOpenAlert={setShowAlert}
        alertType="success"
        msgAlert={alertMessage}
      />
      <Body>
        <div style={{ display: 'flex', gap: '24px', width: '100%' }}>
          <div className="ViewTreeParametro">
            <BasicRichTreeView
              dados={dadosConsultaParametro || []}
              onSelectNode={handleNodeSelect}
              expanded={expandedNodes}
              onNodeToggle={handleNodeToggle}
            />
          </div>

          {/* Aqui vai o trecho do formulário */}
          {selectedNode && (
            <div className="FormParametroContainer">
              {/* Breadcrumb do formulário */}
              <FormBreadcrumb path={selectedNode.path} />

              {selectedNode && showForm && (
                <div>
                  {/* Formulário baseado no tipo do nó */}
                  {selectedNode.type === 'parametro' && (
                    <FormParametro onEditSuccess={handleEditSuccess} />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Body>
    </Section>
  );
};

export default ConsultaParametro;
