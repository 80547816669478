import React, { useContext, useEffect, useState } from "react";
import { HomeContext } from "../../../contexts/homeContext";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Form, Section } from "./styles";
import Header from "../../../components/header/header";
import { CircularProgress, Grid, Stack } from "@mui/material";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import Button from "../../../components/Button/styles";
import { FormikSubmitHandler } from "../../../types";
import * as yup from 'yup';
import StickyTable from "../../../components/stickyTable/stickyTable";
import { AuthContext } from "../../../contexts/auth";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import Pagination from "../../../components/pagination/paginacao";
import { smoothScroll } from "../../../helpers/smoothScroll";
import { getListaJustificativaAbonoComFiltro } from "../services";
import { IGetJustificativaAbono, IGetResponseJustificativasAbono } from "../types";
import { AxiosResponse } from "axios";
import { STATUS_OPTIONS, TIPO_OPTIONS } from "../utils";

const consultAllowanceJustificationeSchema = yup.object({
    status: yup.array().required(),
    tipo: yup.array().required(),
    contratante: yup.array().required(),
});

type ConsultAllowanceJustificationValues = yup.InferType<typeof consultAllowanceJustificationeSchema>;

const CONSULT_ALLOWANCE_JUSTIFICATION_INITIAL_VALUES: ConsultAllowanceJustificationValues = {
    status: [STATUS_OPTIONS[0]],
    tipo: [TIPO_OPTIONS[0]],
    contratante: []
};

export function ConsultAllowanceJustificationPage() {
    const { localGetContratantes, listaContratante, setJustificativaAbonoSelecionada, setMenuLateral } = useContext(HomeContext);

    const navigate = useNavigate();
    const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consultar Justificativas de Abono", funcionalidade);

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
    const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);

    const [disableActions, setDisableActions] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const [dadosConsulta, setDadosConsulta] = useState<any[]>([]);
    const [qtdeRegistros, setQtdeRegistros] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const [groupedContratantesObject, setGroupedContratantesObject] = useState<any>({});

    const createGroupedContratantesObject = (data: { id: string, name: string}[]) => {
        const dataObj = data.reduce((inicial, item) => {
            if (!inicial[item.id]) inicial[item.id] = item.name;
            return inicial;
        }, {} as any);

        setGroupedContratantesObject(dataObj);
    };

    const handleFormikSubmit: FormikSubmitHandler<ConsultAllowanceJustificationValues> = (values, actions) => {
        handleSearch(values);
    };

    const formik = useFormik({
        initialValues: CONSULT_ALLOWANCE_JUSTIFICATION_INITIAL_VALUES,
        onSubmit: handleFormikSubmit
    });

    const handleSearch = async (values: ConsultAllowanceJustificationValues) => {
        setCurrentPage(1);
        setOpenAlert(false);
        setLoading(true);
        setDisableActions(true);

        const statusBool = (!values.status[0] || values.status[0].id === '') ? '' : values.status[0].id === '0' ? true : false;

        const params: IGetJustificativaAbono = {
            ...(statusBool !== '' ? { 'Status': statusBool } : {}),
            ...(!!values.tipo.length && !!values.tipo[0]?.name ? { 'Tipo': values.tipo[0].name } : {}),
            ...(values.tipo[0]?.id === '2' && !!values.contratante.length && !!values.contratante[0]?.id ? { 'IdContrante': Number(values.contratante[0].id) } : {}),
        };

        try {
            const { data }: { data: IGetResponseJustificativasAbono } = await getListaJustificativaAbonoComFiltro(params) as AxiosResponse;

            setLoading(false);
            setDisableActions(false);
            setDadosConsulta(data ?? []);
            setQtdeRegistros(data?.length ?? 0);
        } catch (e: any) {
            setLoading(false);
            setDisableActions(false);
            setDadosConsulta([]);
            setQtdeRegistros(0);
            setTipoMsgAlert("error");
            if (e?.response?.data && typeof e?.response?.data === "string") {
                setMsgAlert(e?.response?.data);
            } else {
                setMsgAlert("Nenhum dado encontrado");
            }
            setOpenAlert(true);
        };
    };

    const dadosFormatados = () => {
        let dados = [...dadosConsulta];

        /**Formata campos para tabela */
        dados = dados.map((item: any) => {
            return {
                status: !!item.status ? "Ativo" : "Inativo",
                id: item.id ?? "",
                codigo: item.codigo ?? "",
                codigoSap: item.codigoSap ?? "",
                tipo: item.tipo,
                contratante: groupedContratantesObject[item.idContrante] ?? "",
            };
        });

        return paginacaoLocal(dados);
    };

    const paginacaoLocal = (dados: any[]) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return dados.slice(firstPageIndex, lastPageIndex);
    };

    const handleClickItemEdit = (data: any) => {
        setJustificativaAbonoSelecionada(getItemByID(data.id)); 
        navigate("/EditarJustificativaAbono");   
    };

    const getItemByID = (ID: number | string) => {
        let resultadoDados = [...dadosConsulta];

        return resultadoDados.find(
            (item) => item.id === ID
        )!;
    };

    const objTraducaoHeader = {
        status: 'Status',
        id: 'ID',
        codigo: 'Código',
        codigoSap: 'Código SAP',
        tipo: 'Tipo',
        contratante: 'Contratante'
    } as any;

    useEffect(() => {
        localGetContratantes();
    }, []);
    
    useEffect(() => {
        createGroupedContratantesObject(listaContratante);
    }, [listaContratante]);

    useEffect(() => {
        if (!!dadosConsulta.length) smoothScroll(document.getElementById('TabelaResultadoConsultaJustificativasAbono'));
    }, [dadosConsulta]);

    return (
        <Section id={"consult-allowance-justification"}>
            <Header
                setMenuLateral={() => setMenuLateral(true)}
                title="Consultar Justificativa de Abono"
            />

            <Stack width="100%" bgcolor="#F9F9F9" px="1.625rem" py="2.625rem" flexDirection="column" rowGap="1rem" borderRadius="1.75rem">
                <Form onSubmit={formik.handleSubmit}>
                    <Grid container width="100%" spacing={4}>
                        <Grid item xs={12} md={4}>
                            <AutocompleteMultiple
                                noOptionsText={"Nenhum status encontrado"}
                                options={STATUS_OPTIONS}
                                value={formik.values.status}
                                onChange={(e: any) => formik.setFieldValue('status', e)}
                                id="multiple-checkbox-status"
                                multiple={false}
                                dimension='sm'
                                name="status"
                                label='Status'
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <AutocompleteMultiple
                                noOptionsText={"Nenhum tipo encontrado"}
                                options={TIPO_OPTIONS}
                                value={formik.values.tipo}
                                onChange={(e: any) => formik.setFieldValue('tipo', e)}
                                id="multiple-checkbox-tipo"
                                multiple={false}
                                dimension='sm'
                                name="tipo"
                                label='Tipo'
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <AutocompleteMultiple
                                noOptionsText={"Nenhum contratante encontrado"}
                                options={listaContratante}
                                value={formik.values.contratante}
                                onChange={(e: any) => formik.setFieldValue('contratante', e)}
                                id="multiple-checkbox-contratante"
                                multiple
                                dimension='sm'
                                name="contratante"
                                label='Contratante'
                                disabled={formik.values.tipo[0] && formik.values.tipo[0]?.id !== '2'}
                                allSelected
                                limitTags={3}
                            />
                        </Grid>
                    </Grid>

                    <Stack direction="row" columnGap="1.5rem">
                        <Button
                            dimension='sm'
                            variant='primary'
                            margin='0'
                            width="12rem"
                            type='submit'
                            disabled={loading}
                        >
                            {loading ?
                                <Stack direction="row" alignItems="center" columnGap="1rem">
                                    Consultando...
                                    <CircularProgress size={14} sx={{ color: '#fff' }} />
                                </Stack> : 'Consultar'}
                        </Button>

                        <Button
                            type="button"
                            dimension='sm'
                            variant='grey'
                            margin='0'
                            style={!!profileHasPermission('Novo Registro') ? { cursor: 'not-allowed', opacity: 0.5 } : {}}
                            onClick={() => {
                                if (!profileHasPermission('Novo Registro')) {
                                    setJustificativaAbonoSelecionada(null);
                                    navigate('/CadastrarJustificativaAbono')
                                }
                            }}
                        >
                            Incluir justificativa
                        </Button>
                    </Stack>
                </Form>
            </Stack>

            <AlertMessage
                isOpenAlert={openAlert}
                setOpenAlert={setOpenAlert}
                alertType={tipomsgAlert}
                msgAlert={msgAlert}
            />

            {!!dadosConsulta.length && (
                <React.Fragment>
                    <StickyTable
                        id={"TabelaResultadoConsultaJustificativasAbono"}
                        data={dadosFormatados()}
                        handleClickEdit={handleClickItemEdit}
                        objTraducaoHeader={objTraducaoHeader}
                        allowEdit={!disableActions && !!profileHasPermission("Editar Registro")}
                        edit={true}
                        labelEdit={"Detalhes"}
                    />

                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={qtdeRegistros}
                        pageSize={pageSize}
                        setPageSize={(value: string) => setPageSize(Number(value))}
                        onPageChange={(page: number) => setCurrentPage(Number(page))}
                        showLabel={false}
                    />
                </React.Fragment>
            )}
        </Section>
    );
};
