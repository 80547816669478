import { useEffect, useContext } from 'react'
import './geralTab.scss'
import Input from '../../../components/Input/Input';
import _ from 'lodash'
import Checkbox from '../../../components/checkbox/checkbox';
import { HomeContext } from '../../../contexts/homeContext';
import { IIncluirEditarContratanteInitialValues } from '../types';
import { FormikErrors, FormikTouched } from 'formik';

interface GeralTabProps {
  formik: {
    values: IIncluirEditarContratanteInitialValues;
    resetForm: () => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleBlur: (e: React.FocusEvent<any>) => void;
    isValid: boolean;
    errors: FormikErrors<IIncluirEditarContratanteInitialValues>;
    touched: FormikTouched<IIncluirEditarContratanteInitialValues>;
  };
  isIncluding: boolean;
};

const ConfiguracaoTab: React.FC<GeralTabProps> = ({ formik, isIncluding }) => {

  const { contratanteSelected } = useContext(HomeContext);
  
  useEffect(() => {
    if (!isIncluding) {
      // setCodSAP(contratanteSelected?.codSAP || "")
      formik.setFieldValue("fornecedorSAP", contratanteSelected?.fornecedorSAP || "")
      formik.setFieldValue("wsLogin", contratanteSelected?.wsLogin || "")
      formik.setFieldValue("wsPass", contratanteSelected?.wsPass || "")
      formik.setFieldValue("integracaoSAP", contratanteSelected?.integracaoSAP || false)
      formik.setFieldValue("integraAbono", contratanteSelected?.integraAbono || false)
      formik.setFieldValue("sincronizacaoFoto", contratanteSelected?.sincronizacaoFoto || false)
      formik.setFieldValue("validaTrocaNumeroSerie", contratanteSelected?.validaTrocaNumeroSerie || false)
      formik.setFieldValue("mobileControleDeChip", contratanteSelected?.mobileControleDeChip || false)
      formik.setFieldValue("mobileBaixaAutomaticaTodosServicos", contratanteSelected?.mobileBaixaAutomaticaTodosServicos || false)
      formik.setFieldValue("mobileBaixaAutomaticaServicosIndividuais", contratanteSelected?.mobileBaixaAutomaticaServicosIndividuais || false)
      formik.setFieldValue("habilitaChatbotInsucesso", contratanteSelected?.habilitaChatbotInsucesso || false)
      formik.setFieldValue("habilitaChatbotTracking", contratanteSelected?.habilitaChatbotTracking || false)
    }
  }, [])

  return (
    <div className='ConfiguracaoTabContainer'>
      <div className='NormalGrid'>
        <Input
          value={formik.values.wsLogin}
          onChange={(e) => formik.setFieldValue("wsLogin", e.target.value)}
          name='wsLogin'
          label='WS Login'
          dimension='sm'
          id='WSLogin'
        />
        <Input
          value={formik.values.wsPass}
          name='wsPass'
          onChange={(e) => formik.setFieldValue("wsPass",e.target.value)}
          label='WS Pass'
          dimension='sm'
          id='WSPass'
        />
        <Input
          value={formik.values.fornecedorSAP}
          onChange={(e) => formik.setFieldValue("fornecedorSAP", e.target.value)}
          name='fornecedorSAP'
          label='Fornecedor SAP'
          dimension='sm'
          id='FornecedorSAP '
        />
      </div>
      <div className='CheckboxGrid'>
        <Checkbox
          id={"IntegracaoGetnetSAP"}
          name='integracaoSAP'
          boxclassName={"IntegracaoGetnetSAP"}
          label="Integração Getnet SAP"
          value={formik.values?.integracaoSAP || false}
          onChange={() => {
            console.log(formik.values.integracaoSAP)
            formik.setFieldValue("integracaoSAP", !formik.values.integracaoSAP)
          }}
        />
        <Checkbox
          id={"IntegraAbono"}
          boxclassName={"IntegraAbono"}
          name={"integraAbono"}	
          label="Integra Abono"
          value={formik.values?.integraAbono || false}
          onChange={() => formik.setFieldValue("integraAbono", !formik.values.integraAbono)}
        />
        <Checkbox
          id={"SincronizacaoFoto"}
          boxclassName={"SincronizacaoFoto"}
          label="Sincronização de Foto"
          name='sincronizacaoFoto'
          value={formik.values?.sincronizacaoFoto || false}
          onChange={() => formik.setFieldValue("sincronizacaoFoto", !formik.values?.sincronizacaoFoto)}
        />
        <Checkbox
          id={"ValidaTrocaNumeroSerie"}
          boxclassName={"ValidaTrocaNumeroSerie"}
          name='validaTrocaNumeroSerie'
          label="Valida Troca de Número de Série"
          value={formik.values?.validaTrocaNumeroSerie || false}
          onChange={() => formik.setFieldValue("validaTrocaNumeroSerie", !formik.values?.validaTrocaNumeroSerie)}
        />
        <Checkbox
          id={"MobileControleChip"}
          boxclassName={"CriaTriagem"}
          label="Mobile - Controle de Chip"
          name='mobileControleChip'
          value={formik.values?.mobileControleChip || false}
          onChange={() => formik.setFieldValue("mobileControleChip", !formik.values?.mobileControleChip)}
        />
        <Checkbox
          id={"BloqueiaReversaNaoKit"}
          boxclassName={"BloqueiaReversaNaoKit"}
          name='mobileBaixaAutomaticaTodosServicos'
          label="Mobile - Baixa Automática (Todos os Serviços)"
          value={formik.values?.mobileBaixaAutomaticaTodosServicos || false}
          onChange={() => formik.setFieldValue("mobileBaixaAutomaticaTodosServicos", !formik.values?.mobileBaixaAutomaticaTodosServicos)}
        />
        <Checkbox
          id={"MobileBaixaAutomaticaServicosIndividuais"}
          boxclassName={"MobileBaixaAutomaticaServicosIndividuais"}
          name='mobileBaixaAutomaticaServicosIndividuais'
          label="Mobile - Baixa Automática (Serviços Individuais)"
          value={formik.values?.mobileBaixaAutomaticaServicosIndividuais || false}
          onChange={() => formik.setFieldValue("mobileBaixaAutomaticaServicosIndividuais", !formik.values?.mobileBaixaAutomaticaServicosIndividuais)}
        />

        <Checkbox
          id={"HabilitaChatbotInsucesso"}
          boxclassName={"HabilttaChatbotInsucesso"}
          name='habilitaChatbotInsucesso'
          label="Habilita Chatbot Insucesso"
          value={formik.values?.habilitaChatbotInsucesso || false}
          onChange={() => formik.setFieldValue("habilitaChatbotInsucesso", !formik.values?.habilitaChatbotInsucesso)}
        />
        <Checkbox
          id={"HabilitaChatbotTracking"}
          boxclassName={"HabilitaChatbotTracking"}
          label="Habilita Chatbot Tracking"
          name='habilitaChatbotTracking'
          value={formik.values?.habilitaChatbotTracking || false}
          onChange={() => formik.setFieldValue("habilitaChatbotTracking", !formik.values?.habilitaChatbotTracking)}
        />
      </div>
    </div>
  )
}

export default ConfiguracaoTab