import { useState } from "react";
import { IGetResponseJustificativaAbono } from "../pages/justificativaAbono/types";
import { IJustificativaAbonoContext } from "./types/justificativaAbonoTypes";

export const JustificativaAbonoContext = () => {
  const [justificativaAbono, setJustificativaAbono] = useState({} as IGetResponseJustificativaAbono);

  return {
    justificativaAbonoSelecionada: justificativaAbono,
    setJustificativaAbonoSelecionada: setJustificativaAbono
  } as IJustificativaAbonoContext;
};