import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    TextField,
    Button,
    SelectChangeEvent,
    CircularProgress,
    IconButton,
    FormHelperText,
} from "@mui/material";
import {
    consultaJustificativaAbonoPorContratante,
    justificativaAbono,
    obterAbono,
    salvarJustificativaAbono,
} from "../services";
import { AxiosResponse } from "axios";
import { DadosSelectJustificatia, IAbono } from "../types";
import AlertMessage from "../../../components/AlertMessage/alertMessage";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

interface AbonoModalProps {
    open: boolean;
    onClose: () => void;
    idContratante: number;
    numeroChamado: number;
}
interface FormErrors {
    statusAbono?: string;
    previsao?: string;
    abonadoPaytec?: string;
    abonadoContratante?: string;
    justificativa?: string;
    observacao?: string;
}
const AbonoModal: React.FC<AbonoModalProps> = ({
                                                   open,
                                                   onClose,
                                                   idContratante,
                                                   numeroChamado,
                                               }) => {
    const [previsao, setPrevisao] = useState<string>(
        new Date().toISOString().split("T")[0]
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [podeJustificarAbono, setPodeJustificarAbono] =
        useState<boolean>(false);
    const [podeAprovarAbono, setPodeAprovarAbono] = useState<boolean>(false);
    const [pendenteAbono, setPendenteAbono] = useState<boolean>(false);
    const [statusAbono, setStatusAbono] = useState<DadosSelectJustificatia[]>([]);
    const [abonoId, setAbonoId] = useState<number>(0);
    const [justificativaAbonoId, setJustificativaAbonoId] = useState<number>(0);
    const [statusAbonoSelecionadoId, setStatusAbonoSelecionadoId] =
        useState<number>(0);
    const [abonadoContratante, setAbonadoContratante] = useState<boolean | undefined>(undefined);

    const [abonadoPaytec, setAbonadoPaytec] = useState<boolean | undefined>(undefined);
    const [descricaoAbono, setDescricaoAbono] = useState<string>("");
    const [dadosJustificativa, setDadosJustificativa] = useState<
        DadosSelectJustificatia[]
    >([]);
    const [msgAlertError, setMsgAlertError] = useState<string>(
        "Falha na operação, tente novamente."
    );
    const [errors, setErrors] = useState<FormErrors>({});
    const [alertType, setAlertType] = React.useState<
        "error" | "success" | "info" | "warning" | undefined
    >("error");
    const [openAlertError, setOpenAlertError] = useState(false);
    const handlePrevisaoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrevisao(event.target.value);
    };
    const handleAbonadoContratanteChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setAbonadoContratante(event.target.value === "true");
    };
    const handleObservacaoAbonoChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setDescricaoAbono(event.target.value);
    };
    const handleAbonadoPaytecChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setAbonadoPaytec(event.target.value === "true");
    };
    const obterJustificativa = async () => {
        setLoading(true);
        try {
            var consulta = (await consultaJustificativaAbonoPorContratante(
                idContratante
            )) as AxiosResponse;
            setDadosJustificativa(consulta.data as DadosSelectJustificatia[]);
            setLoading(false);
        } catch (error) {
            setAlertType("error");
            setMsgAlertError("Nenhuma justificativa de abono encontrada.");
            setOpenAlertError(true);
            setDadosJustificativa([]);
            setLoading(false);
        }
    };

    const obterStatusAbono = async () => {
        setLoading(true);
        try {
            var consulta = (await justificativaAbono()) as AxiosResponse;
            setStatusAbono(consulta.data as DadosSelectJustificatia[]);
            setLoading(false);
        } catch (error) {
            setAlertType("error");
            setMsgAlertError("Erro ao buscar dados:");
            setStatusAbono([]);
            setOpenAlertError(true);
            setLoading(false);
        }
    };

    const handleSelectJustificativa = (e: SelectChangeEvent<number | string>) => {
        const { value } = e.target;
        const numericValue = Number(value);
        setJustificativaAbonoId(numericValue);
    };
    const handleSelectStatus = (e: SelectChangeEvent<number | string>) => {
        const { value } = e.target;
        const numericValue = Number(value);
        setStatusAbonoSelecionadoId(numericValue);
    };
    const obterAbonoOs = async () => {
        setLoading(true);
        try {
            var request = (await obterAbono(numeroChamado)) as AxiosResponse;
            const dados = request.data as IAbono;
            setAbonoId(dados.id);
            setStatusAbonoSelecionadoId(dados.statusAbono);
            if(dados.id !== 0)
                setPendenteAbono(dados.statusAbono == 2);
            else setPendenteAbono(true);
            setDescricaoAbono(dados.descricao);
            setJustificativaAbonoId(dados.justificativaAbonoId);
            setAbonadoContratante(dados.id > 0 ? dados.abonadoContratante : undefined);
            setAbonadoPaytec(dados.id > 0 ? dados.abonadoPaytec : undefined);
            setPrevisao(dados.previsao.toString().split("T")[0]);
            setPodeAprovarAbono(dados.podeAprovarAbono);
            setPodeJustificarAbono(dados.podeJustificarAbono);
            setLoading(false);
        } catch (error: any) {
            setAbonoId(0);
            setPendenteAbono(false)
            setStatusAbonoSelecionadoId(0);
            setDescricaoAbono("");
            setJustificativaAbonoId(0);
            setAbonadoContratante(undefined);
            setAbonadoPaytec(undefined);
            setPrevisao(new Date().toISOString().split("T")[0]);
            setPodeAprovarAbono(false);
            setPodeJustificarAbono(false);

            setLoading(false);
            if(error?.response?.data?.detail){
                setAlertType("info");
                setMsgAlertError(error?.response?.data?.detail);
            }
            else {
                setAlertType("error");
                setMsgAlertError("Falha ao obter abono");
            }
            setOpenAlertError(true);
        }
    };
    const clearValidateForm = () => {
        obterAbonoOs();
        const newErrors: FormErrors = {};
        setErrors(newErrors);
        onClose();
    };
    const validateForm = (): boolean => {
        const newErrors: FormErrors = {};
        let isValid = true;

        if (!previsao.trim()) {
            newErrors.previsao = "A data de previsão é obrigatória";
            isValid = false;
        }

        if (justificativaAbonoId == 0) {
            newErrors.justificativa = "Justificativa é obrigatória";
            isValid = false;
        }
        if (descricaoAbono == null || descricaoAbono?.length == 0) {
            newErrors.observacao = "Observação é obrigatória";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };
    const handlerSalvar = async (e: React.FormEvent) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                setLoading(true);

                const data = moment(previsao, "YYYY-MM-DD").toDate();

                const dados = {
                    id: abonoId,
                    previsao: data,
                    abonadoPaytec,
                    abonadoContratante,
                    descricao: descricaoAbono,
                    justificativaAbonoId,
                    numeroChamado: numeroChamado,
                };
                const response = (await salvarJustificativaAbono(
                    dados
                )) as AxiosResponse;
                if (response.status === 200) {
                    if(abonoId == 2)
                        setPendenteAbono(true);
                    else setPendenteAbono(false);
                    obterAbonoOs();
                    setLoading(false);
                    setMsgAlertError("Abono salvo com sucesso!!");
                    setAlertType("success");
                    setOpenAlertError(true);
                } else {
                    setLoading(false);
                    setMsgAlertError("Falha ao salvar abono");
                    setOpenAlertError(true);
                    setPendenteAbono(false);
                    setAlertType("warning");
                    setOpenAlertError(true);
                }
            } catch (error: any) {
                setAlertType("error");
                if (error?.response?.data?.detail)
                    setMsgAlertError(error?.response?.data?.detail);
                else setMsgAlertError("Erro ao salvar abono");
                setLoading(false);
                setOpenAlertError(true);
                setPendenteAbono(false);
            }
        }
    };
    useEffect(() => {
        obterJustificativa();
        obterStatusAbono();
        obterAbonoOs();
    }, []);

    return (
        <Modal open={open}  aria-labelledby="abono-modal-title">
            <>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 1000,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    {!!loading && (
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "rgba(255, 255, 255, 0.7)",
                                zIndex: 1,
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    <Box sx={{ position: "absolute", right: 8, top: 8 }}>
                        <IconButton
                            aria-label="close"
                            onClick={clearValidateForm}
                            sx={{
                                color: "grey",
                                "&:hover": {
                                    bgcolor: "rgba(255, 255, 255, 0.1)",
                                },
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <AlertMessage
                        isOpenAlert={openAlertError}
                        setOpenAlert={setOpenAlertError}
                        alertType={alertType ?? "error"}
                        msgAlert={msgAlertError}
                    />
                    <Typography
                        id="abono-modal-title"
                        variant="h6"
                        component="h2"
                        gutterBottom
                    >
                        Abono
                    </Typography>
                    <hr style={{ borderColor: "rgba(255, 255, 255, 0.2)" }} />
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(5, 1fr)",
                            gap: 2,
                            mb: 2,
                        }}
                    >
                        <FormControl
                            fullWidth
                            sx={{ mb: 2 }}
                            error={!!errors.statusAbono}
                            required
                        >
                            <InputLabel>Status do Abono</InputLabel>
                            <Select
                                label="Status do Abono"
                                onChange={handleSelectStatus}
                                error={!!errors.statusAbono}
                                disabled={true}
                                value={statusAbonoSelecionadoId}
                            >
                                {statusAbono.map((c: any) => (
                                    <MenuItem key={c.id} value={c.id}>
                                        {c.descricao}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.statusAbono && (
                                <FormHelperText>{errors.statusAbono}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                            fullWidth
                            sx={{ mb: 2 }}
                            error={!!errors.previsao}
                            required
                        >
                            <TextField
                                fullWidth
                                label="Previsão"
                                type="date"
                                error={!!errors.previsao}
                                value={previsao}
                                disabled={!pendenteAbono || (!podeJustificarAbono || !podeAprovarAbono)}
                                onChange={handlePrevisaoChange}
                                InputLabelProps={{ shrink: true }}
                            />
                            {errors.previsao && (
                                <FormHelperText>{errors.previsao}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControl required fullWidth sx={{ mb: 2 }}>
                            <Typography variant="body2">Abonado Paytec</Typography>
                            <RadioGroup
                                row
                                value={abonadoPaytec}
                                onChange={handleAbonadoPaytecChange}
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Sim"
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="Não"
                                />
                            </RadioGroup>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }} required>
                            <Typography variant="body2">Abonado Contratante</Typography>
                            <RadioGroup
                                row
                                value={abonadoContratante}
                                onChange={handleAbonadoContratanteChange}
                            >
                                <FormControlLabel
                                    value={true}
                                    control={<Radio />}
                                    label="Sim"
                                />
                                <FormControlLabel
                                    value={false}
                                    control={<Radio />}
                                    label="Não"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <FormControl fullWidth sx={{ mb: 2 }} error={!!errors.justificativa}>
                        <Typography variant="body2">Justificativa do Abono</Typography>
                        <Select
                            name="justificativaAbonoId"
                            value={justificativaAbonoId}
                            error={!!errors.justificativa}
                            disabled={!pendenteAbono || (!podeJustificarAbono || !podeAprovarAbono)}
                            onChange={handleSelectJustificativa}
                            required
                        >
                            {dadosJustificativa.map((c: any) => (
                                <MenuItem key={c.id} value={c.id}>
                                    {c.descricao}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.justificativa && (
                            <FormHelperText>{errors.justificativa}</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 2 }} error={!!errors.observacao}>
                        <TextField
                            label="Observação do Abono"
                            multiline
                            error={!!errors.observacao}
                            rows={4}
                            fullWidth
                            required
                            variant="outlined"
                            sx={{ mb: 2 }}
                            value={descricaoAbono}
                            onChange={handleObservacaoAbonoChange}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingBottom: "10px",
                            }}
                        >
                            {descricaoAbono?.length > 200 ? (
                                <Typography color={"red"}>
                                    A observação do abono deve ter 200 caracteres, você digitou{" "}
                                    {descricaoAbono?.length} caracteres
                                </Typography>
                            ) : (
                                <></>
                            )}
                        </Box>
                        {errors.observacao && (
                            <FormHelperText>{errors.observacao}</FormHelperText>
                        )}
                    </FormControl>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            variant="contained"
                            onClick={handlerSalvar}
                            disabled={
                                loading ||
                                descricaoAbono?.length > 200 ||
                                !podeJustificarAbono ||
                                !podeAprovarAbono
                            }
                        >
                            Salvar
                        </Button>
                    </Box>
                </Box>
            </>
        </Modal>
    );
};

export default AbonoModal;
