import { useContext, useEffect, useState } from "react";
import { Section } from "./consultarTermoOs_styles";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/header/header";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "../../../components/breadcrumb/breadcrumb";
import { useFormik } from "formik";
import * as yup from "yup";
import "./incluirTermoOs.scss";
import MultipleSelectCheckmarksid from "../../../components/seletorMultiploId/seletorMultiploId";
import { TextAreaTermo } from "./textAreaTermoOs";
import Button from "../../../components/Button/styles";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "../../../components/collapse/collapse";
import { Grupo_Servico, IServico } from "../../../contexts/types/farolTypes";
import AutocompleteMultiple from "../../../components/autocompleteMultiple/autocompleteMultiple";
import { getGrouped_Services } from "../../farol/services";
import { ResponseApi } from "../../../contexts/types/responsesHttp";
import { getListaServicosComFiltro } from "../../servicos/services";
import { Alert, CircularProgress, IconButton } from "@mui/material";
import { TermoPostPut } from "../types";
import { AxiosResponse } from "axios";
import { salvarTermo } from "../services";

const IncluirTermoOs = () => {
  useEffect(() => {
    localGetContratantes();
    handleGetGroupesServices();
  }, []);
  const isIncluding = window.location.pathname.includes("IncluirTermoOs");
  const listaStatus = [
    { "name": "Ativo", "id": "1" },
    { "name": "Inativo", "id": "0" }
  ]
  const { setMenuLateral, localGetContratantes, listaContratante } =
  useContext(HomeContext);
  const [listaGrupoServico, setListaGrupoServico] = useState<Grupo_Servico[]>(
    []
  );
  const [listaServicos, setListaServicos] = useState<IServico[]>([]);
  const [disableActions, setDisableActions] = useState(false);
  const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [msgAlertError, setMsgAlertError] = useState("");
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const [msgAlertSuccess, setMsgAlertSuccess] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao(
    isIncluding ? "Incluir Termo de OS" : "Editar Termo de OS",
    funcionalidade);

  const navigate = useNavigate();

  const handleGetGroupesServices = async () => {
    await getGrouped_Services().then((resp: any) => {

      const respSuccess = resp?.data as ResponseApi<Grupo_Servico[]>;
      const grupos = respSuccess?.data as any[] ?? [];

      setListaGrupoServico(grupos);
    }, (err: any) => {
      const respErr = err?.response?.data as ResponseApi<any>;
      setMsgAlertError(respErr.errors[0].Message);
      setOpenAlertError(true);
    });
  };
  const handleChangeGrupoServico = (e: any) => {
    handleGetServicosPorGrupo(Number(e[0]?.id));
    return formik.setFieldValue('grupoServico', e);
  }
  const handleGetServicosPorGrupo = async (idGrupoServico: number) => {

    await getListaServicosComFiltro({ IdGrupoServico: idGrupoServico })
      .then((resp: any) => {
        const respSuccess = resp?.data as ResponseApi<IServico[]>;
        const servicos = respSuccess?.data as any[] ?? [];
        setListaServicos(servicos);
      }, err => {
        const respErr = err?.response?.data as ResponseApi<any>;
        setMsgAlertError(respErr.errors[0].Message);
        setOpenAlertError(true);
      });
  };
  const initialValues: any = {
    status: ["1"],
    termo: "",
    contratante: [],
    grupoServico: [],
    servico: [],
  };
  const validationSchema = yup.object({
    status: yup.array().required("Campo obrigatório"),
    termo: yup.string().required("Campo obrigatório"),
    contratante: yup.array().max(1).min(1, 'Campo obrigatório'),
  });
  const handleIncluirTermo = async (values: any, actions: any) => {
    try {
      setOpenAlertError(false);
    setLoading(true);
    setDisableActions(true);
    const statusBool = values.status[0] === "0" ? false : true;
    const dados:TermoPostPut = {
      ativo: statusBool,
      termo: values.termo,
      idContratante:parseInt(values.contratante[0]),
      idGrupoServico: values?.grupoServico[0]?.id ?? null,
      idsServicos:values?.servico?.map((servico:any) => parseInt(servico.id)),
    };
    const retorno = (await salvarTermo(dados))as AxiosResponse;
    if(retorno.status === 200){
      setMsgAlertSuccess("Termo salvo com sucesso!!");
      setLoading(false);
      setDisableActions(false);
      setOpenAlertSuccess(true);
      navigate("/ConsultarTermoOs");
    }else{
      setLoading(false);
      setDisableActions(false);
      setOpenAlertError(true);
      setMsgAlertError(retorno.data.errors[0].Message);
    }
    } catch (error:any) {
      setLoading(false);
      setDisableActions(false);
      setOpenAlertError(true);
      setMsgAlertError(error.response.data.errors[""][0]);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, actions) => {
      if (!!profileHasPermission("Incluir Termo de OS"))
        return handleIncluirTermo(values, actions);
    },
  });

  return (
    <Section id={"IncluirTermo"} className="IncluirTermo">
      <Header setMenuLateral={() => setMenuLateral(true)} title="Termo de OS" />
      <Breadcrumb>
        <BreadcrumbItem onClick={() => navigate("/ConsultarTermoOs")}>
          Consultar Termo de OS
        </BreadcrumbItem>
        <BreadcrumbItem>Incluir Termo</BreadcrumbItem>
      </Breadcrumb>
      <form className="IncluirTermo-Form" onSubmit={formik.handleSubmit}>
        <div className="IncluirTermo-Container">
          <div className="InsideGrid">
            <MultipleSelectCheckmarksid
              dimension="sm"
              id="multiple-checkbox-status"
              idOption="multiple-checkbox-option-status"
              labelId="multiple-checkbox-label-status"
              nodata="Nenhum status encontrado"
              value={formik.values.status}
              onChange={(e) => formik.setFieldValue("status", e)}
              options={listaStatus}
              label="Status"
              name="status"
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              multiple={false}
              disabled={disableActions}
            />
            <MultipleSelectCheckmarksid
              dimension="sm"
              label={"Contratante"}
              nodata={"Nenhum contratante encontrado"}
              options={[{ name: "--", id: "" }, ...listaContratante]}
              value={formik.values.contratante}
              onChange={(e) => formik.setFieldValue("contratante", e)}
              placeholder={""}
              id="multiple-checkbox-Contratante"
              idOption="multiple-checkbox-option-Contratante-"
              labelId="multiple-checkbox-label-Contratante"
              classes={{
                select: "ConsultaTermo-Seletor error",
                primary: "Seletor-Option",
                group: "Seletor-OptionGroup",
              }}
              multiple={false}
              error={
                formik.touched.contratante && Boolean(formik.errors.contratante)
              }
              helperText={
                formik.touched.contratante && formik.errors.contratante
              }
              disabled={disableActions}
            />
            <AutocompleteMultiple
              dimension="sm"
              label={"Grupo Serviços"}
              placeholder={""}
              noOptionsText={"Nenhum grupo de serviço encontrado"}
              options={
                listaGrupoServico?.map((item: any) => ({
                  name: item.nome,
                  id: item.id.toString(),
                }))!
              }
              value={formik.values.grupoServico}
              onChange={handleChangeGrupoServico}
              id="multiple-checkbox-grupoServico"
              error={
                formik.touched.grupoServico &&
                Boolean(formik.errors.grupoServico)
              }
              helperText={
                formik.touched.grupoServico && formik.errors.grupoServico
              }
              multiple={false}
              disabled={disableActions}
            />
            <AutocompleteMultiple
              dimension="sm"
              label={"Serviços"}
              placeholder={""}
              noOptionsText={"Nenhum serviço encontrado"}
              options={
                listaServicos?.map((item: any) => ({
                  name: item.nome,
                  id: item.id.toString(),
                }))!
              }
              value={formik.values.servico}
              onChange={(e: any) => formik.setFieldValue("servico", e)}
              id="multiple-checkbox-servico"
              error={formik.touched.servico && Boolean(formik.errors.servico)}
              helperText={formik.touched.servico && formik.errors.servico}
              multiple={true}
              disabled={disableActions}
            />
          </div>
          <TextAreaTermo
            dimension="sm"
            label="Termo"
            name="termo"
            id="termo"
            maxLength={2000}
            value={formik.values.termo}
            onChange={formik.handleChange}
            error={formik.touched.termo && Boolean(formik.errors.termo)}
            helperText={formik.touched.termo && formik.errors.termo}
            disabled={disableActions}
          />
        </div>
        <div className="button-list-right">
          {!!profileHasPermission("Incluir Termo de OS") ? (
            <Button variant="primary" type="submit" disabled={false}>
              Salvar
            </Button>
          ) : (
            <Button
              variant="primary"
              style={{ cursor: "not-allowed", opacity: 0.5 }}
              type="button"
              disabled={disableActions}
            >
              Salvar
            </Button>
          )}
        </div>
      </form>
      {!!loading && <CircularProgress style={{ margin: "0px auto" }} />}
      {openAlertError && (
        <Collapse onClose={setOpenAlertError} in={openAlertError}>
          <Alert
            severity="error"
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "rgb(255, 35, 35, 0.7)",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "50px",
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertError(false)}
              >
                <CloseIcon
                  id={"ModalTermoOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{ __html: msgAlertError }}
            />
          </Alert>
        </Collapse>
      )}
      {openAlertSuccess && (
        <Collapse onClose={setOpenAlertSuccess} in={openAlertSuccess}>
          <Alert
            severity="success"
            icon={<div />}
            variant="filled"
            sx={{
              mb: 2,
              bgcolor: "#178B319E",
              borderRadius: "15px",
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => setOpenAlertSuccess(false)}
              >
                <CloseIcon
                  id={"ModalTermoOS-AlertCloseIcon"}
                  fontSize="inherit"
                  sx={{
                    color: "white",
                  }}
                />
              </IconButton>
            }
          >
            <span
              style={{
                color: "white",
                fontSize: "16px",
              }}
            >
              {msgAlertSuccess}
            </span>
          </Alert>
        </Collapse>
      )}
    </Section>
  );
};

export default IncluirTermoOs;
