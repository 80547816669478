import { useContext, useEffect, useRef, useState } from "react";
import { IAutocompleteValue } from "../../../components/autocompleteMultiple/types";
import { HomeContext } from "../../../contexts/homeContext";
import { AuthContext } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/header/header";
import { Breadcrumb, BreadcrumbItem } from "../../../components/breadcrumb/breadcrumb";
import Input from "../../../components/Input/Input";
import MultipleSelectCheckmarksId from '../../../components/seletorMultiploId/seletorMultiploId'
import { Alert, IconButton } from "@mui/material";
import Pagination from "../../../components/pagination/paginacao";
import { IConsultaContratante, IConsultaContratanteTabela, IGetContratantes, IGetResponseContratante, IGetResponseContratantes } from "../types/";
import { getListaContratantesComFiltro } from "../services/services";
import { AxiosResponse } from "axios";
import Button from '../../../components/Button/styles';
import CloseIcon from "@mui/icons-material/Close"
import { IContratante } from "../../../contexts/types/homeTypes";
import { getContratantes } from "../services/services";
import FullscreenLoading from "../../../components/fullscreenLoading/fullscreenLoading";
import { NewButton } from "../../prestador/view/consultaPrestador_styles";
import Collapse from "../../../components/collapse/collapse";
import ConsultaContratanteTabela from "./consultaContratanteTabela";
import _ from 'lodash'
import "./consultaContratante.scss"

const ConsultaContratante = () => {
    const { setMenuLateral, setContratanteSelected } = useContext(HomeContext);

    const { funcionalidadeDaTelaTemPermissao } = useContext(AuthContext);
    const profileHasPermission = (funcionalidade: string) => funcionalidadeDaTelaTemPermissao("Consultar Contratantes", funcionalidade);
    const navigate = useNavigate();

    const listaStatus = [
        {
            name: '--',
            id: ''
        },
        {
            name: 'Ativo',
            id: '1'
        },
        {
            name: 'Inativo',
            id: '0'
        }
    ];

    const [status, setStatus] = useState<string[]>([])
    const [contratanteList, setContratanteList] = useState<IAutocompleteValue>([]);
    const [tableData, setTableData] = useState<IConsultaContratante>({} as IConsultaContratante);
    const [nomeFantasia, setNomeFantasia] = useState('')
    const [razaoSocial, setRazaoSocial] = useState('')
    const [cpfCnpj, setCpfCnpj] = useState('')
    const [codigoContratante, setCodigoContratante] = useState('')

    const [tipomsgAlert, setTipoMsgAlert] = useState<'success' | 'info' | 'warning' | 'error'>("error");
    const [msgAlert, setMsgAlert] = useState("OS encaminhada com  sucesso!");
    const [openAlert, setOpenAlert] = useState(false);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [loading, setLoading] = useState<boolean>(false);


    const handleSearch = async (page?: number, size?: number) => {
        setLoading(true)
        setOpenAlert(false)
        try {
            const pageSizeToUse = size || pageSize;

            const { data } = await getListaContratantesComFiltro({
                nomeFantasia: nomeFantasia ?? "",
                razaoSocial: razaoSocial ?? "",
                codigoContratante: codigoContratante ?? "",
                cpfCnpj: cpfCnpj ?? "",
                ativo: !!status[0] ? Boolean(Number(status[0])) : undefined,
                pageNumber: page || 1,
                pageSize: pageSizeToUse
            }) as AxiosResponse

            setTableData({
                totalCount: data?.totalCount,
                table: data?.items as IConsultaContratanteTabela[],
                pageNumber: data?.pageNumber,
                totalPages: data?.totalPages,
                hasPreviousPage: data?.hasPreviousPage,
                hasNextPage: data?.hasNextPage
            })

            // Atualiza o estado de paginação
            setCurrentPage(page || 1);
            setPageSize(pageSizeToUse);
        } catch (e) {
            setTableData({} as IConsultaContratante)
            setMsgAlert("Nenhum contratante encontrado")
            setTipoMsgAlert('error')
            setOpenAlert(true)
        }
        setLoading(false)
    }

    const getAllContractors = async () => {
        try {
            const { data } = await getContratantes() as AxiosResponse
            setContratanteList(data.map((item: IContratante) => {
                return { name: item.nomeFantasia, id: item.id.toString() }
            }).sort((a: { name: string, id: string }, b: { name: string, id: string }) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))

        } catch (e) {
            setContratanteList([])
        }
    }

    const beforeSubmit = () => {
        if (currentPage !== 1) setCurrentPage(1)
        else handleSearch()
    }

    useEffect(() => {
        getAllContractors()
        setContratanteSelected({})
    }, [])

    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        handleSearch(currentPage, pageSize)
    }, [currentPage, pageSize])

    return (
        <section className="ConsultaContratante">
            <Header setMenuLateral={() => setMenuLateral(true)} title="Contratantes" />
            <Breadcrumb>
                <BreadcrumbItem>Buscar Contratante</BreadcrumbItem>
            </Breadcrumb>

            {loading && <FullscreenLoading />}
            <div className="Container">
                <span className='FiltroSpan'>Filtros</span>
                <div className="ConsultarContratantePesquisar">
                    <MultipleSelectCheckmarksId
                        nodata={"Nenhum status encontrado"}
                        options={listaStatus}
                        value={status}
                        onChange={(e: string[]) => setStatus(e)}
                        id="multiple-checkbox-status"
                        idOption="multiple-checkbox-option-status-"
                        labelId="multiple-checkbox-label-status"
                        multiple={false}
                        className="ConsultarContratanteSelectStatus"
                        dimension='sm'
                        label="Status"
                    />

                    <Input
                        value={nomeFantasia}
                        onChange={(e) => setNomeFantasia(e.target.value)}
                        dimension='sm'
                        label='Nome Fantasia'
                    />
                </div>
                <div className="ConsultarContratantePesquisar">
                    <Input
                        value={razaoSocial}
                        onChange={(e) => setRazaoSocial(e.target.value)}
                        dimension='sm'
                        label='Razão Social'
                    />
                    <Input
                        value={codigoContratante}
                        onChange={(e) => setCodigoContratante(e.target.value)}
                        dimension='sm'
                        label='Código Contratante'
                    />
                    <Input
                        label="CPF/CNPJ"
                        value={cpfCnpj}
                        onChange={(e) => setCpfCnpj(e.target.value)}
                        dimension='sm'
                    />
                    <Button dimension='sm' variant='primary' margin='0' onClick={() => beforeSubmit()}>Pesquisar</Button>
                    {!!profileHasPermission("Novo Registro") ?
                        <NewButton 
                            variant="contained" 
                            size="small" 
                            onClick={() => {
                                setContratanteSelected({});
                                navigate('/IncluirContratante');
                            }}
                        >
                            Novo
                        </NewButton>
                        :
                        <NewButton
                            className={"bloqueado"}
                            style={{ cursor: 'not-allowed', opacity: 0.5 }}
                            variant="contained"
                            size="small"
                        >
                            Novo
                        </NewButton>
                    }
                </div>
                <Collapse onClose={setOpenAlert} in={openAlert}>
                    <Alert
                        severity={tipomsgAlert}
                        icon={<div />}
                        variant="filled"
                        sx={{
                            mb: 2,
                            bgcolor: tipomsgAlert === "success" ? "#178B319E" : "rgb(255, 35, 35, 0.7)",
                            height: "67px",
                            borderRadius: "15px",
                            boxSizing: "border-box",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "20px",
                            marginBottom: 0,
                        }}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="medium"
                                onClick={() => setOpenAlert(false)}
                            >
                                <CloseIcon
                                    id={"ModalEncaminharOS-AlertCloseIcon"}
                                    fontSize="inherit"
                                    sx={{
                                        color: "white",
                                    }}
                                />
                            </IconButton>
                        }
                    >
                        <span
                            style={{
                                color: "white",
                                fontSize: "16px",
                            }}
                            dangerouslySetInnerHTML={{ __html: msgAlert }}
                        />
                    </Alert>
                </Collapse>
                {!_.isEmpty(tableData) &&
                    <>
                        <ConsultaContratanteTabela tableData={tableData?.table} allowEdit={!!profileHasPermission("Editar Registro")} />
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={tableData?.totalCount}
                            pageSize={pageSize}
                            setPageSize={(value: string) => setPageSize(Number(value))}
                            onPageChange={(page: number) => setCurrentPage(Number(page))}
                            showLabel={false}
                        />
                    </>
                }
            </div>
        </section>
    )

}
export default ConsultaContratante;