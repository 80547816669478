import { useContext, ReactNode, useMemo } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/home/view/home";
import { AuthContext, AuthProvider } from "./contexts/auth";
import AcessoNegado from "./pages/acessoNegado/view/acessoNegado";
import { pagesComponentList, publicURLsList, privateURLsList } from "./helpers/listaRotas";

interface IChildren {
  children?: ReactNode
}

const AppRoutes = () => {
  const Private: any = ({ children }: IChildren) => {
    const LocalAuthContext = useContext(AuthContext);

    if (!LocalAuthContext) {
      return null
    }

    const { user, authenticated, loading, getTelaTemAcesso, getPaginaInicial } = LocalAuthContext;

    if (loading) {
      return <div className="loading">Carregando...</div>;
    }

    if (!authenticated) {
      return <Navigate to={"/Login"} />;
    }

    if (!!user?.primeiroAcesso && window.location.pathname !== "/ChangePassword") {
      return <Navigate to={"/ChangePassword"} />;
    }

    /* Pagina inicial */
    if (window.location.pathname === "/") {
      return <Navigate to={getPaginaInicial()} />;
    }

    if (!getTelaTemAcesso(window.location.pathname)) {
      return <Navigate to={"/AcessoNegado"} replace={true} />;
    }

    return children;
  };

  const AlreadyLogged: any = ({ children }: IChildren) => {
    const LocalAuthContext = useContext(AuthContext);

    if (!LocalAuthContext) {
      return null
    }

    const { authenticated, loading } = LocalAuthContext

    if (loading) {
      return <div className="loading">Carregando...</div>;
    }

    function isNotValidatingOrResetting(): boolean {
      return window.location.pathname !== "/ValidarEmail" && window.location.pathname !== "/ResetSenha";
    }

    if (!!authenticated && isNotValidatingOrResetting()) {
      return <Navigate to={"/"} />;
    }

    return children;
  };

  const pagesComponent: any = useMemo(() => ({ ...pagesComponentList }), []);
  const publicURLs = [...publicURLsList];
  const privateURLs = useMemo(() => ([...privateURLsList]), []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {!!publicURLs?.length && publicURLs.map(({ name, url }) => {
            return (
              <Route
                key={url}
                path={url}
                element={
                  <AlreadyLogged>
                    {!!pagesComponent[name] ? pagesComponent[name] : <AcessoNegado />}
                  </AlreadyLogged>
                }
              />
            );
          })}

          <Route
            path={"/"}
            element={
              <Private>
                <Home />
              </Private>
            }
          >
            {!!privateURLs?.length && privateURLs.map(({ name, url }) => {
              return (
                <Route
                  key={url}
                  path={url}
                  element={
                    <Private>
                      {!!pagesComponent[name] ? pagesComponent[name] : <AcessoNegado />}
                    </Private>
                  }
                />
              );
            })}
          </Route>

          <Route path="*" element={<Navigate to={"/AcessoNegado"} replace={true} />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
