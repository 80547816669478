import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Styles from '../../../components/table/styles';
import { StyledTableRow } from './consultarChecklistCompleto_Style';
import { ItensListagem } from '../types';
import Pagination from '../../../components/pagination/paginacao';

interface TecnicosTableProps {
  itemsListagem: ItensListagem[];
  onEdit: (tecnico: ItensListagem) => void;
  onDelete: (tecnico: ItensListagem) => void;
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: string) => void;
}

const TecnicosTable: React.FC<TecnicosTableProps> = ({
  itemsListagem,
  onEdit,
  onDelete,
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
  onPageSizeChange,
}) => {
  return (
    <Box sx={{ padding: 2, marginTop: 8 }}>
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {['Técnico', 'Intervalo de tempo(em dias)', 'Editar', 'Excluir'].map((title) => (
                <Styles.StyledTableCell className={`${title} center`} key={title}>
                  {title}
                </Styles.StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsListagem.length ? (
              itemsListagem.map((item) => (
                <StyledTableRow key={item.id}>
                  <Styles.StyledTableCell>{item.tecnico}</Styles.StyledTableCell>
                  <Styles.StyledTableCell align="center">{item.intervaloDias}</Styles.StyledTableCell>
                  <Styles.StyledTableCell align="center">
                    <IconButton onClick={() => onEdit(item)}>
                      <EditIcon />
                    </IconButton>
                  </Styles.StyledTableCell>
                  <Styles.StyledTableCell align="center">
                    <IconButton onClick={() => onDelete(item)}>
                      <DeleteIcon />
                    </IconButton>
                  </Styles.StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <Styles.StyledTableCell
                  colSpan={4}
                  sx={{
                    textAlign: 'center',
                    fontSize: '1.25rem',
                    padding: '40px',
                    color: '#666',
                    fontWeight: 500,
                  }}
                >
                  Não há configurações por técnico
                </Styles.StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        showLabel={false}
        showQtdPageSelectBox={false}
        setPageSize={(value: string) => onPageSizeChange(value)}
        onPageChange={(page: number) => onPageChange(page)}
      />
    </Box>
  );
};

export default TecnicosTable;
