
export const STATUS_OPTIONS = [
    { name: '--', id: '' },
    { name: 'Ativo', id: '0' },
    { name: 'Inativo', id: '1' },
];

export const TIPO_OPTIONS = [
    { name: 'GERAL', id: '1' },
    { name: 'CONTRATANTE', id: '2' },
];
